import { Form } from "formik"

import QontoStepper from "src/sections/@dashboard/components/QontoStepper"

import { Box, useTheme } from "@mui/material"

import TradeReferencesSection, {
  getEnabledSchema as getTradeEnabledSchema,
} from "src/sections/@dashboard/intake_sections/TradeReferencesSection"
import BankReferenceSection, {
  getEnabledSchema as getBankEnabledSchema,
} from "src/sections/@dashboard/intake_sections/BankReferenceSection"

import { getEnabledSchema as getUserEnabledSchema } from "src/sections/@dashboard/intake_sections/UserRegistrationSection"
import OwnersSection, {
  getEnabledSchema,
} from "src/sections/@dashboard/intake_sections/OwnersSection"
import CompanyDetailsSection, {
  getEnabledSchema as getCompanyDetailsEnabledSchema,
} from "src/sections/@dashboard/intake_sections/CompanyDetailsSection"

import CustomQuestionsSection from "src/sections/@dashboard/intake_sections/CustomQuestionsSection"
import PersonalGuarantySection, {
  getEnabledSchema as getPersonalGuarantyEnabledSchema,
} from "src/sections/@dashboard/intake_sections/PersonalGuarantySection"

import ManualApplicantSection from "../../intake_sections/ManualApplicantSection"
import { FormComponent } from "src/types/credit/FormComponent"

export default ({
  steps,
  activeStep,
  handleBack,
  props,
  onContinue,
  onDataFieldUpdated,
  onCustomFieldUpdated,
  application,
  template,
  onApplicationFieldUpdated,
}: FormComponent) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "5%",
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        style={{ margin: "0 5% 32px 5%" }}
        display={{
          xs: "none",
          sm: "none",
          md: "block",
          large: "block",
          xlarge: "block",
        }}
      >
        <QontoStepper
          steps={steps}
          // data={formik.values.data}
          activeStep={activeStep}
          handleBack={handleBack}
        />
      </Box>
      <Box
        style={{
          textAlign: "left",
          padding: "32px 10% 32px 10%",
          border: "solid",
          borderWidth: "1px",
          borderRadius: "16px",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Form>
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("user") && (
              <ManualApplicantSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={(onVerificationNeeded, onFormValidationError) => {
                  onContinue(
                    getUserEnabledSchema(template),
                    onFormValidationError,
                  )
                }}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("business") && (
              <CompanyDetailsSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                topLevelValues={props.values}
                onApplicationFieldUpdated={onApplicationFieldUpdated}
                onContinue={() => {
                  onContinue(getCompanyDetailsEnabledSchema(template))
                }}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("owners") && (
              <OwnersSection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(getEnabledSchema(template))
                }}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("trade") && (
              <TradeReferencesSection
                size={3}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onContinue={() => {
                  onContinue(getTradeEnabledSchema(template))
                }}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("bank") && (
              <BankReferenceSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(getBankEnabledSchema(template))
                }}
                template={template}
              />
            )}

          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("guaranty") && (
              <PersonalGuarantySection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onContinue={() => {
                  onContinue(getPersonalGuarantyEnabledSchema(template))
                }}
                template={template}
              />
            )}

          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("additional") && (
              <CustomQuestionsSection
                application={application}
                errors={props.errors.data}
                data={props.values.data}
                onChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue()
                }}
              />
            )}
        </Form>
      </Box>
    </Box>
  )
}
