import {
  CreditApplication,
  BankReferenceChart,
  Asset,
  Liability,
} from "src/types"
import { Close } from "@mui/icons-material"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  Typography,
  Paper,
  Stack,
  Grid,
} from "@mui/material"
import { isSectionEnabled } from "src/utils/utils"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useGetBankReferenceChart } from "src/queries/credit/useGetBankReferenceChart"
import VerticalBarChart from "src/components/chart/VerticalBarChart"
import { fShortenNumber, formatCurrency } from "src/utils/formatNumber"

export default function ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) {
  const chartDisplayCards = (data: BankReferenceChart | undefined) => {
    if (!data || !data?.balances || Object.keys(data?.balances).length === 0) {
      return <></>
    }
    if (!data || !data?.assets || Object.keys(data?.assets).length === 0) {
      return <></>
    }
    const key: string = Object.keys(data?.balances)[0]
    return (
      <>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ p: 3 }}
        >
          <Paper
            elevation={4}
            sx={{ paddingTop: 1, paddingBottom: 2, paddingX: 1, width: 180 }}
          >
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              Current Balance
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "text.primary", paddingTop: 1 }}
            >
              ${fShortenNumber(data.balances[key].currentBalance)}
            </Typography>
          </Paper>
          <Paper
            elevation={4}
            sx={{
              paddingTop: 1,
              paddingBottom: 2,
              paddingX: 1,
              color: "text.secondary",
              width: 180,
            }}
          >
            <Typography variant="caption">NSF Last Month</Typography>
            <Typography
              variant="h6"
              sx={{ color: "text.primary", paddingTop: 1 }}
            >
              {data.transactions[key].nsfTransactions.lastMonthCount}
            </Typography>
          </Paper>
          <Paper
            elevation={4}
            sx={{
              paddingTop: 1,
              paddingBottom: 2,
              paddingX: 1,
              color: "text.secondary",
              width: 180,
            }}
          >
            <Typography variant="caption">
              Avg Balance Last 12 Months
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "text.primary", paddingTop: 1 }}
            >
              ${fShortenNumber(data.balances[key].averageBalanceLastYear)}
            </Typography>
          </Paper>
          <Paper
            elevation={4}
            sx={{
              paddingTop: 1,
              paddingBottom: 2,
              paddingX: 1,
              color: "text.secondary",
              width: 180,
            }}
          >
            <Typography variant="caption">NSF Last 12 Month</Typography>
            <Typography
              variant="h6"
              sx={{ color: "text.primary", paddingTop: 1 }}
            >
              {data.transactions[key].nsfTransactions.last12MonthsCount}
            </Typography>
          </Paper>
        </Stack>
      </>
    )
  }

  const balancesBarChart = (data: BankReferenceChart | undefined) => {
    if (!data || !data?.balances || Object.keys(data?.balances).length === 0) {
      return <></>
    }
    // Display Current Year and Prior Year
    const key: string = Object.keys(data?.balances)[0]
    const series = []
    const categories = []
    let count = 12

    if (data.balances[key].balances.length < count) {
      count = data.balances[key].balances.length
    }

    for (let i = 0; i < count; i++) {
      series.push(data.balances[key].balances[i].balance)
      const date = new Date(data.balances[key].balances[i].date)
      const month = date.toLocaleString("default", { month: "short" })
      categories.push(month)
    }
    return (
      <Stack sx={{ p: 3 }}>
        <Paper elevation={4} sx={{ p: 3, color: "text.primary" }}>
          <Typography variant="h6">Average Cash Balance By Month</Typography>
          <VerticalBarChart
            series_name="Account balance"
            series={series}
            categories={categories}
          />
        </Paper>
      </Stack>
    )
  }

  const displayAssetsChart = (data: BankReferenceChart | undefined) => {
    if (!data || !data?.assets || Object.keys(data?.assets).length === 0) {
      return <></>
    }

    const key: string = Object.keys(data?.assets)[0]
    const asset: Asset = data?.assets[key]
    return (
      <Stack sx={{ p: 4 }}>
        <Paper elevation={4} sx={{ p: 3, color: "text.primary" }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Assets
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Institution
              </Typography>
              <Typography variant="subtitle1">
                {asset?.institutionName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Account Type
              </Typography>
              <Typography variant="subtitle1">{asset?.accountType}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Account Name
              </Typography>
              <Typography variant="subtitle1">{asset?.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Avaliable Balance
              </Typography>
              <Typography variant="subtitle1">
                ${fShortenNumber(asset?.available)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Current Balance
              </Typography>
              <Typography variant="subtitle1">
                ${fShortenNumber(asset?.current)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    )
  }

  const displayLiabilitiesChart = (data: BankReferenceChart | undefined) => {
    if (
      !data ||
      !data?.liabilities ||
      Object.keys(data?.liabilities).length === 0
    ) {
      return <></>
    }

    const key: string = Object.keys(data?.liabilities)[0]
    const liability: Liability = data?.liabilities[key]
    return (
      <Stack sx={{ p: 4 }}>
        <Paper elevation={4} sx={{ p: 3, color: "text.primary" }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Liabilities
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Institution
              </Typography>
              <Typography variant="subtitle1">
                {liability?.institutionName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Account Type
              </Typography>
              <Typography variant="subtitle1">
                {liability?.accountType}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Account Name
              </Typography>
              <Typography variant="subtitle1">{liability?.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Avaliable Balance
              </Typography>
              <Typography variant="subtitle1">
                ${fShortenNumber(liability?.available)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Current Balance
              </Typography>
              <Typography variant="subtitle1">
                ${fShortenNumber(liability?.current)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    )
  }

  const plaidGeneratedBankReference = (
    data: BankReferenceChart | undefined,
  ) => {
    if (!data?.lastUpdated) {
      return (
        <>
          <Paper sx={{ p: 4, textAlign: "center" }}>
            <Typography variant="h6">
              Please Wait We are Processing the Bank Reference
            </Typography>
          </Paper>
        </>
      )
    }
    return (
      <>
        <Stack justifyContent="flex-end">
          <Stack
            sx={{
              paddingTop: 2,
              paddingRight: 4,
              marginLeft: "auto",
            }}
          >
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              Last Updated: {new Date(data.lastUpdated).toLocaleString()}
            </Typography>
          </Stack>
          {chartDisplayCards(data)}
          {balancesBarChart(data)}
          {displayAssetsChart(data)}
          {displayLiabilitiesChart(data)}
        </Stack>
      </>
    )
  }

  const { data: template } = useApplicationTemplate()

  const { data: chartData } = useGetBankReferenceChart(application.id)

  if (isSectionEnabled(template, "Bank Reference") === false)
    return (
      <>
        <Paper variant="outlined" sx={{ p: 3, color: "text.primary" }}>
          <Typography variant="h6">Section Not Enabled</Typography>
        </Paper>
      </>
    )
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogContent>
        <Paper elevation={4}>
          <Stack direction={"row"} justifyContent="flex-end" sx={{ p: 1 }}>
            <Close onClick={onClose} />
          </Stack>
          {plaidGeneratedBankReference(chartData)}
        </Paper>
      </DialogContent>
    </Dialog>
  )
}
