import { useState, memo, useCallback } from "react"
// @mui
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import Stack from "@mui/material/Stack"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Dialog, { dialogClasses } from "@mui/material/Dialog"
import { Button, Typography } from "@mui/material"
// hooks
import { useBoolean } from "src/hooks/use-boolean"
import { useEventListener } from "src/hooks/use-event-listener"
import useDebounced from "src/hooks/useDebounced"
// components
import Label from "src/components/label"
import Iconify from "src/components/iconify"
import Scrollbar from "src/components/scrollbar"
import SearchNotFound from "src/components/search-not-found"
//
import ResultItem from "./result-item"
import { useSearchApplications } from "src/queries/credit/useSearchApplications"
import { groupedData, getAllItems } from "./utils"
import { useNavigate } from "react-router-dom"

// ----------------------------------------------------------------------

function Searchbar() {
  const theme = useTheme()

  const navigate = useNavigate()

  const search = useBoolean()

  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchTerm = useDebounced(searchQuery)

  const { data: filteredApplications, isLoading } =
    useSearchApplications(debouncedSearchTerm)

  const handleClose = useCallback(() => {
    search.onFalse()
    setSearchQuery("")
  }, [search])

  const handleKeyDown = (event) => {
    if (event.key === "k" && event.metaKey) {
      search.onToggle()
      setSearchQuery("")
    }
  }

  useEventListener("keydown", handleKeyDown)

  const handleClick = useCallback(
    (path) => {
      if (path.includes("http")) {
        window.open(path)
      } else {
        navigate(path)
      }
      handleClose()
    },
    [handleClose, navigate],
  )

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value)
  }, [])

  const dataFiltered = getAllItems({ data: filteredApplications || [] })

  const notFound =
    filteredApplications && debouncedSearchTerm && !dataFiltered.length

  const renderItems = () => {
    if (isLoading) {
      return <>Loading...</>
    }
    const data = groupedData(dataFiltered)

    return Object.keys(data)
      .sort((a, b) => -b.localeCompare(a))
      .map((group, index) => (
        <List key={group || index} disablePadding>
          {data[group].map((item) => {
            const { title, path, subtitle, status } = item
            return (
              <ResultItem
                title={[{ text: title }]}
                subtitle={[{ text: subtitle }]}
                key={`${title}${path}`}
                status={status}
                groupLabel={debouncedSearchTerm && group}
                onClickItem={() => handleClick(path)}
              />
            )
          })}
        </List>
      ))
  }

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <Button
        size="large"
        onClick={search.onTrue}
        startIcon={<Iconify icon="eva:search-fill" style={{ color: "gray" }} />}
      >
        <Typography
          style={{
            color: theme.palette.text.primary,
          }}
          variant="body2"
        >
          Search...
        </Typography>
      </Button>
    </Stack>
  )

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: "unset",
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: "flex-start",
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  width={24}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
            endAdornment={
              <Label sx={{ letterSpacing: 1, color: "text.secondary" }}>
                esc
              </Label>
            }
            inputProps={{
              sx: { typography: "h6" },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? (
            <SearchNotFound query={debouncedSearchTerm} sx={{ py: 10 }} />
          ) : (
            renderItems()
          )}
        </Scrollbar>
      </Dialog>
    </>
  )
}

export default memo(Searchbar)
