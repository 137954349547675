import * as React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material"
import { confirmable } from "react-confirm"
import ThemeProvider from "../../theme"

type AlertProps = {
  title?: string
  okLabel?: string
  cancelLabel?: string
  confirmation: string
  show: boolean
  proceed: () => void
  dismiss: () => void
  cancel: () => void
  modal: boolean
  okColor?: "primary" | "secondary" | "error"
}

function Alert({
  okLabel = "OK",
  cancelLabel = "Cancel",
  title = "Are you sure?",
  confirmation,
  show,
  proceed,
  dismiss,
  cancel,
  okColor,
  modal,
}: AlertProps) {
  return (
    <ThemeProvider>
      <Dialog
        open={show}
        onClose={dismiss}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmation}
          </DialogContentText>
        </DialogContent>
        <Divider style={{ marginBottom: "8px" }} />
        <DialogActions>
          <Button onClick={cancel}>{cancelLabel}</Button>
          <Button
            onClick={proceed}
            autoFocus
            variant="contained"
            color={okColor}
          >
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
export default confirmable(Alert)
