// onboarding states
export const NEW = "NEW"
export const PENDING_APPROVAL = "PENDING_APPROVAL"
export const ONBOARDED = "ONBOARDED"

export const APPLICATION_STAGE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  MANUAL_INCOMPLETE: 50,
  MANUAL_COMPLETE: 60,
  AWAITING_SIGNATURE: 100,
  COMPLETED_BY_CLIENT: 200,
  APPROVED: 800,
  REJECTED: 900,
  ARCHIVE: 1000000,
} as const

export const PERSONAL_GUARANTY = {
  REQUIRED: "required",
  OPTIONAL: "optional",
  OFF: "off",
} as const

export const BUSINESS_TYPE_OPTIONS = [
  "Building Maintenance",
  "Cabinet Manufacturer",
  "Commercial",
  "Deck Building",
  "Developer",
  "Fabricator",
  "General Contracting",
  "Industrial",
  "Remodeling",
  "Residential Construction",
  "Retailer",
  "Roofing",
]
// applicantType
export const APPLICANT_TYPES = {
  CORPORATION: "Corporation",
  LLC: "Limited Liability Corporation (LLC)",
  PARTNERSHIP: "Partnership",
  SOLE_PROPRIETORSHIP: "Sole Proprietorship",
  INDIVIDUAL_CONSUMER_HOMEOWNER: "Individual/Consumer/Homeowner",
  NON_PROFIT: "Non-Profit",
  OTHER: "Other",
}
export const APPLICANT_TYPES_OPTIONS = [
  APPLICANT_TYPES.CORPORATION,
  APPLICANT_TYPES.LLC,
  APPLICANT_TYPES.PARTNERSHIP,
  APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
  APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER,
  APPLICANT_TYPES.NON_PROFIT,
  APPLICANT_TYPES.OTHER,
]

export const APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME = [
  APPLICANT_TYPES.CORPORATION,
  APPLICANT_TYPES.PARTNERSHIP,
  APPLICANT_TYPES.NON_PROFIT,
  APPLICANT_TYPES.LLC,
]
