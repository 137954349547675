import { useQuery } from "react-query"
import { AdditionalDataRequest, CreditApplication } from "src/types"

export function useBuyerQuickLinks(enabled = false) {
  return useQuery<{
    applications: CreditApplication[]
    requests: AdditionalDataRequest[]
  }>("/buyer/quick_links", {
    enabled,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
