import { QueryClient } from "react-query"
import { CreditApplication } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

// to be used for generating apps without the seller intervention
export function usePostGenerateAnonymousApplication(
  onSuccess?: (data: CreditApplication) => void,
) {
  const ENDPOINT = "/application/generate"
  const postGenerateApplication = useTypedMutation<FormData>(
    "post/application/generate",
    onSuccess,
  )

  const execute = (body: Partial<CreditApplication>) => {
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "files" && body["files"]) {
        body["files"].forEach((file, index) => {
          if (file instanceof File) {
            formData.append("file" + index, file as File)
          } else {
            formData.append("file" + index, JSON.stringify(file))
          }
        })
      } else if (item.includes("data")) {
        formData.append(item, JSON.stringify(body["data"]))
      } else if (item.includes("Date")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(
          item,
          new Date(body[item as keyof CreditApplication] as Date).toISOString(),
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof CreditApplication])
      }
    }
    postGenerateApplication.mutate(
      {
        method: "post",
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        anonymous: true,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...postGenerateApplication, execute }
}
