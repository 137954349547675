import * as React from "react"

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import { StepIconProps } from "@mui/material/StepIcon"
import Check from "@mui/icons-material/Check"
import { ArrowBack } from "@mui/icons-material"

import { styled } from "@mui/material/styles"
import { ApplicationTemplate } from "src/types"
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    }),
    "& .QontoStepIcon-completedIcon": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      borderColor: "black",
    },
  }),
)

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

type Props = {
  activeStep: number
  handleBack: () => void
  steps: ApplicationTemplate["formTemplate"]["pages"]
}

export default function QontoStepper({ steps, activeStep, handleBack }: Props) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Button
          id="back"
          onClick={handleBack}
          // sx={{ mt: 1, mr: 1 }}
          disabled={activeStep === 0 || activeStep === 99 || activeStep === -1}
          variant="contained"
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
      </Box>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
        style={{ width: "100%" }}
      >
        {steps.map((step, index) => {
          const labelProps: {
            optional?: React.ReactNode
          } = {}

          if (step.required === false && !step.label.includes("Additional")) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            )
          }
          return (
            <Step key={step.label}>
              <StepLabel {...labelProps} StepIconComponent={QontoStepIcon}>
                {index === activeStep ? (
                  <Typography variant="subtitle1">{step.label}</Typography>
                ) : (
                  step.label
                )}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
