import { useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import {
  CreditApplication,
  CustomFieldResponse,
  TemplateCustomField,
  UploadedFile,
} from "src/types"
import Label from "src/components/label"
import ReactPhoneInput from "react-phone-input-material-ui"

import { DropzoneDialog } from "mui-file-dropzone"
import { confirm } from "src/components/confirm"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useDeleteUploadedFile } from "src/queries/credit/useDeleteUploadedFile"
import { useLocation, useParams } from "react-router-dom"
import { info, error } from "src/utils/logger"
import { isEmail } from "src/utils/utils"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"

type Props = {
  application?: CreditApplication
  associatedPage: string
  errors: CreditApplication["data"] | undefined
  data?: { [key: string]: CustomFieldResponse }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
}

export default ({
  application,
  associatedPage,
  errors,
  data,
  onChange,
}: Props) => {
  const [pendingUploadFileFieldName, setPendingUploadFileFieldName] = useState<
    string | null
  >(null)

  const [showError, setShowError] = useState(false)
  const [fieldIdToValidate, setFieldIdToValidate] = useState("")
  const { data: emailValidationResult } = useValidateEmail(
    fieldIdToValidate && data?.[fieldIdToValidate]
      ? (data?.[fieldIdToValidate].response as string)
      : "",
    "CUSTOMQUESTION",
  )

  useEffect(() => {
    if (emailValidationResult?.verdict === "Invalid") {
      setShowError(true)
    }
  }, [emailValidationResult, fieldIdToValidate])

  const params = useParams()
  const { id } = params

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")
  const { data: template } = useApplicationTemplate(
    application?.seller?.id || businessId || undefined,
    true,
    id ? !application?.seller?.id : !businessId,
    id,
  )

  const customFields = template?.customFields?.filter(
    (field) => field.associatedPage === associatedPage,
  )

  const { execute: deleteFile } = useDeleteUploadedFile()

  if (!customFields) return <></>

  const handleFileUpload = (field: string, file: File | undefined) => {
    setPendingUploadFileFieldName(null)
    const temp_field = customFields.find((f) => f.id === field)
    if (temp_field) {
      if (file) {
        // temp_field.file = file
        onChange(field, {
          field: temp_field,
          file,
        })
      } else {
        onChange(field, undefined)
      }
    }
  }

  const handleTextChange = (field: string, response: string) => {
    const temp_field = customFields.find((f) => f.id === field)
    if (temp_field) {
      // temp_field.response = response
      if (response) {
        onChange(field, {
          field: temp_field,
          response,
        })
      } else {
        onChange(field, undefined)
      }
    }
  }

  const sortByFieldType = (a: TemplateCustomField, b: TemplateCustomField) => {
    if (a.order && b.order && a.order !== b.order) {
      if (a.order > b.order) {
        return +1
      }
      return -1
    }
    if (a.fieldType === b.fieldType) {
      if (a.id && b.id && a.id > b.id) return -1
      return +1
    } else if (a.fieldType < b.fieldType) {
      return +1
    }
    return -1
  }

  const getField = (
    field: TemplateCustomField,
    index: number,
    required = false,
  ) => {
    if (!!field.id) {
      if (field.fieldType === "text") {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ width: "100%" }}>
              {field.text}
              {required ? " *" : ""}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              type="text"
              value={data?.[field.id] ? data?.[field.id].response : ""}
              onChange={(event) => {
                if (field.id) {
                  handleTextChange(field.id, event.target.value)
                }
              }}
              error={Boolean(
                errors?.customFields
                  ? errors.customFields[field.id || ""]
                  : false,
              )}
            />
          </Box>
        )
      } else if (field.fieldType === "email") {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ width: "100%" }}>
              {field.text}
              {required ? " *" : ""}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              type="email"
              value={data?.[field.id] ? data?.[field.id].response : ""}
              onChange={(event) => {
                if (field.id) {
                  handleTextChange(field.id, event.target.value)
                }
              }}
              onBlur={(event) => {
                setFieldIdToValidate(field.id as string)
              }}
              error={Boolean(
                errors?.customFields
                  ? errors.customFields[field.id || ""]
                  : data?.[field.id]
                    ? !isEmail(data?.[field.id].response as string)
                    : false,
              )}
            />
          </Box>
        )
      } else if (field.fieldType === "date") {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ width: "100%" }}>
              {field.text}
              {required ? " *" : ""}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              type="date"
              value={data?.[field.id] ? data?.[field.id].response : ""}
              onChange={(event) => {
                if (field.id) {
                  handleTextChange(field.id, event.target.value)
                }
              }}
              error={Boolean(
                errors?.customFields
                  ? errors.customFields[field.id || ""]
                  : false,
              )}
            />
          </Box>
        )
      } else if (field.fieldType === "phone") {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ width: "100%" }}>
              {field.text}
              {required ? " *" : ""}
            </Typography>
            <ReactPhoneInput
              value={data?.[field.id] ? data?.[field.id].response : "+1"}
              country={"us"}
              onChange={(event) => {
                if (field.id) {
                  if (event !== "1") {
                    handleTextChange(field.id, "+" + event)
                  } else {
                    handleTextChange(field.id, "")
                  }
                }
              }}
              countryCodeEditable={false}
              component={TextField}
              label="Number"
              containerStyle={{
                width: "100%",
                marginTop: "8px",
                height: "56px",
              }}
              inputProps={{
                id: "phone-number",
                error: Boolean(
                  errors?.customFields
                    ? errors.customFields[field.id || ""]
                    : false,
                ),
              }}
            />
          </Box>
        )
      } else if (field.fieldType === "file") {
        const isInMemoryFile =
          data && data[field.id] && typeof data[field.id].file !== "string"
        const isUploadedFile =
          data &&
          field.id &&
          data[field.id] &&
          application?.files?.find(
            (uploadedFile: any) =>
              uploadedFile["id"] === data[field.id || "no-op"]["file"],
          )
        return (
          <Box
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
              margin: "8px 0 8px 0",
            }}
          >
            <Typography style={{ width: "100%" }}>
              {field.text}
              {required ? " *" : ""}
            </Typography>
            {(isInMemoryFile || isUploadedFile) && (
              <Box
                style={{ display: "flex", gap: "16px", alignItems: "center " }}
              >
                <Label
                  color="warning"
                  key={`customFile-${data[field.id]}`}
                  variant="filled"
                >
                  {isInMemoryFile
                    ? (data[field.id].file as File)?.name
                    : application?.files?.find(
                        (uploadedFile: any) =>
                          uploadedFile["id"] ===
                          data[field.id || "no-op"]["file"],
                      )?.name}
                </Label>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    confirm(
                      "You are about to remove this file from your application.",
                    )
                      .then(
                        () => {
                          if (field.id) {
                            if (isUploadedFile) {
                              deleteFile(
                                data[field.id]["file"] as unknown as string,
                              )
                            }
                            return handleFileUpload(field.id, undefined)
                          }
                          return
                        },
                        () => {
                          info("cancelled")
                        },
                      )
                      .catch(() => {
                        error("cancelled")
                      })
                  }}
                >
                  <Close />
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    isInMemoryFile
                      ? window.open(
                          window.URL.createObjectURL(
                            data[field.id || ""].file as Blob,
                          ),
                        )
                      : window.open(
                          (
                            application?.files?.find(
                              (uploadedFile: any) =>
                                uploadedFile["id"] ===
                                data[field.id || "no-op"]["file"],
                            ) as UploadedFile
                          ).file,
                        )
                  }}
                >
                  View
                </Button>
              </Box>
            )}
            {!isInMemoryFile && !isUploadedFile && (
              <Button
                variant="contained"
                onClick={() => {
                  if (field.id) setPendingUploadFileFieldName(field.id)
                }}
                size="large"
                style={{ width: "40%" }}
                color={
                  errors?.customFields && errors.customFields[field.id || ""]
                    ? "error"
                    : "primary"
                }
              >
                Select File
              </Button>
            )}
          </Box>
        )
      } else if (field.fieldType === "dropdown") {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
              margin: "0 0 8px 0",
            }}
          >
            <Typography style={{ width: "100%" }}>{field.text}</Typography>
            <FormControl fullWidth required={!!required}>
              <InputLabel id={`${index}-label`}>Response</InputLabel>
              <Select
                labelId={`${index}-label`}
                id={`${index}-select`}
                value={data?.[field.id] ? data?.[field.id].response : ""}
                defaultValue={data?.[field.id] ? data?.[field.id].response : ""}
                onChange={(event) => {
                  if (field.id) {
                    handleTextChange(field.id, event.target.value || "")
                  }
                }}
                label="Response"
                error={Boolean(
                  errors?.customFields
                    ? errors.customFields[field.id || ""]
                    : false,
                )}
              >
                <MenuItem value={""} disabled>
                  Select One
                </MenuItem>
                {field.options &&
                  field.options?.split(",").map((option, optionIndex) => (
                    <MenuItem value={option} key={index + "-" + optionIndex}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === "multiselect") {
        const getValue = () => {
          if (
            field.id &&
            data &&
            data[field.id] &&
            data[field.id].response &&
            data[field.id].response !== ""
          ) {
            return data[field.id].response?.split(",")
          }
          return []
        }
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
              margin: "16px 0 16px 0",
            }}
          >
            <Typography style={{ width: "100%" }}>
              {field.text}
              {required ? " *" : ""}
            </Typography>
            <Autocomplete
              fullWidth
              id={`${index}-search`}
              multiple
              sx={{
                width: "100%",
              }}
              options={field.options?.split(",") || []}
              defaultValue={[]}
              value={getValue()}
              onChange={(_, value) => {
                if (field.id) {
                  handleTextChange(
                    field.id,
                    value.length > 0 ? value.toString() : "",
                  )
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Response"
                  error={Boolean(
                    errors?.customFields
                      ? errors.customFields[field.id || ""]
                      : false,
                  )}
                />
              )}
            />
          </Box>
        )
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "8px",
          margin: "16px 0 16px 0",
          alignItems: "left",
        }}
      >
        {customFields
          ?.filter((field) => field.required)
          ?.sort(sortByFieldType)
          .map((field: TemplateCustomField, index) => {
            return getField(field, index, true)
          })}

        {customFields?.filter((field) => !field.required)?.length > 0 && (
          <Typography
            component="h1"
            variant="subtitle1"
            style={{ marginTop: "8px" }}
          >
            Please answer the following if they apply to you. The more
            information you can provide, the faster we can process your request
            for credit.
          </Typography>
        )}

        {customFields
          ?.filter((field) => !field.required)
          ?.sort(sortByFieldType)
          .map((field: TemplateCustomField, index) => {
            return getField(field, index)
          })}
      </Box>

      {showError && (
        <Dialog open={showError}>
          <DialogTitle>Did you enter the right email?</DialogTitle>
          <DialogContent>
            <Typography>
              The email <b>{emailValidationResult?.email}</b> seems to not be
              correct. Please double check and make sure the address is valid to
              avoid delays in processing your application.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowError(false)
                handleTextChange(fieldIdToValidate, "")
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {pendingUploadFileFieldName && (
        <DropzoneDialog
          open={!!pendingUploadFileFieldName}
          onSave={(newFiles) => {
            handleFileUpload(pendingUploadFileFieldName, newFiles[0])
          }}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/bmp",
            "application/pdf",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/doc",
            "application/ms-doc",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/rtf",
            "text/plain",
            "text/csv",
          ]}
          showPreviews={true}
          maxFileSize={32000000}
          onClose={() => {
            setPendingUploadFileFieldName(null)
          }}
          filesLimit={1}
          fileObjects={[data?.[pendingUploadFileFieldName]]}
          // dropzoneText={field.text}
        />
      )}
    </>
  )
}
