// @mui
import React, { useState } from "react"
import { Application } from "src/types"

import {
  Box,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"

import { confirm } from "src/components/confirm"

// ----------------------------------------------------------------------

import { CloseOutlined, Edit, SaveOutlined } from "@mui/icons-material"
import { useFormik } from "formik"
import Label from "src/components/label"
import EditableTypography from "src/components/editable-typography"
import parsePhoneNumberFromString, {
  isPossiblePhoneNumber,
} from "libphonenumber-js"
import { info, error } from "src/utils/logger"

export default ({
  title,
  subheader,
  applicationId,
  data,
  rows,
  isPatching,
  patchApplication,
}: {
  title: string | React.ReactNode
  applicationId: string
  subheader?: React.ReactNode
  data: Application["data"]
  isPatching: boolean
  patchApplication: (
    applicationId: string,
    body: { data: Application["data"] },
    cb: () => void,
  ) => unknown
  rows: Array<Array<{ name: string; key: string }>>
}) => {
  const [editable, setEditable] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDataFieldUpdated = (key: string, value: any) => {
    formik.setFieldValue(key, value, false)
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: (values: Application["data"]) => {
      patchApplication(applicationId, { data: values }, () => {
        setEditable(false)
      })
    },
  })
  return (
    <>
      <CardHeader
        subheader={subheader}
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            {typeof title === "string" ? (
              <Typography variant="h6">{title}</Typography>
            ) : (
              title
            )}
            {isPatching && <CircularProgress />}
            {!isPatching && !editable && (
              <IconButton
                onClick={() => {
                  setEditable(true)
                }}
              >
                <Edit />
              </IconButton>
            )}
            {!isPatching && editable && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1px",
                }}
              >
                <Label variant="outlined" color={"error"}>
                  Unsaved Changes
                </Label>
                <IconButton
                  onClick={() => {
                    confirm("You are about to discard your changes.", {
                      okLabel: "Discard",
                    })
                      .then(
                        () => {
                          return setEditable(false)
                        },
                        () => {
                          info("cancelled")
                        },
                      )
                      .catch(() => {
                        error("error")
                      })
                  }}
                >
                  <CloseOutlined />
                </IconButton>
                <IconButton
                  onClick={() => {
                    confirm(
                      "You are about to make changes to this application.",
                      {
                        // okColor: "error",
                        okLabel: "Save",
                      },
                    )
                      .then(
                        () => {
                          formik
                            .validateForm()
                            .then((errors) => {
                              if (Object.keys(errors).length === 0) {
                                formik.submitForm()
                              }
                              return
                            })
                            .catch((err) => {
                              error(err)
                            })
                          return
                        },
                        () => {
                          info("cancelled")
                        },
                      )
                      .catch(() => {
                        setEditable(false)
                      })
                  }}
                >
                  <SaveOutlined />
                </IconButton>
              </Box>
            )}
          </Box>
        }
      />
      <Stack direction="row" sx={{ p: 3, gap: 3 }}>
        {rows.map((columns, index) => {
          return (
            <Stack
              spacing={1.5}
              alignItems="flex-start"
              sx={{ typography: "body2", width: "50%" }}
              key={index}
            >
              {columns.map((item, index) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    key={index}
                    style={{ height: "100%" }}
                  >
                    <Box
                      sx={{
                        color: "text.secondary",
                        width: 120,
                        flexShrink: 0,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          color: "text.secondary",
                          justifyContent: "center",
                          display: "flex",
                          height: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <span>{item.name}</span>
                      </Box>
                    </Box>
                    <EditableTypography
                      variant="body2"
                      editable={editable}
                      onChange={(val) => {
                        onDataFieldUpdated(item.key, val as string)
                      }}
                    >
                      {data[item.key] &&
                      isPossiblePhoneNumber(data[item.key], "US")
                        ? `${parsePhoneNumberFromString(
                            data[item.key] || "",
                          )?.formatInternational()} \next. ${
                            data[item.key + "Ext"] || "--"
                          }`
                        : data[item.key]}
                    </EditableTypography>
                  </Stack>
                )
              })}
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}
