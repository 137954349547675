import { Box, IconButton, MenuItem, Stack } from "@mui/material"
import Paper from "@mui/material/Paper"
import ListItemText from "@mui/material/ListItemText"
import CustomPopover, { usePopover } from "src/components/custom-popover"
import Iconify from "src/components/iconify"
import useResponsive from "src/hooks/useResponsive"
import {
  ArticleOutlined,
  FileDownloadOutlined,
  FilePresentOutlined,
  ReportOutlined,
} from "@mui/icons-material"
import { fDateTime } from "src/utils/formatTime"

type Props = {
  fileName: string
  createdAt: string
  onView: () => void
}

export default ({ createdAt, fileName, onView }: Props) => {
  const { open, onOpen, onClose, setOpen } = usePopover()
  const smUp = useResponsive("up", "sm")
  // const { name = "", path = "", preview = "" } = fileData(file, fileName)
  const renderAction = (
    <Box
      sx={{
        top: 0,
        right: 8,
        position: "absolute",
        ...(smUp && {
          flexShrink: 0,
          position: "unset",
        }),
      }}
    >
      <IconButton color={open ? "inherit" : "default"} onClick={onOpen}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </Box>
  )

  const renderText = (fileName: string, createdAt: string) => {
    return (
      <ListItemText
        primary={fileName}
        secondary={
          <>
            {/* {fData(fileSize)} */}
            {/* <Box
              sx={{
                mx: 0.75,
                width: 2,
                height: 2,
                borderRadius: "50%",
                bgcolor: "currentColor",
              }}
            /> */}
            Purchased {fDateTime(createdAt)}
          </>
        }
        primaryTypographyProps={{
          noWrap: true,
          typography: "subtitle2",
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: "span",
          alignItems: "center",
          typography: "caption",
          color: "text.disabled",
          display: "inline-flex",
        }}
      />
    )
  }

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        spacing={1}
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "unset", sm: "center" }}
        sx={{
          borderRadius: 2,
          bgcolor: "unset",
          cursor: "pointer",
          position: "relative",
          p: { xs: 2.5, sm: 2 },
          "&:hover": {
            bgcolor: "background.paper",
            boxShadow: (theme) => theme.shadows[20],
          },
        }}
      >
        <ArticleOutlined />
        {renderText(fileName, createdAt)}
        {renderAction}
      </Stack>
      <CustomPopover
        open={open}
        onClose={onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onView()
            onClose()
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
      </CustomPopover>
    </>
  )
}
