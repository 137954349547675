import { QueryClient } from "react-query"
import { CashApplication } from "src/types"
import { useTypedMutation } from "../useTypedMutation"

export function usePostCreateCashApplication(
  onSuccess?: (data: CashApplication) => void,
) {
  const ENDPOINT = "/cod/"
  const postApplication = useTypedMutation<FormData>("post/cod", onSuccess)

  const execute = (formData: FormData, onSuccess?: () => void) => {
    postApplication.mutate(
      {
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...postApplication, execute }
}
