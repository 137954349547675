import { Box, Divider, IconButton, MenuItem, Stack } from "@mui/material"
import Paper from "@mui/material/Paper"
import FileThumbnail from "src/sections/@dashboard/components/file/FileThumbnail"
import ListItemText from "@mui/material/ListItemText"
import { fData } from "src/utils/formatNumber"
import { fileData } from "src/utils/files"
import CustomPopover, { usePopover } from "src/components/custom-popover"
import Iconify from "src/components/iconify"
import useResponsive from "src/hooks/useResponsive"

type FileItemProps = {
  fileName?: string
  file: string
  fileSize: number
  onDownload: () => void
  onDelete: () => void
  onView: () => void
}

export default ({
  fileName = "",
  file,
  fileSize,
  onDownload,
  onDelete,
  onView,
}: FileItemProps) => {
  const { open, onOpen, onClose, setOpen} = usePopover()
  const smUp = useResponsive("up", "sm")
  const { name = "", path = "", preview = "" } = fileData(file, fileName)
  const renderAction = (
    <Box
      sx={{
        top: 0,
        right: 8,
        position: "absolute",
        ...(smUp && {
          flexShrink: 0,
          position: "unset",
        }),
      }}
    >
      <IconButton color={open ? "inherit" : "default"} onClick={onOpen}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </Box>
  )
  
  const renderText = (
    fileName: string,
    // createdAt: string,
    fileSize: number,
  ) => {
    return (
      <ListItemText
        primary={fileName}
        secondary={
          <>
            {fData(fileSize)}
            <Box
              sx={{
                mx: 0.75,
                width: 2,
                height: 2,
                borderRadius: "50%",
                bgcolor: "currentColor",
              }}
            />
            {/* {fDateTime(createdAt)} */}
          </>
        }
        primaryTypographyProps={{
          noWrap: true,
          typography: "subtitle2",
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: "span",
          alignItems: "center",
          typography: "caption",
          color: "text.disabled",
          display: "inline-flex",
        }}
      />)
  }

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        spacing={1}
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "unset", sm: "center" }}
        sx={{
          borderRadius: 2,
          bgcolor: "unset",
          cursor: "pointer",
          position: "relative",
          p: { xs: 2.5, sm: 2 },
          "&:hover": {
            bgcolor: "background.paper",
            boxShadow: (theme) => theme.shadows[20],
          }}}
      >
        <FileThumbnail file={file} sx={{ width: 36, height: 36, mr: 1 }} />
        {renderText(name, fileSize)}
        {renderAction}
      </Stack>
      <CustomPopover
        open={open}
        onClose={onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onView()
            onClose()
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        <MenuItem
          onClick={() => {
            onDownload()
            onClose()
          }}
        >
          <Iconify icon="solar:cloud-download-bold" />
          Download
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            onDelete()
            onClose()
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}
