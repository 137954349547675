import { useState } from "react"
// @mui
import PropTypes from "prop-types"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
// routes
import { RouterLink } from "../../../routes/components"
// components
import Iconify from "../../../components/iconify"
import {
  Check,
  Close,
  EditNoteOutlined,
  EditOutlined,
  PersonOutline,
  PictureAsPdf,
  SupportAgentOutlined,
} from "@mui/icons-material"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import { LoadingButton } from "@mui/lab"
import { Avatar, Box, Button } from "@mui/material"
import { confirm } from "src/components/confirm"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { useSnackbar } from "notistack"
import { getStatusString } from "../utils"
import Label from "src/components/label"
import { fDateTime } from "src/utils/formatTime"
import { formatCurrency } from "src/utils/formatNumber"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import { useUsers } from "src/queries/base/useUsers"
import { useAssignApplication } from "src/queries/credit/useAssignApplication"
import { stringAvatar } from "src/utils/avatar"
import ApprovalDialog from "./components/ApprovalDialog"
import MenuItem from "@mui/material/MenuItem"
import DropdownMenuButton from "src/components/dropdown-menu-button"
import RequestInformationDialog from "./components/RequestInformationDialog"
import { error } from "src/utils/logger"
import { Group } from "src/types"
import { Icon } from "@iconify/react"
import { useRequestCompleteApplication } from "src/queries/credit/useRequestCompleteApplication"
import { startCase } from "lodash"
import { format } from "date-fns"

export default function CreditApplicationDetailsToolbar(
  { application, backLink }, // onChangeStatus,
) {
  const { refetch: fetchPdf, isLoading: isFetchingPdf } = useDocumentPDF(
    application ? application.data.zohoReqId : "",
  )

  const { execute: assignApplication } = useAssignApplication()

  const { execute: requestApplicationCompletion } =
    useRequestCompleteApplication(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
    })

  const { enqueueSnackbar } = useSnackbar()

  const { data: users } = useUsers()

  const { refetch } = useCreditApplication(application.id)

  const { execute: patchApplication } = usePatchApplication(() => {
    refetch()
    enqueueSnackbar("Application updated", { variant: "success" })
  })

  const [showApprovalDialog, setShowApprovalDialog] = useState(false)
  const [showRequestInfoDialog, setShowRequestInfoDialog] = useState(false)
  const [showAssignmentDialog, setShowAssignmentDialog] = useState(undefined)

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          <IconButton id="toolbar-back" component={RouterLink} href={backLink}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4">
                {application.data.legalBusinessName ||
                  `${application.data.firstName} ${application.data.lastName}`}
              </Typography>
              <Label
                variant="soft"
                color={
                  (application.stage === 800 && "success") ||
                  (application.stage < 800 && "warning") ||
                  (application.stage === 900 && "error") ||
                  "default"
                }
              >
                {getStatusString(application.stage)}
              </Label>
              {application.data.customerId && (
                <Label variant="soft" color="default">
                  Customer ID {application.data.customerId}
                </Label>
              )}
            </Stack>
            {application.stage === 800 && application.approvedAt && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Latest Approval
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.approvedAt)}
                </Label>
                {application.data.creditLimit && (
                  <Label variant="soft" color="default">
                    {formatCurrency(
                      application.data.creditLimit,
                      application.data.currency,
                    )}
                  </Label>
                )}
                {application.data.netTerms && (
                  <Label variant="soft" color="default">
                    {application.data.netTerms}
                  </Label>
                )}
              </Stack>
            )}
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                Submitted
              </Typography>
              <Label variant="soft" color="default">
                {fDateTime(application.createdAt)}
              </Label>
            </Stack>
            {application.lastViewed && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Last Accessed
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.lastViewed)}
                </Label>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          flexGrow={1}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "right",
          }}
        >
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {application.stage == 800 && (
              <Button
                color="primary"
                variant="outlined"
                // endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                onClick={() => {
                  setShowApprovalDialog(true)
                }}
                sx={{ textTransform: "capitalize" }}
              >
                Edit Approval
              </Button>
            )}
            {application.stage < 800 && (
              <>
                <DropdownMenuButton title="Approve/Reject">
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowApprovalDialog(true)
                    }}
                  >
                    <Check />
                    Approve
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      confirm("You are about to reject this application.", {
                        okColor: "error",
                        okLabel: "Reject",
                      })
                        .then(() => {
                          return patchApplication(application.id, {
                            stage: 900,
                          })
                        })
                        .catch((err) => {
                          error("error in confirm dialog", err)
                        })
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <Close style={{ fill: "red" }} />
                    Reject
                  </MenuItem>
                </DropdownMenuButton>
                <DropdownMenuButton title="Request from Applicant">
                  <MenuItem
                    disableRipple
                    onClick={() => setShowRequestInfoDialog(true)}
                  >
                    <EditNoteOutlined />
                    Additional Information
                  </MenuItem>

                  <MenuItem
                    disableRipple
                    onClick={() => {
                      confirm("Send reminder to complete application?")
                        .then(
                          () => {
                            return requestApplicationCompletion(
                              application.id || "",
                            )
                          },
                          () => {
                            info("cancelled")
                          },
                        )
                        .catch(() => {
                          error("cancelled")
                        })
                    }}
                  >
                    <Icon
                      icon="mdi:email-outline"
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                    />
                    Send Reminder
                  </MenuItem>
                </DropdownMenuButton>
              </>
            )}
          </Stack>
          <Stack
            flexGrow={1}
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!application.assignee ? (
              <Button
                variant="outlined"
                startIcon={<PersonOutline />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                Assign Credit Manager
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    {...stringAvatar(
                      `${
                        application.assignee.firstName
                          ? application.assignee.firstName
                          : application.assignee.email
                      }`,
                      {
                        width: 24,
                        height: 24,
                      },
                    )}
                  />
                  {application.assignee.firstName
                    ? `${application.assignee.firstName}${
                        application.assignee.lastName
                          ? " " + application.assignee.lastName[0]
                          : ""
                      }`
                    : application.assignee.email}
                </Box>
              </Button>
            )}
            {!application.salesRep ? (
              <Button
                variant="outlined"
                startIcon={<SupportAgentOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                Assign Sales Rep
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <SupportAgentOutlined />
                  {application.salesRep.firstName
                    ? `${application.salesRep.firstName}${
                        application.salesRep.lastName
                          ? " " + application.salesRep.lastName[0]
                          : ""
                      }`
                    : application.salesRep.email}
                </Box>
              </Button>
            )}

            {!application.seller.name.includes("Quecan") && (
              <LoadingButton
                color="primary"
                variant="outlined"
                startIcon={<PictureAsPdf />}
                loading={isFetchingPdf}
                disabled={
                  application.stage < 200 || !application.data.zohoReqId
                }
                onClick={() => {
                  fetchPdf()
                    .then((res) => {
                      function saveByteArray(fileName, b64) {
                        const link = document.createElement("a")
                        // link.href = window.URL.createObjectURL(blob)
                        link.href = "data:application/pdf;base64," + b64
                        link.download = fileName
                        link.click()
                      }
                      if (!res.data) throw new Error("No data")
                      return saveByteArray(
                        `${startCase(
                          application.data.legalBusinessName ||
                            `${application.data.firstName} ${application.data.lastName}`,
                        )} Credit Application ${format(
                          new Date(application.createdAt),
                          "yyyy/MM/dd",
                        )}.pdf`,
                        res.data,
                      )
                    })
                    .catch((err) => error(err))
                }}
              >
                Download Signed PDF
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Stack>
      {showRequestInfoDialog && (
        <RequestInformationDialog
          application={application}
          open
          onClose={() => {
            setShowRequestInfoDialog(false)
          }}
        />
      )}
      {showApprovalDialog && (
        <ApprovalDialog
          application={application}
          open
          onClose={() => {
            refetch()
            setShowApprovalDialog(false)
          }}
        />
      )}
      {users && showAssignmentDialog === "CREDIT_MANAGER" && (
        <AssignmentSelectionDialog
          open={!!showAssignmentDialog}
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id, user.id, () => {
              refetch()
            })
          }}
          contacts={users.filter((user) =>
            user.groups.includes(Group.CreditManager),
          )}
        />
      )}
      {users && showAssignmentDialog === "SALES" && (
        <AssignmentSelectionDialog
          open={!!showAssignmentDialog}
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id, user.id, () => {
              refetch()
            })
          }}
          contacts={users.filter((user) => user.groups.includes(Group.Sales))}
        />
      )}
    </>
  )
}

CreditApplicationDetailsToolbar.propTypes = {
  backLink: PropTypes.string,
  application: PropTypes.object,
}
