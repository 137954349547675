import { useEffect } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { Box, DialogContent, TextField } from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { UseCreateSellerType, UseCreateBuyerType } from "../../../queries"
import { Business, User } from "../../../types"

type Props = {
  mutation: UseCreateSellerType | UseCreateBuyerType
  defaults?: Partial<User>
  children: JSX.Element
}

export default ({ mutation, defaults = {}, children }: Props) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
      email: defaults.email,
      phoneNumber: "",
      ...defaults,
    },
    validationSchema: validationSchema,
    onSubmit: (values: Partial<Business>) => {
      mutation.execute(values, () => {
        formik.resetForm()
      })
    },
  })

  // reset form on mount
  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        style={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          width: "75vh",
        }}
      >
        <TextField
          autoFocus
          id="name"
          label="Business Name"
          type="text"
          fullWidth
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          id="email"
          label="Email"
          type="email"
          fullWidth
          disabled={Boolean(defaults.email)}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <ReactPhoneInput
          value={formik.values.phoneNumber}
          country={"ca"}
          onChange={(event) => {
            formik.setFieldValue("phoneNumber", "+" + event, false)
          }}
          component={TextField}
          containerStyle={{ width: "100%", marginTop: "8px" }}
          inputProps={{
            id: "phoneNumber",
            required: true,
            error: Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber,
            ),
          }}
        />
      </Box>
      {children}
    </form>
  )
}
