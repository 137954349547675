import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import Pagination from "@mui/material/Pagination"
import { ZoomIn, ZoomOut } from "@mui/icons-material"
import "react-pdf/dist/esm/Page/TextLayer.css"

export default ({
  title = "View Terms and Conditions",
  fileUrl,
  onClose,
}: {
  title?: string
  fileUrl: string
  onClose: () => void
}) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [fileUrl])

  return (
    <Dialog
      open={!!fileUrl}
      onClose={() => {
        onClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth={false}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", gap: "1rem" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setScale((prev) => prev + 0.1)
              }}
            >
              <ZoomIn />
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (scale < 0.2) return
                setScale((prev) => prev - 0.1)
              }}
            >
              <ZoomOut />
            </Button>
          </Box>
          <Pagination
            count={numPages}
            color="primary"
            onChange={(event, page) => setPageNumber(page)}
          />
          <Button
            variant="contained"
            onClick={() => {
              onClose()
            }}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
