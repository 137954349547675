import { QueryClient, UseMutationResult } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { MutationVariables, Business } from "../../types"

type ExecuteType = {
  execute: (body: Partial<Business>, onSuccess: () => void) => void
}

export type UseCreateSellerType = UseMutationResult<
  unknown,
  unknown,
  MutationVariables<Business>,
  unknown
> &
  ExecuteType

export function useCreateSeller(onSuccess?: (data: string) => void) {
  const ENDPOINT = "/seller/"
  const createSeller = useTypedMutation<Business>("post/seller", onSuccess)

  const execute = (body: Partial<Business>, onSuccess?: () => void) => {
    createSeller.mutate(
      {
        endpoint: ENDPOINT,
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...createSeller, execute }
}
