import { useEffect, useState } from "react"
import Iframe from "react-iframe"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { useDocumentDetails } from "src/queries/vendors/useDocumentDetails"

export default ({
  applicationId,
  onContinue,
}: {
  applicationId?: string
  onContinue: () => void
}) => {
  const { data, isLoading } = useCashApplication(applicationId)

  const [fetchDetailsEnabled, setFetchDetailsEnabled] = useState(false)

  const { data: details } = useDocumentDetails(
    data?.data["zohoReqId"] || "",
    "cod",
    fetchDetailsEnabled,
    true,
  )

  useEffect(() => {
    if (details && details?.requests?.requestStatus == "completed") {
      onContinue()
    }
  }, [details, onContinue])

  return (
    <>
      {!isLoading && data && (
        <>
          <Iframe
            url={data.data["signingUrl"]}
            width="100%"
            height="750px"
            id="signature-zoho"
            // className=""
            display="block"
            position="relative"
            onLoad={() => {
              if (!isLoading) {
                setFetchDetailsEnabled(true)
              }
            }}
          />
        </>
      )}
    </>
  )
}
