import { Document, Text } from "@react-pdf/renderer"
import { PDFItem, PDFTemplate, QuestionItem } from "src/types/credit/pdf"

import { CreditApplication, TemplateCustomField } from "src/types"

import { PageBase, Cell, Input, Block, Title } from "./Elements"

export default ({
  data,
  businessData,
  pdfTemplate,
  customQuestionsTemplate,
}: {
  data: CreditApplication
  businessData: {
    name?: string
    logo?: string
    color?: string
  }
  pdfTemplate: PDFTemplate
  customQuestionsTemplate: TemplateCustomField[] | undefined
}) => {
  const { data: fieldsData } = data

  const pageAdditionalQuestions = getPagesQuestionsItems(
    customQuestionsTemplate,
    fieldsData.customFields,
  )

  const BusinessDetailsPage = () => {
    return (
      <PageBase
        title={pdfTemplate["Business Details"].title}
        logoSrc={businessData.logo}
      >
        {renderPageItems(pdfTemplate["Business Details"].items, fieldsData)}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["User Details"])}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Business Details"])}
      </PageBase>
    )
  }

  const OwnersAndContactsPage = () => {
    return (
      <PageBase
        title={pdfTemplate["Owners and Contacts"].title}
        logoSrc={businessData.logo}
      >
        {renderPageItems(pdfTemplate["Owners and Contacts"].items, fieldsData)}

        {pageAdditionalQuestions &&
          renderQuestionsSection(
            pageAdditionalQuestions["Owners and Contacts"],
          )}
      </PageBase>
    )
  }

  const ReferencePage = () => {
    const getCountryBasedFields = (
      templateFields: PDFItem[],
      country: string,
    ) => {
      if (country === "Canada") {
        return templateFields
      }

      const newTemplateArr = [...templateFields]

      const fieldsForUSA: PDFItem[] = [
        {
          label: "Account Number",
          type: "input",
          spacing: "48",
          dataField: "bankAccountNumber",
        },
        {
          label: "Routing Number",
          type: "input",
          spacing: "48",
          dataField: "bankRoutingNumber",
        },
      ]

      const mutualTemplateFieldIndex = templateFields.findIndex(
        (item) => item.dataField === "bankAccountNumber",
      )

      newTemplateArr.splice(mutualTemplateFieldIndex, 3, ...fieldsForUSA)

      return newTemplateArr
    }

    return (
      <PageBase
        title={pdfTemplate["Trade References"].title}
        logoSrc={businessData.logo}
      >
        {renderPageItems(pdfTemplate["Trade References"].items, fieldsData)}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Trade References"])}

        <Title
          label={pdfTemplate["Bank Reference"].title}
          extraStyles={{ marginTop: "20px" }}
        />

        {fieldsData.bankAccountNumber === undefined ? (
          <Text
            style={{
              fontWeight: 600,
              fontSize: "14px",
              textAlign: "left",
              marginTop: "6px",
            }}
          >
            Instant Bank Reference
          </Text>
        ) : (
          renderPageItems(
            getCountryBasedFields(
              pdfTemplate["Bank Reference"].items,
              fieldsData.businessCountry,
            ),
            fieldsData,
          )
        )}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Bank Reference"])}
      </PageBase>
    )
  }

  const AdditionalQuestionAndGuarantyPage = () => {
    return (
      <PageBase
        title={pdfTemplate["Additional Questions"].title}
        logoSrc={businessData.logo}
      >
        {renderPageItems(pdfTemplate["Additional Questions"].items, fieldsData)}

        {pageAdditionalQuestions &&
          renderQuestionsSection(
            pageAdditionalQuestions["Additional Questions"],
          )}

        <Title
          label={pdfTemplate["Personal Guaranty"].title}
          extraStyles={{ marginTop: "20px" }}
        />

        {renderPageItems(pdfTemplate["Personal Guaranty"].items, fieldsData)}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Personal Guaranty"])}
      </PageBase>
    )
  }

  return (
    <Document>
      <BusinessDetailsPage />
      <OwnersAndContactsPage />
      <ReferencePage />
      <AdditionalQuestionAndGuarantyPage />
    </Document>
  )
}

function renderPageItems(
  items: PDFItem[],
  fieldsData: {
    [key: string]: string | number
  },
) {
  const getValue = (
    item: PDFItem,
    fieldsData: { [key: string]: string | number },
  ) => {
    if (item.label.includes("SSN") || item.label.includes("Driver License")) {
      const res = item.dataField
        ? "*********" + fieldsData[item.dataField]?.toString().slice(-2) || ""
        : "*********"
      return res
    }
    return item.dataField ? fieldsData[item.dataField] : ""
  }

  return (
    <Block>
      {items.map((item) => {
        if (item.type === "input") {
          return (
            <Cell key={item.dataField} spacing={item.spacing}>
              <Input label={item.label} value={getValue(item, fieldsData)} />
            </Cell>
          )
        }

        if (item.type === "text") {
          return (
            <Cell key={item.label} spacing={item.spacing}>
              <Text
                style={{
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingTop: "4px",
                  textAlign: "left",
                }}
              >
                {item.label}
              </Text>
            </Cell>
          )
        }
      })}
    </Block>
  )
}

function renderQuestionsSection(items: QuestionItem[]) {
  if (!items) {
    return
  }

  return (
    <>
      {items.map((item) => (
        <Block key={item.id}>
          <Cell key={item.label} spacing="48">
            <Text
              style={{
                fontSize: "11px",
                marginTop: "10px",
                paddingTop: "10px",
                textAlign: "left",
              }}
            >
              {item.label}
            </Text>
          </Cell>

          <Cell key={item.id} spacing="48">
            <Input label="" value={String(item.value) ?? ""} />
          </Cell>
        </Block>
      ))}
    </>
  )
}

function getPagesQuestionsItems(
  template: TemplateCustomField[] | undefined,
  dataCustomFields: {
    [key: string]: any
  },
) {
  if (!template || !dataCustomFields) return

  const pagesQuestions = Object.keys(dataCustomFields).reduce(
    (
      acc: {
        [key: string]: QuestionItem[]
      },
      curr,
    ) => {
      const questionTemplate = template.find((question) => question.id === curr)

      if (!questionTemplate || !questionTemplate?.associatedPage) return acc

      if (
        questionTemplate?.associatedPage &&
        !acc[questionTemplate.associatedPage]
      ) {
        acc[questionTemplate.associatedPage] = []
      }

      acc[questionTemplate.associatedPage].push({
        id: questionTemplate.id,
        label: questionTemplate.text,
        type: questionTemplate.fieldType === "text" ? "text" : "input",
        value: dataCustomFields[curr].response ?? "",
      })

      return acc
    },
    {},
  )

  return pagesQuestions
}
