export const PAGE_LABEL_OWNERS = "Owners and Contacts"
export const PAGE_LABEL_PERSONAL_GUARANTY = "Personal Guaranty"
export const PAGE_LABEL_TRADE_REFERENCES = "Trade References"
export const PAGE_LABEL_BANK_REFERENCE = "Bank Reference"
export const PAGE_LABEL_USER_DETAILS = "User Details"
export const PAGE_LABEL_BUSINESS_DETAILS = "Business Details"
export const PAGE_LABEL_ADDITIONAL_QUESTIONS = "Additional Questions"

export const BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR = "Credit Manager Selector"
export const BUSINESS_CONFIG_REVENUE = "Revenue"
export const BUSINESS_CONFIG_BUSINESS_ADDRESS = "Business Address"
export const BUSINESS_CONFIG_SHIPPING_ADDRESS = "Shipping Address"
export const BUSINESS_CONFIG_BILLING_ADDRESS = "Billing Address"
export const BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS =
  "Credit Card Billing Address"
export const BUSINESS_CONFIG_SERVICE_ADDRESS = "Service Address"

export const USER_CONFIG_BUSINESS_TYPE_LIST = "Business Type List"
