import { useState } from "react"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  TextField,
} from "@mui/material"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { usePostAdditionalDataRequest } from "src/queries/credit/additional/usePostAdditionalDataRequest"
import { useSnackbar } from "notistack"
import useGenerateApplicationPdf from "src/hooks/useGenerateApplicationPdf"

export default ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) => {
  const { data: template } = useApplicationTemplate(application.seller?.id)

  const { execute: generatePdf } = useGenerateApplicationPdf(application)

  const [selectedPages, setSelectedPages] = useState<
    ApplicationTemplate["formTemplate"]["pages"]
  >([])

  const [requestSignature, setRequestSignature] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { execute, isLoading } = usePostAdditionalDataRequest(() => {
    enqueueSnackbar("Request sent successfully.", { variant: "success" })
  })

  const [instructions, setInstructions] = useState("")

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>Request Information</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography>
            Select the information you would like to request from the applicant.
            We will send an email to <b>{application.data.email}</b> with a link
            to upload the requested information. You will receive a notification
            when completed.
          </Typography>
          {template?.formTemplate?.pages
            .filter((p) => p.label !== "User Details")
            .map((page) => (
              <FormControlLabel
                key={page.label}
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPages([...selectedPages, page])
                      } else {
                        setSelectedPages(
                          selectedPages.filter(
                            (
                              p: ApplicationTemplate["formTemplate"]["pages"][0],
                            ) => p.label !== page.label,
                          ),
                        )
                      }
                    }}
                  />
                }
                label={page.label}
              />
            ))}
          {template?.customFields?.some(
            (field) => field.associatedPage === "Additional Questions",
          ) && (
            <FormControlLabel
              key={"Additional Questions"}
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedPages([
                        ...selectedPages,
                        {
                          label: "Additional Questions",
                          description: "",
                          enabled: true,
                          required: true,
                          config: [],
                        },
                      ])
                    } else {
                      setSelectedPages(
                        selectedPages.filter(
                          (
                            p: ApplicationTemplate["formTemplate"]["pages"][0],
                          ) => p.label !== "Additional Questions",
                        ),
                      )
                    }
                  }}
                />
              }
              label={"Additional Questions"}
            />
          )}
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                id="request-signature-checkbox"
                onChange={(event) => {
                  setRequestSignature(event.target.checked)
                }}
                checked={requestSignature}
              />
            }
            label="Request a new digital signature from the applicant."
          />
          {requestSignature && (
            <Typography>
              Note: Please ensure <b>{application.data.email}</b> is authorized
              to sign the application. If not, close this dialog, update the
              email in the Submitted By section and re-open this dialog.
            </Typography>
          )}

          <Grid container>
            <Grid item md={12}>
              <Typography>Applicant Instructions</Typography>
              <TextField
                type="text"
                fullWidth
                multiline
                placeholder="Enter any additional instructions for the applicant here. This will be included in the email."
                value={instructions}
                onChange={(e) => {
                  setInstructions(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          onClick={() => {
            generatePdf()
              .then((res) => {
                return execute(
                  application.id || "",
                  selectedPages,
                  instructions,
                  requestSignature,
                  new File([res], "application_pdf.pdf"),
                  () => {
                    enqueueSnackbar("Request sent.", {
                      variant: "success",
                    })
                    onClose()
                  },
                )
              })
              .catch((err) => console.log(err))
          }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
