// @mui
import { TextField, InputAdornment } from "@mui/material"

import { useUser } from "src/queries"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

import { useSnackbar } from "notistack"

import { ContentCopyOutlined } from "@mui/icons-material"
import { getPath } from "@mui/system"

export default ({ type = "credit" }: { type?: "credit" | "cash" }) => {
  const { data: userData } = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { data } = useApplicationTemplate(
    userData?.userToBusiness?.business?.id,
    false,
    userData?.userToBusiness?.business !== undefined,
  )

  if (!data || !userData) return <></>

  const FONT_SIZE = 9
  const DEFAULT_INPUT_WIDTH = 300

  const path =
    type === "credit"
      ? "/ua-trade-credit/base?business_id="
      : "/ua-cod/base?business_id="

  let applicationUrl = ""
  if (location.host.includes("netnow.io")) {
    applicationUrl =
      "https://app.netnow.io/" +
      userData?.userToBusiness?.business.name.replaceAll(" ", "").toLowerCase()

    if (type === "cash") {
      applicationUrl = applicationUrl + "-cod"
    }
  } else {
    applicationUrl =
      location.protocol + "//" + location.host + path + data.seller
  }
  let inputWidth = DEFAULT_INPUT_WIDTH
  if (applicationUrl.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
    inputWidth = (applicationUrl.length + 1) * FONT_SIZE
  }

  if (!applicationUrl) return <></>

  return (
    <TextField
      value={applicationUrl}
      label="Organization Application Link (click to copy)"
      sx={{ input: { cursor: "pointer" } }}
      InputProps={{
        style: { width: `${inputWidth}px` },
        endAdornment: (
          <InputAdornment position="end">
            <ContentCopyOutlined />
          </InputAdornment>
        ),
      }}
      onClick={() => {
        enqueueSnackbar("Link copied to clipboard.", {
          variant: "info",
        })
        navigator.clipboard.writeText(applicationUrl)
      }}
    />
  )
}
