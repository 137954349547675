import { SupportAgentOutlined } from "@mui/icons-material"
import {
  Button,
  TableRow,
  TableCell,
  ListItemText,
  Avatar,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material"
import { Box } from "@mui/system"
import { formatDistance } from "date-fns"
import Iconify from "src/components/iconify"
import { CashApplication } from "src/types"
import { stringAvatar } from "src/utils/avatar"
import { formatCurrency } from "src/utils/formatNumber"
import { fDate } from "src/utils/formatTime"
import { termsFormatter } from "src/utils/formatters"
import { getStatusLabel } from "../../utils"
import { useNavigate } from "react-router-dom"
import { useUser } from "src/queries"
import Label from "src/components/label"

export default ({
  row,
  index,
  handleOpenMenu,
}: {
  row: CashApplication
  index: number
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const navigate = useNavigate()

  const { isCreditManager } = useUser()

  return (
    <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
      <TableCell align="left">
        <ListItemText
          style={{
            cursor: isCreditManager ? "pointer" : "default",
          }}
          primaryTypographyProps={{
            typography: "subtitle2",
            sx: { textTransform: "capitalize" },
          }}
          secondaryTypographyProps={{
            typography: "caption",
          }}
          primary={
            <Box
              key={index}
              component="span"
              sx={{
                color: "text.primary",
              }}
            >
              {row.data.legalBusinessName ||
                `${row.data.firstName} ${row.data.lastName}`}
            </Box>
          }
          secondary={
            <Box
              key={index}
              component="span"
              sx={{
                color: "text.secondary",
              }}
            >
              {row.data.email}
            </Box>
          }
          onClick={() => {
            navigate("/cash-applications/" + row.id + "/")
          }}
        />
        {/* <Typography variant="subtitle2" noWrap></Typography> */}
      </TableCell>
      <TableCell align="left">{fDate(row.createdAt)}</TableCell>
      <TableCell align="left">{getStatusLabel(row.stage)}</TableCell>
      <TableCell align="left">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {row.assignee ? (
            <>
              <Avatar
                {...stringAvatar(
                  `${
                    row.assignee.firstName
                      ? row.assignee.firstName
                      : row.assignee.email
                  }`,
                  {
                    width: 28,
                    height: 28,
                  },
                )}
              />
              {row.assignee.firstName
                ? `${row.assignee.firstName} ${
                    row.assignee.lastName ? row.assignee.lastName[0] : ""
                  }`
                : row.assignee.email}
            </>
          ) : (
            <>
              {/* <Avatar
                    {...stringAvatar("?", {
                      width: 28,
                      height: 28,
                    })}
                  /> */}
              Unassigned
            </>
          )}
        </Box>
      </TableCell>
      <TableCell align="left">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {row.salesRep ? (
            <>
              <SupportAgentOutlined />
              {row.salesRep.firstName
                ? `${row.salesRep.firstName} ${
                    row.salesRep.lastName ? row.salesRep.lastName[0] : ""
                  }`
                : row.salesRep.email}
            </>
          ) : (
            <>
              {/* <Avatar
                    {...stringAvatar("?", {
                      width: 28,
                      height: 28,
                    })}
                  /> */}
              Unassigned
            </>
          )}
        </Box>
      </TableCell>

      <TableCell align="left">
        <Tooltip title={fDate(row.lastViewed)} placement="top" arrow>
          <Typography>
            {row.lastViewed
              ? formatDistance(new Date(row.lastViewed), new Date(), {
                  addSuffix: true,
                })
              : ""}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        {row.stage !== 50 && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <IconButton
            id={`menu-${index}`}
            row={row.id}
            size="large"
            color="inherit"
            onClick={handleOpenMenu}
          >
            <Iconify icon={"eva:more-vertical-fill"} />
          </IconButton>
        )}
        {/* {isCreditManager && row.stage === 50 && (
          <Button
            variant="contained"
            onClick={() => {
              navigate("/manual-application/" + row.id + "/")
            }}
          >
            Continue
          </Button>
        )} */}
      </TableCell>
    </TableRow>
  )
}
