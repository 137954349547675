import { QueryClient } from "react-query"
import { TradeReference } from "src/types"
import { useTypedMutation } from "../useTypedMutation"

/**
 *  Use to add a new trade reference to an existing application
 * @param onSuccess
 * @returns
 */
export function usePostTradeReference(
  onSuccess?: (data: TradeReference) => void,
) {
  const ENDPOINT = "/application/base/trade_references/"
  const post = useTypedMutation<TradeReference & { sendEmail: boolean }>(
    "post/application/base/trade_references",
    onSuccess,
  )

  const execute = (
    body: Partial<TradeReference>,
    sendEmail: boolean,
    onSuccess = () => {
      return
    },
    onError = () => {
      return
    },
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT + "?application_id=" + body.application,
        body: {
          ...body,
          sendEmail,
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...post, execute }
}
