// component
import { Settings } from "@mui/icons-material"
import SvgColor from "../../../components/svg-color"
import { Icon } from "@iconify/react"
import { Group } from "src/types"

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "buyers",
    path: "/",
    icon: <Icon icon="ic:outline-shopping-cart" width={24} />,
  },
  {
    title: "accounting (coming soon)",
    path: "/",
    icon: <Icon icon="ic:outline-receipt-long" width={24} />,
  },

  {
    title: "credit applications",
    path: "/applications",
    icon: <Icon icon="mdi:hand-coin-outline" width={24} />,
  },
  {
    title: "cash applications",
    path: "/cash-applications",
    icon: <Icon icon="mdi:credit-card-outline" width={24} />,
  },

  {
    title: "monitoring",
    path: "/monitoring",
    icon: <Icon icon="mdi:monitor-eye" width={24} />,
  },

  {
    title: "settings",
    path: "/settings",
    icon: <Settings />,
  },
]

const getNavConfig = (user: any, businessServices: string[]) => {
  let config = navConfig
  if (user) {
    if (!user.groups.includes(Group.CreditManager)) {
      config = navConfig.filter((item) => item.title !== "settings")
    }
  }
  if (businessServices && !businessServices.includes("cod")) {
    config = navConfig.filter((item) => item.title !== "cash applications")
  }
  return config
}

export default getNavConfig
