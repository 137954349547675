import { QueryClient } from "react-query"
import { CashApplication } from "src/types"
import { useTypedMutation } from "../useTypedMutation"

// to be used for generating apps based on the base template
export function usePostGenerateCashApplication(
  onSuccess?: (data: CashApplication) => void,
) {
  const ENDPOINT = "/cod/generate"
  const postGenerateApplication = useTypedMutation<FormData>(
    "post/cod/generate",
    onSuccess,
  )

  const execute = (body: Partial<CashApplication>) => {
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "files" && body["files"]) {
        body["files"].forEach((file, index) => {
          if (file instanceof File) {
            formData.append("file" + index, file as File)
          } else {
            formData.append("file" + index, JSON.stringify(file))
          }
        })
      } else if (item.includes("data")) {
        const data = body["data"]
        if (data) {
          // if (data["customFields"]) {
          //   const customFields = data[
          //     "customFields"
          //   ] as Array<TemplateCustomField>
          //   Object.entries(customFields).forEach(([key, value]) => {
          //     if (value.fieldType === "file") {
          //       formData.append(key, value.file as File)
          //     } else {
          //       formData.append(key, value.response || "")
          //     }
          //   })
          // }
          // delete data["customFields"]
          formData.append(item, JSON.stringify(data))
        }
      } else if (item.includes("Date")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(
          item,
          new Date(body[item as keyof CashApplication] as Date).toISOString(),
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof CashApplication])
      }
    }
    postGenerateApplication.mutate(
      {
        method: "post",
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // anonymous,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...postGenerateApplication, execute }
}
