// @mui

import {
  Box,
  Button,
  Card,
  CardHeader,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import { ApplicationTemplate } from "../../../types"

import { DropzoneDialog } from "mui-file-dropzone"
import { useEffect, useState } from "react"
import { usePatchBusinessBranding } from "../../../queries/base/usePatchBusinessBranding"
import { loadLogoIntoImg } from "../../../utils/imageTools"
import PDFViewerDialog from "../../../components/pdf/PDFViewerDialog"

// ----------------------------------------------------------------------

export default ({
  template,
  onColorChanged,
  onFileChanged,
}: {
  template: Partial<ApplicationTemplate>
  onColorChanged: (arg0: string, arg1: string) => void
  onFileChanged: () => void
}) => {
  const [uploadDialogFieldName, setUploadFieldName] = useState<string | null>(
    null,
  )

  const [showTermsDialog, setShowTermsDialog] = useState(false)

  const { execute } = usePatchBusinessBranding()

  useEffect(() => {
    loadLogoIntoImg(document.getElementById("logo-preview"), template.logo)
  }, [template.logo])

  const renderBranding = (
    <>
      <CardHeader title="Branding" />
      <Stack
        spacing={1.5}
        sx={{ p: 3, typography: "body2" }}
        style={{ height: "100%" }}
      >
        {!template.terms && (
          <Typography color="error">
            Please upload your terms and conditions
          </Typography>
        )}
        {!template.logo && (
          <Typography color="error">Please upload your company logo</Typography>
        )}
        <Stack direction="row" alignItems="center" style={{ gap: "1rem" }}>
          <Box
            component="span"
            sx={{ color: "text.secondary", width: 220, flexShrink: 0 }}
          >
            Color (in HEX)
          </Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
            value={template.color}
            onChange={(event) => {
              onColorChanged("color", event.target.value)
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" style={{ gap: "1rem" }}>
          <Box
            component="span"
            sx={{ color: "text.secondary", width: 220, flexShrink: 0 }}
          >
            Company Logo
          </Box>
          {template.logo && (
            <img
              id="logo-preview"
              style={{
                maxHeight: "75px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                maxWidth: "75px",
              }}
            />
          )}

          <Button
            id="change-logo-button"
            variant="contained"
            onClick={() => {
              setUploadFieldName("logo")
            }}
            size="large"
          >
            Change
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" style={{ gap: "1rem" }}>
          <Box
            component="span"
            sx={{ color: "text.secondary", width: 220, flexShrink: 0 }}
          >
            Terms and Conditions
          </Box>
          {template.terms && (
            <Button
              variant="outlined"
              onClick={() => {
                setShowTermsDialog(true)
              }}
              size="large"
            >
              View
            </Button>
          )}
          <Button
            id="change-terms-button"
            variant="contained"
            onClick={() => {
              setUploadFieldName("terms")
            }}
            size="large"
          >
            Change
          </Button>
        </Stack>
      </Stack>
    </>
  )

  return (
    <Card style={{ height: "100%" }}>
      {renderBranding}
      {/* <CardActions>
        <Stack
          style={{
            padding: "1rem",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "end",
          }}
        >
          
        </Stack>
      </CardActions> */}
      <DropzoneDialog
        open={uploadDialogFieldName !== null}
        onSave={(newFiles) => {
          if (
            uploadDialogFieldName &&
            ["logo", "terms"].includes(uploadDialogFieldName) &&
            template?.seller &&
            newFiles.length > 0
          ) {
            execute(
              template.seller,
              uploadDialogFieldName === "logo" ? newFiles[0] : undefined,
              uploadDialogFieldName === "terms" ? newFiles[0] : undefined,
              () => {
                setUploadFieldName(null)
                onFileChanged()
              },
            )
          }
        }}
        acceptedFiles={
          uploadDialogFieldName === "logo" ? ["image/png"] : ["application/pdf"]
        }
        showPreviews={true}
        maxFileSize={5000000}
        onClose={() => {
          setUploadFieldName(null)
        }}
        filesLimit={1}
        fileObjects={null}
        dropzoneText={
          uploadDialogFieldName === "logo"
            ? "Drag and drop a PNG file here or click to select a file"
            : "Drag and drop a PDF file here or click to select a file"
        }
      />

      {template.terms && showTermsDialog && (
        <PDFViewerDialog
          fileUrl={template.terms}
          onClose={() => {
            setShowTermsDialog(false)
          }}
        />
      )}
    </Card>
  )
}
