import { useState, useCallback, FormEvent, useEffect } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { CreditApplication, Group } from "src/types"
import { usePostCreateApplication, useUser } from "src/queries"
import { useSnackbar } from "notistack"
import { useUsers } from "src/queries/base/useUsers"
import { usePostCreateCashApplication } from "src/queries/cod/usePostCreateCashApplication"
import { PERSONAL_GUARANTY } from "src/statics"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { getPersonalGuarantyFromSchema, isEmail } from "src/utils/utils"
import { themePalette } from "src/theme/palette"

type props = {
  open: boolean
  toggle: (open: boolean) => void
  refetch: () => void
  type: "credit" | "cash"
}

const NewApplicationForm = ({
  open,
  toggle,
  refetch,
  type = "credit",
}: props) => {
  const { enqueueSnackbar } = useSnackbar()

  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [personalGuarantor, setPersonalGuarantor] = useState<string>(
    PERSONAL_GUARANTY.OFF,
  )

  const [applicationUrl, setApplicationUrl] = useState<string | undefined>(
    undefined,
  )

  const { data: user, isSales, isCreditManager } = useUser()

  const [salesRep, setSalesRep] = useState<string | undefined>(undefined)

  const { data: allUsers } = useUsers()

  const {
    data: template,
    dataSchema,
    steps,
    applicationError,
  } = useApplicationTemplate(
    user?.userToBusiness?.business?.id,
    false,
    user?.userToBusiness?.business !== undefined,
  )

  const submitApplication = usePostCreateApplication(
    (data: CreditApplication) => {
      const url = `${location.protocol}//${location.host}/trade-credit/${data.id}?business_id=${data.seller}`
      setApplicationUrl(url)
      navigator.clipboard.writeText(url)
      enqueueSnackbar(`Invitation email sent to ${email}`, {
        variant: "info",
      })
      enqueueSnackbar("Link copied to clipboard.", {
        variant: "info",
      })
      refetch()
    },
  )

  const submitCashApplication = usePostCreateCashApplication(
    (data: CreditApplication) => {
      const url = `${location.protocol}//${location.host}/cod/${data.id}?business_id=${data.seller}`
      setApplicationUrl(url)
      navigator.clipboard.writeText(url)
      enqueueSnackbar(`Invitation email sent to ${email}`, {
        variant: "info",
      })
      enqueueSnackbar("Link copied to clipboard.", {
        variant: "info",
      })
      refetch()
    },
  )

  const reset = useCallback(() => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPersonalGuarantor(PERSONAL_GUARANTY.OFF)
    setApplicationUrl(undefined)
    setSalesRep(undefined)
  }, [])

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()

      const formData = new FormData()
      formData.append("firstName", firstName)
      formData.append("lastName", lastName)
      formData.append("email", email)
      formData.append("collaborators", email.toString())
      formData.append("personalGuarantor", personalGuarantor)
      if (salesRep) {
        formData.append("salesRep", salesRep || "")
      } else if (isSales) {
        formData.append("salesRep", user?.id || "")
      }
      if (type === "credit") {
        submitApplication.execute(formData, () => {
          refetch()
          toggle(false)
          reset()
        })
      } else {
        submitCashApplication.execute(formData, () => {
          refetch()
          toggle(false)
          reset()
        })
      }
    },
    [
      firstName,
      lastName,
      email,
      personalGuarantor,
      salesRep,
      isSales,
      type,
      user?.id,
      submitApplication,
      refetch,
      toggle,
      reset,
      submitCashApplication,
    ],
  )
  const canSubmit = useCallback(() => {
    return isEmail(email) && firstName.length > 0 && lastName.length > 0
  }, [firstName, lastName, email])

  useEffect(() => {
    if (
      template &&
      template?.formTemplate?.pages.length >= 6 &&
      template?.formTemplate?.pages[5].label === "Personal Guaranty"
    ) {
      const personalGuaranty = getPersonalGuarantyFromSchema(
        template.formTemplate.pages[5],
      )
      if (personalGuaranty != "") {
        setPersonalGuarantor(personalGuaranty)
      }
    }
  }, [open, template])

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          toggle(false)
        }
      }}
      maxWidth="sm"
    >
      <DialogTitle>
        New {type === "credit" ? "Credit" : "Cash"} Application
      </DialogTitle>
      {!applicationUrl && (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="body1">
              Enter the details of your buyer to invite them to fill out a{" "}
              {type} application. Once created they will receive an email with a
              link to your {type} application form.
            </Typography>

            <Stack style={{ padding: "10px" }} spacing={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Stack>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Recepient Email"
                value={email}
                onChange={(e) =>
                  setEmail(
                    e.target.value ? e.target.value.toLowerCase().trim() : "",
                  )
                }
              />
              {isCreditManager && (
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                >
                  <InputLabel 
                  id="sales-rep-label">Sales Rep</InputLabel>
                  <Select
                    labelId="sales-rep-label"
                    id="sales-rep-select"
                    label="Sales Rep"
                    value={salesRep}
                    onChange={(event) => {
                      setSalesRep(
                        allUsers?.find((user) => user.id === event.target.value)
                          ?.id,
                      )
                    }}
                    style={{ height: "56px" }}
                  >
                    <MenuItem disabled value={undefined}>
                      Select One
                    </MenuItem>
                    <MenuItem value={-1} key="sales_rep_0">
                      No Sales Representative
                    </MenuItem>
                    {allUsers
                      ?.filter((user) => user.groups?.includes(Group.Sales))
                      .map((salesRep) => (
                        <MenuItem value={salesRep.id} key={salesRep.id}>
                          {salesRep.firstName} {salesRep.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {type === "credit" && (
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                >
                  <InputLabel
                    id="personal-guarantee-check-label"
                    style={{
                      zIndex: 1000,
                      backgroundColor: themePalette().background.paper,
                      paddingRight: "0.2rem",
                    }}
                  >
                    Personal Guarantor Check
                  </InputLabel>
                  <Select
                    labelId="personal-guarantee-label"
                    id="personal-guarantee"
                    label="Personal Guarantor"
                    value={personalGuarantor}
                    onChange={(event) => {
                      setPersonalGuarantor(event.target.value)
                    }}
                  >
                    <MenuItem value={PERSONAL_GUARANTY.OFF}>
                      No Personal Guarantor
                    </MenuItem>
                    <MenuItem value={PERSONAL_GUARANTY.REQUIRED}>
                      Personal Guarantor Required
                    </MenuItem>
                    <MenuItem value={PERSONAL_GUARANTY.OPTIONAL}>
                      Personal Guarantor Optional
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                toggle(false)
                reset()
              }}
            >
              Close
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!canSubmit()}
              onClick={handleSubmit}
              loading={
                submitApplication.isLoading || submitCashApplication.isLoading
              }
            >
              Invite
            </LoadingButton>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

export default NewApplicationForm
