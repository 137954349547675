import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, TextField, Divider } from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { ApplicationTemplate, CreditApplication } from "src/types"
import * as yup from "yup"
import { PAGE_LABEL_PERSONAL_GUARANTY } from "./constants"
import { isPageRequired } from "../utils"

const PAGE_LABEL = PAGE_LABEL_PERSONAL_GUARANTY

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  template: ApplicationTemplate
}

const PERSONAL_GUARANTY_YUP_SCHEMA = yup.object({
  guarantorName: yup.string().required("Name is required"),
  guarantorEmail: yup
    .string()
    .email("Email is required")
    .required("Email is required"),
  guarantorPhoneNumber: yup.string().required("Phone number is required"),
})

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  if (
    template?.formTemplate?.pages
      .filter(
        (page) =>
          Boolean(page.enabled) &&
          Boolean(page.required) &&
          page.required !== "Homeowner",
      )
      .find((tstep) => tstep.label === PAGE_LABEL)
  ) {
    return PERSONAL_GUARANTY_YUP_SCHEMA
  } else if (
    template?.formTemplate?.pages
      .filter(
        (page) =>
          Boolean(page.enabled) &&
          Boolean(page.required) &&
          page.required === "Homeowner",
      )
      .find((tstep) => tstep.label === PAGE_LABEL)
  ) {
    return yup.object({
      guarantorName: yup.string().when("applicantType", {
        is: (fieldTwo: string) => fieldTwo === "Individual/Consumer/Homeowner",
        then: yup.string().required(),
      }),
      guarantorEmail: yup
        .string()
        .email("Email is required")
        .when("applicantType", {
          is: (fieldTwo: string) =>
            fieldTwo === "Individual/Consumer/Homeowner",
          then: yup.string().required(),
        }),
      guarantorPhoneNumber: yup.string().when("applicantType", {
        is: (fieldTwo: string) => fieldTwo === "Individual/Consumer/Homeowner",
        then: yup.string().required(),
      }),
    })
  }
  return yup.object({})
}

const getNumberOfGuanators = (template: ApplicationTemplate): number => {
  return (
    Number(
      template?.formTemplate?.pages
        .find((tstep) => tstep.label === PAGE_LABEL)
        ?.config.find((config) => config.label.includes("Number of Guarantors"))
        ?.value,
    ) || 1
  )
}

export default ({ data, errors, onChange, onContinue, template }: Props) => {
  const numberOfGuarantors = getNumberOfGuanators(template)

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Personal Guarantor
        {isPageRequired(PAGE_LABEL, template) ? "" : " (Optional)"}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        A personal guarantor (PG) helps us assess your application. Please fill
        out the form below. The PG document will be sent to the email(s) you
        specify in this form to be digitally signed. Therefore, as a minimum, we
        require that the email address of the guarantor(s) is included.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <form>
        {numberOfGuarantors > 1 && (
          <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
            Guarantor 1
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Full Name"
            type="text"
            required={isPageRequired(PAGE_LABEL, template)}
            value={data.guarantorName}
            onChange={(event) => {
              onChange("guarantorName", event.target.value)
            }}
            error={Boolean(errors?.guarantorName)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            type="email"
            required={isPageRequired(PAGE_LABEL, template)}
            value={data.guarantorEmail}
            onChange={(event) => {
              onChange("guarantorEmail", event.target.value)
            }}
            error={Boolean(errors?.guarantorEmail)}
          />
          <ReactPhoneInput
            value={data.guarantorPhoneNumber}
            country={"ca"}
            onChange={(event) => {
              onChange("guarantorPhoneNumber", "+" + event)
            }}
            component={TextField}
            containerStyle={{ width: "100%", marginTop: "8px" }}
            inputProps={{
              id: "guarantorPhoneNumber",
              required: isPageRequired(PAGE_LABEL, template),
              error: Boolean(errors?.guarantorPhoneNumber),
            }}
          />
        </Box>

        {numberOfGuarantors > 1 &&
          new Array(numberOfGuarantors - 1).fill(0).map((_, index) => (
            <>
              <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
                Guarantor {index + 2}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  label="Full Name"
                  type="text"
                  value={data["guarantorName" + (index + 2)]}
                  onChange={(event) => {
                    onChange("guarantorName" + (index + 2), event.target.value)
                  }}
                  error={Boolean(errors?.["guarantorName" + (index + 2)])}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email"
                  type="email"
                  value={data["guarantorEmail" + (index + 2)]}
                  onChange={(event) => {
                    onChange("guarantorEmail" + (index + 2), event.target.value)
                  }}
                  error={Boolean(errors?.["guarantorEmail" + (index + 2)])}
                />
                <ReactPhoneInput
                  value={data["guarantorPhoneNumber" + (index + 2)]}
                  country={"ca"}
                  onChange={(event) => {
                    onChange("guarantorPhoneNumber" + (index + 2), "+" + event)
                  }}
                  component={TextField}
                  containerStyle={{ width: "100%", marginTop: "8px" }}
                  inputProps={{
                    id: "guarantorPhoneNumber" + (index + 2),
                    error: Boolean(
                      errors?.["guarantorPhoneNumber" + (index + 2)],
                    ),
                  }}
                />
              </Box>
            </>
          ))}

        <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
          <LoadingButton
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save & Continue
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
