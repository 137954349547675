import { Form } from "formik"

import QontoStepper from "src/sections/@dashboard/components/QontoStepper"

import { Box, Divider, Typography, useTheme } from "@mui/material"

import TradeReferencesSection, {
  getEnabledSchema as getTradeEnabledSchema,
} from "src/sections/@dashboard/intake_sections/TradeReferencesSection"
import BankReferenceSection, {
  getEnabledSchema as getBankEnabledSchema,
} from "src/sections/@dashboard/intake_sections/BankReferenceSection"

import UserRegistrationSection, {
  getEnabledSchema as getUserEnabledSchema,
} from "src/sections/@dashboard/intake_sections/UserRegistrationSection"
import OwnersSection, {
  getEnabledSchema,
} from "src/sections/@dashboard/intake_sections/OwnersSection"
import CompanyDetailsSection, {
  getEnabledSchema as getCompanyDetailsEnabledSchema,
} from "src/sections/@dashboard/intake_sections/CompanyDetailsSection"

import CustomQuestionsSection from "src/sections/@dashboard/intake_sections/CustomQuestionsSection"
import PersonalGuarantySection, {
  getEnabledSchema as getPersonalGuarantyEnabledSchema,
} from "src/sections/@dashboard/intake_sections/PersonalGuarantySection"

import { useParams } from "react-router-dom"
import Complete from "../../intake_sections/Complete"
import { FormComponent } from "src/types/credit/FormComponent"

export default ({
  steps,
  activeStep,
  handleBack,
  props,
  onContinue,
  onDataFieldUpdated,
  onCustomFieldUpdated,
  onApplicationFieldUpdated,
  application,
  template,
  completionError,
}: FormComponent) => {
  const theme = useTheme()
  const params = useParams()
  const { id } = params

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "5%",
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        style={{ margin: "0 5% 32px 5%" }}
        display={{
          xs: "none",
          sm: "none",
          md: "block",
          large: "block",
          xlarge: "block",
        }}
      >
        <QontoStepper
          steps={steps}
          // data={formik.values.data}
          activeStep={activeStep}
          handleBack={handleBack}
        />
      </Box>
      <Box
        style={{
          textAlign: "left",
          padding: "32px 10% 32px 10%",
          border: "solid",
          borderWidth: "1px",
          borderRadius: "16px",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Form>
          {completionError && (
            <>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginTop: "8px" }}
              >
                Error!
              </Typography>
              <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                There was an error while completing your application. Please
                refresh this page. If the error persists, please contact us at
                info@netnow.io with the following information.
              </Typography>
              <Divider style={{ margin: "16px 0 16px 0" }} />
              <Typography color="error">{completionError.message}</Typography>
              <Typography color="error">Application ID: {id}</Typography>
              <Typography color="error">
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  completionError.response?.data?.error
                }
              </Typography>
            </>
          )}
          {!completionError && activeStep == 99 && <Complete />}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("user") && (
              <UserRegistrationSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={(onFormValidationError) => {
                  onContinue(getUserEnabledSchema(template), () => {
                    onFormValidationError()
                    return undefined
                  })
                }}
                template={template}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("business") && (
              <CompanyDetailsSection
                application={application}
                topLevelValues={props.values}
                data={props.values.data}
                errors={{
                  ...props.errors.data,
                  salesRep: props.errors.salesRep,
                }}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onApplicationFieldUpdated={onApplicationFieldUpdated}
                onContinue={() => {
                  onContinue(getCompanyDetailsEnabledSchema(template))
                }}
                template={template}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("owners") && (
              <OwnersSection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(getEnabledSchema(template))
                }}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("trade") && (
              <TradeReferencesSection
                size={3}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onContinue={() => {
                  onContinue(getTradeEnabledSchema(template))
                }}
                template={template}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("bank") && (
              <BankReferenceSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(getBankEnabledSchema(template))
                }}
                template={template}
              />
            )}

          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("guaranty") && (
              <PersonalGuarantySection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onContinue={() => {
                  onContinue(getPersonalGuarantyEnabledSchema(template))
                }}
                template={template}
              />
            )}

          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("additional") && (
              <CustomQuestionsSection
                application={application}
                errors={props.errors.data}
                data={props.values.data}
                onChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={function (): void {
                  onContinue()
                }}
              />
            )}
        </Form>
      </Box>
    </Box>
  )
}
