// @mui

import { Container, Grid } from "@mui/material"

// components
import { useParams } from "src/routes/hook"
//

import { useCashApplication } from "src/queries/cod/useCashApplication"
import CashApplicationBaseInfo from "../CashApplicationBaseInfo"
import CashApplicationDetailsToolbar from "../CashApplicationDetailsToolbar"

// ----------------------------------------------------------------------

export default function CreditApplicationDetailsView() {
  const params = useParams()

  const { id } = params

  const { data } = useCashApplication(id)

  if (!data || !data.id) {
    return <></>
  }

  return (
    <Container maxWidth={false}>
      <CashApplicationDetailsToolbar
        backLink={"/cash-applications"}
        application={data}
      />

      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <CashApplicationBaseInfo applicationId={data.id} />
        </Grid>
      </Grid>
    </Container>
  )
}
