import { QueryClient } from "react-query"
import { TemplateCustomField } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function usePatchTemplateCustomFields(
  onSuccess?: (data: TemplateCustomField) => void,
) {
  const ENDPOINT = "/configure/custom_fields"
  const patchTemplate = useTypedMutation<Partial<TemplateCustomField>>(
    "patch/configure/custom_fields",
    onSuccess,
  )

  const execute = (
    body: Partial<TemplateCustomField>,
    onSuccess?: () => void,
  ) => {
    patchTemplate.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + `/${body.id}/`,
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  const executeAsync = (
    body: Partial<TemplateCustomField>,
    onSuccess?: () => void,
  ) => {
    return patchTemplate.mutateAsync(
      {
        method: "patch",
        endpoint: ENDPOINT + `/${body.id}/`,
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patchTemplate, execute, executeAsync }
}
