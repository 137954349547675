import { LoadingButton } from "@mui/lab"
import { CreditApplication } from "../../../types"
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import { useDocumentDetails } from "src/queries/vendors/useDocumentDetails"
import Label from "src/components/label"
import { confirm } from "src/components/confirm"
import { useRequestPersonalGuaranty } from "src/queries/credit/useRequestPersonalGuaranty"
import { enqueueSnackbar } from "notistack"
import { info, error } from "src/utils/logger"
import { TableHeadCustom } from "src/components/table"
import * as Sentry from "@sentry/react"
import { startCase } from "lodash"

export default function ({ application }: { application: CreditApplication }) {
  const { refetch: fetchPdf, isLoading } = useDocumentPDF(
    application.personalGuaranty
      ? application.personalGuaranty[0]?.documentRequestId
      : "",
    "guaranty",
  )

  const { data: documentDetails, isLoading: isFetchingDetails } =
    useDocumentDetails(
      application.personalGuaranty
        ? application.personalGuaranty[0]?.documentRequestId
        : "",
      "guaranty",
    )

  const { execute: requestSecureDocument, isLoading: isRequesting } =
    useRequestPersonalGuaranty(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
    })

  const getLabel = (signed: boolean) => {
    return (
      <Label color={signed ? "success" : "warning"}>
        {signed ? "SIGNED" : "NOT SIGNED"}
      </Label>
    )
  }

  const findAction = (actionId: string) => {
    if (!documentDetails?.requests?.actions) return undefined

    if (documentDetails?.requests?.actions.length === 0) {
      Sentry.captureMessage(
        "No actions found in document details " + actionId,
        "error",
      )
      return undefined
    }

    if (documentDetails?.requests?.actions.length === 1) {
      return documentDetails?.requests?.actions[0]
    }

    return documentDetails?.requests?.actions?.find(
      (a: any) => a.actionId === actionId,
    )
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}
            >
              Personal Guarantor
              {isFetchingDetails && <CircularProgress size={24} />}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  confirm("Send reminder to pending signers?")
                    .then(
                      () => {
                        return requestSecureDocument(application.id || "")
                      },
                      () => {
                        info("cancelled")
                      },
                    )
                    .catch(() => {
                      error("cancelled")
                    })
                }}
                loading={isRequesting}
              >
                Send Reminder
              </LoadingButton>
              <LoadingButton
                loading={isLoading}
                disabled={
                  !application.personalGuaranty ||
                  application.personalGuaranty.length === 0 ||
                  !application.personalGuaranty[0]?.documentRequestId
                }
                variant="contained"
                onClick={() => {
                  fetchPdf()
                    .then((res) => {
                      function saveByteArray(fileName: string, b64: string) {
                        const link = document.createElement("a")
                        link.href = "data:application/pdf;base64," + b64
                        link.download = fileName
                        link.click()
                      }
                      if (!res.data) throw new Error("No data")
                      return saveByteArray(
                        `${startCase(
                          application.data.legalBusinessName ||
                            `${application.data.firstName} ${application.data.lastName}`,
                        )} Personal Guarantor Agreement.pdf`,
                        res.data,
                      )
                    })
                    .catch((err) => error(err))
                }}
              >
                Download PDF
              </LoadingButton>
            </Box>
          </Box>
        }
      />

      <TableContainer sx={{ overflow: "unset", marginTop: "24px" }}>
        <Table sx={{ minWidth: 680 }}>
          <TableHeadCustom
            headLabel={[
              { id: "name", label: "Name" },
              { id: "email", label: "Email" },
              { id: "phone", label: "Phone Number" },
              { id: "status", label: "Status" },
            ]}
          />

          <TableBody>
            {application.personalGuaranty &&
              application.personalGuaranty.map((row) => (
                <>
                  <TableRow>
                    <TableCell>{row.name}</TableCell>

                    <TableCell>{row.email}</TableCell>

                    <TableCell>{row.phoneNumber}</TableCell>

                    <TableCell>
                      {!isFetchingDetails &&
                        getLabel(
                          findAction(row.actionId)?.actionStatus === "SIGNED",
                        )}
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}
