import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { AutoCompleteOption } from "../../types/components/autocomplete.types"
import { InputProps } from "@mui/material"

interface Props<T> {
  options: Array<T>
  style: React.CSSProperties
  onValueChanged: (value: string) => void
  loading: boolean
  label: string
  required?: boolean
  value: string
  error: boolean
  helperText: string | undefined
  inputProps?: Partial<InputProps>
}

export default function AutoComplete<T extends AutoCompleteOption>({
  options,
  style,
  onValueChanged,
  loading,
  label,
  required,
  value,
  error,
  helperText,
  inputProps,
}: Props<T>) {
  return (
    <Autocomplete
      style={style}
      loading={loading}
      freeSolo
      id="business-name-search"
      disableClearable
      options={options.map((option) => option.title)}
      defaultValue={value}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          variant="outlined"
          label={label}
          error={error}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
            type: "search",
          }}
          required={required}
          onChange={(event) => onValueChanged(event.target.value)}
        />
      )}
    />
  )
}
