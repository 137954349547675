import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { BankReference } from "../../types"

export function usePatchBankReference(onSuccess?: (data: string) => void) {
  const ENDPOINT = "/application/base/bank_reference/"
  const patch = useTypedMutation<BankReference>(
    "post/application/base/bank_reference",
    onSuccess,
  )

  const execute = (
    id: string,
    body: Partial<BankReference>,
    onSuccess?: () => void,
  ) => {
    patch.mutate(
      {
        endpoint: ENDPOINT + body.id + "/?application_id=" + id,
        body,
        method: "patch",
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
