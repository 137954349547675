import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material"
import { Outlet, useParams } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useAnonymousBusiness } from "../../queries/credit/useAnonymousBusiness"
import { loadLogoIntoImg } from "../../utils/imageTools"
import { useEffect } from "react"
import { DynamicThemeProvider } from "../../theme"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
// ----------------------------------------------------------------------

export default function NoAuthReferenceSimpleLayout() {
  const params = useParams()

  const { id } = params

  const { data } = useTradeReferences(undefined, id)

  const { data: business } = useAnonymousBusiness(
    data && data.length > 0 ? data[0].sellerId || "" : "",
  )

  useEffect(() => {
    if (business)
      loadLogoIntoImg(document.getElementById("company-logo"), business?.logo)
  }, [business])
  if (!data || data.length === 0)
    return (
      <Box
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <CircularProgress color="info" />
      </Box>
    )

  return (
    <DynamicThemeProvider>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          md={4}
          lg={4}
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
          sx={{ height: "auto" }}
        >
          <Box
            display={{ xs: "none", lg: "block", xl: "block" }}
            style={{
              backgroundColor: "#fff",
            }}
            sx={{
              padding: "10%",
            }}
          >
            <img id="company-logo" style={{ padding: "10% 30% 20% 30%" }} />
            <Typography variant="h3">
              Thank you for providing a digital trade reference
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              bottom: "5%",
              position: "absolute",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>powered by</Typography>
            <img src="/assets/logo-simple.png" style={{ width: "32px" }} />
            <Typography sx={{ fontWeight: "bold" }}>NetNow</Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          component={Paper}
          elevation={6}
          square
        >
          <Outlet />
        </Grid>
      </Grid>
    </DynamicThemeProvider>
  )
}
