import { useCallback, useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Checkbox,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import LoadingButton from "@mui/lab/LoadingButton"
import { CreditApplication } from "../../../types"
import { usePostCreateApplication } from "../../../queries"
import { PERSONAL_GUARANTY } from "src/statics"
import { useUser } from "src/queries/base/useUser"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { getPersonalGuarantyFromSchema, isEmail } from "src/utils/utils"
import { themePalette } from "src/theme/palette"


type props = {
  open: boolean
  toggle: (open: boolean) => void
  refetch: () => void
}

export default ({ open, toggle, refetch }: props) => {
  const [personalGuarantor, setPersonalGuarantor] = useState<string>(
    PERSONAL_GUARANTY.OFF,
  )
  const [requestTradeRefs, setRequestTradeRefs] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const { data: userData } = useUser()
  const {
    data: template,
    dataSchema,
    steps,
    applicationError,
  } = useApplicationTemplate(
    userData?.userToBusiness?.business?.id,
    false,
    userData?.userToBusiness?.business !== undefined,
  )

  const { execute, isLoading } = usePostCreateApplication(
    (data: CreditApplication) => {
      window.open(
        `${location.protocol}//${location.host}/manual-application/${data.id}}`,
      )
      refetch()
      toggle(false)
    },
  )

  const canSubmit = useCallback(() => {
    return isEmail(email) && firstName.length > 0 && lastName.length > 0
  }, [firstName, lastName, email])

  useEffect(() => {
    if (
      template &&
      template?.formTemplate?.pages.length >= 6 &&
      template?.formTemplate?.pages[5].label === "Personal Guaranty"
    ) {
      const personalGuaranty = getPersonalGuarantyFromSchema(
        template.formTemplate.pages[5],
      )
      if (personalGuaranty != "") {
        setPersonalGuarantor(personalGuaranty)
      }
    }
  }, [open, template])

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          toggle(false)
        }
      }}
      maxWidth="sm"
    >
      <DialogTitle>Start Manual Appplication Entry</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          This feature allows you to add an existing paper application into
          NetNow yourself. Please note that this feature does not automatically
          send trade reference requests, ask for personal guaranty signatures or
          notify the buyer. Select any additional requirements from below and
          click Start Application.
        </Typography>

        <Stack style={{ padding: "10px" }} spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Stack>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Recepient Email"
            value={email}
            onChange={(e) =>
              setEmail(e.target.value?.toLowerCase().trim() || "")
            }
          />
          <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
            <InputLabel
              style={{
                zIndex: 1000,
                backgroundColor: themePalette().background.paper,
                paddingRight: "0.2rem",
              }}
              id="application-personal-guarantee-check-select-label"
            >
              Does this application include a personal guarantor?
            </InputLabel>
            <Select
              labelId="personal-guarantor-label"
              id="personal-guarantor-id"
              label="Personal Guarantor"
              value={personalGuarantor}
              onChange={(event) => {
                setPersonalGuarantor(event.target.value)
              }}
            >
              <MenuItem value={PERSONAL_GUARANTY.OFF}>
                No Personal Guarantor
              </MenuItem>
              <MenuItem value={PERSONAL_GUARANTY.REQUIRED}>
                Personal Guarantor Required
              </MenuItem>
              <MenuItem value={PERSONAL_GUARANTY.OPTIONAL}>
                Personal Guarantor Optional
              </MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={requestTradeRefs}
                onChange={(e) => setRequestTradeRefs(e.target.checked)}
                aria-label="Select this option if you would like to request automated trade references for this application. Upon completion, we will send an email to the reference and ask for data. Leave this unchecked if you are only importing an existing application."
              />
            }
            label="Select this option if you would like to request automated trade references for this application. Upon completion, we will send an email to the reference and ask for data. Leave this unchecked if you are only importing an existing application."
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setPersonalGuarantor(PERSONAL_GUARANTY.OFF)
            setFirstName("")
            setLastName("")
            setEmail("")
            setRequestTradeRefs(false)
            toggle(false)
          }}
        >
          Close
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!canSubmit()}
          onClick={() => {
            const formData = new FormData()
            formData.append("firstName", firstName)
            formData.append("lastName", lastName)
            formData.append("email", email)
            formData.append(
              "requestTradeRefs",
              String(Number(requestTradeRefs)),
            )
            formData.append("personalGuarantor", personalGuarantor)
            formData.append("invite", "0")
            execute(formData)
          }}
          loading={isLoading}
        >
          Start Application
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
