import { useQuery } from "react-query"
import { User } from "src/types"

export function useUsers() {
  const djangoResponse = useQuery<User[]>("/org_users/", {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })

  return { ...djangoResponse }
}
