import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"

import { ApplicationTemplate, Application } from "src/types"
import { useState, useEffect } from "react"
import PDFViewerDialog from "src/components/pdf/PDFViewerDialog"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import * as yup from "yup"
import { useLocation, useParams } from "react-router-dom"
import {
  APPLICANT_TYPES,
  APPLICANT_TYPES_OPTIONS,
  BUSINESS_TYPE_OPTIONS,
} from "src/statics"
import { PDFDocument } from "pdf-lib"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { USER_CONFIG_BUSINESS_TYPE_LIST } from "./constants"

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: (onFormValidationError: () => void) => void
  template: ApplicationTemplate
}

const AUTHENTICATED_USER_DETAILS_YUP_SCHEMA = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Email is required").required("Email is required"),
  userPhoneNumber: yup
    .string()
    .required()
    .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Phone number is not valid"),
})

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  let enabledSchema = AUTHENTICATED_USER_DETAILS_YUP_SCHEMA
  template.formTemplate.pages
    .find((step) => step.label === "User Details")
    ?.config?.filter((config) => !!config.value)
    .forEach(
      (config) =>
        (enabledSchema = enabledSchema.concat(
          getAdditionalSchema(config.label),
        )),
    )
  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === "User Details")
    .filter((field) => field.required && !!field.id)
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )
  return enabledSchema
}

const getAdditionalSchema = (label: string) => {
  switch (label) {
    case "Applicant Type":
      return yup.object({
        applicantType: yup.string().required("Applicant type is required"),
        businessType: yup.string().when("applicantType", {
          is: (fieldTwo: string) =>
            [
              APPLICANT_TYPES.LLC,
              APPLICANT_TYPES.CORPORATION,
              APPLICANT_TYPES.PARTNERSHIP,
              APPLICANT_TYPES.NON_PROFIT,
            ].includes(fieldTwo),
          then: yup.string().required(),
        }),
      })
    default:
      return yup.object({})
  }
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  template,
}: Props) => {
  const [openTerms, setOpenTerms] = useState<boolean | string>(false)

  const [loading, setLoading] = useState(false)

  const [showApplicantType, setShowApplicantType] = useState(true)

  const params = useParams()
  const { id } = params

  const { search } = useLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""
  const [termsAccepted, setTermsAccepted] = useState(false)

  const { data: business } = useAnonymousBusiness(businessId || "")

  const getBusinessTypes = (template: ApplicationTemplate) => {
    const configTypes = template.formTemplate.pages
      .find((step) => step.label === "User Details")
      ?.config?.find(
        (config) => config.label === USER_CONFIG_BUSINESS_TYPE_LIST,
      )?.value as string[]
    return configTypes && configTypes.length > 0
      ? configTypes
      : BUSINESS_TYPE_OPTIONS
  }

  useEffect(() => {
    if (template) {
      if (
        template.formTemplate.pages
          .find((step) => step.label === "User Details")
          ?.config?.find((config) => config.label === "Applicant Type")
          ?.value === false
      ) {
        onChange("applicantType", "Corporation")
        onChange("businessType", "Other")
        setShowApplicantType(false)
      }
    }
  }, [template, onChange])

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Your Details
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Information of the person filling out this form. In case we have any
        questions, this will be the main point of contact.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <form>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            id="first-name"
            fullWidth
            label="First Name"
            type="text"
            required
            value={data.firstName}
            onChange={(event) => {
              onChange("firstName", event.target.value)
            }}
            error={Boolean(errors?.firstName)}
          />
          <TextField
            id="last-name"
            margin="normal"
            required
            fullWidth
            label="Last Name"
            type="text"
            value={data.lastName}
            onChange={(event) => {
              onChange("lastName", event.target.value)
            }}
            error={Boolean(errors?.lastName)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            disabled={!!id}
            fullWidth
            required
            label="Contact Email"
            type="email"
            id="email"
            value={data.email}
            onChange={(event) => {
              onChange("email", event.target.value?.toLowerCase() || "")
            }}
            error={Boolean(errors?.email)}
          />
          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "32px",
            }}
          >
            <ReactPhoneInput
              value={data.userPhoneNumber}
              country={"us"}
              onChange={(event) => {
                onChange("userPhoneNumber", "+" + event)
              }}
              countryCodeEditable={false}
              label="Phone Number"
              component={TextField}
              containerStyle={{
                width: "100%",
                marginTop: "8px",
                height: "56px",
              }}
              inputProps={{
                id: "phone-number",
                required: true,
                error: Boolean(errors?.userPhoneNumber),
              }}
            />
            <TextField
              id="phone-number-ext"
              fullWidth
              margin="normal"
              label="Extension"
              type="text"
              value={data.userPhoneNumberExt}
              onChange={(event) => {
                onChange("userPhoneNumberExt", event.target.value)
              }}
            />
          </Box>
        </Box>
        {showApplicantType && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              margin="normal"
              style={{ flex: "1 1 0px" }}
              required
            >
              <InputLabel id="applicant-type-label">Applicant Type</InputLabel>
              <Select
                labelId="applicant-type-label"
                id="applicant-type-select"
                label="Applicant Type"
                value={data.applicantType}
                onChange={(event) => {
                  onChange("applicantType", event.target.value)
                }}
                style={{ height: "56px" }}
                error={Boolean(errors?.applicantType)}
              >
                <MenuItem disabled value={undefined}>
                  Select one
                </MenuItem>
                {APPLICANT_TYPES_OPTIONS.map((type) => (
                  <MenuItem value={type} key={"applicant" + type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {[
              APPLICANT_TYPES.LLC,
              APPLICANT_TYPES.CORPORATION,
              APPLICANT_TYPES.PARTNERSHIP,
              APPLICANT_TYPES.NON_PROFIT,
            ].includes(data.applicantType) && (
              <FormControl
                fullWidth
                margin="normal"
                style={{ flex: "1 1 0px" }}
                required
              >
                <InputLabel id="business-type-label">Business Type</InputLabel>
                <Select
                  labelId="business-type-label"
                  id="business-type-select"
                  label="Business Type"
                  value={data.businessType}
                  onChange={(event) => {
                    onChange("businessType", event.target.value)
                  }}
                  style={{ height: "56px" }}
                  error={Boolean(errors?.businessType)}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>

                  {getBusinessTypes(template).map((type) => (
                    <MenuItem value={type} key={"business" + type}>
                      {type}
                    </MenuItem>
                  ))}

                  <MenuItem value={"Other"} key={"businessOther"}>
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        )}

        {[
          APPLICANT_TYPES.CORPORATION,
          APPLICANT_TYPES.LLC,
          APPLICANT_TYPES.PARTNERSHIP,
          APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
          APPLICANT_TYPES.NON_PROFIT,
          APPLICANT_TYPES.OTHER,
        ].includes(data.applicantType) &&
          showApplicantType && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <Typography style={{ width: "100%" }}>
                What is your role in the business?
              </Typography>

              <FormControl fullWidth margin="normal">
                <InputLabel id="role">Role</InputLabel>

                <Select
                  labelId="role-label"
                  id="role-select"
                  label="Role"
                  value={data.role}
                  onChange={(event) => {
                    onChange("role", event.target.value)
                  }}
                  style={{ height: "56px" }}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>
                  {[
                    "Owner/Executive",
                    "Sales",
                    "Finance",
                    "Procurement",
                    "Administation",
                    "Other",
                  ].map((role) => (
                    <MenuItem value={role} key={"role" + role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        {template?.customFields && (
          <ReusableCustomQuestions
            application={application}
            associatedPage="User Details"
            data={data["customFields"]}
            errors={errors}
            onChange={onCustomChange}
          />
        )}

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justfityContent: "left",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="terms-checkbox"
                value="remember"
                color="primary"
                onChange={(event) => {
                  setTermsAccepted(event.target.checked)
                }}
                checked={termsAccepted}
              />
            }
            label={
              <div>
                I accept the NetNow
                <Button
                  onClick={async () => {
                    const mergedPdf = await PDFDocument.create()
                    let documents = [
                      "https://storage.googleapis.com/netnow-web-assets-production/Terms%20of%20Service%20-%20NetNow.pdf",
                    ]

                    if (business?.terms) {
                      // order is important
                      documents = [
                        business.terms as string,
                        "https://storage.googleapis.com/netnow-web-assets-production/Terms%20of%20Service%20-%20NetNow.pdf",
                      ]

                      for (const document of documents) {
                        const existingPdfBytes = await fetch(document).then(
                          (res) => res.arrayBuffer(),
                        )

                        const pdfDoc = await PDFDocument.load(existingPdfBytes)

                        const copiedPages = await mergedPdf.copyPages(
                          pdfDoc,
                          pdfDoc.getPageIndices(),
                        )
                        copiedPages.forEach((page) => mergedPdf.addPage(page))
                      }

                      const pdfBytes = await mergedPdf.save()

                      const docUrl = URL.createObjectURL(
                        new Blob([pdfBytes], { type: "application/pdf" }),
                      )
                      setOpenTerms(docUrl)
                    } else {
                      setOpenTerms(documents[0])
                    }
                  }}
                  style={{ marginBottom: "3px" }}
                >
                  terms and conditions
                </Button>
              </div>
            }
          />
        </Box>

        {openTerms && (
          <PDFViewerDialog
            fileUrl={openTerms as string}
            onClose={() => setOpenTerms(false)}
          />
        )}

        <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
          <LoadingButton
            id="user-continue"
            disabled={!termsAccepted}
            loading={loading}
            onClick={() => {
              setLoading(true)
              onContinue(() => {
                setLoading(false)
              })
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Continue
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
