import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import queryString from "query-string"
import axios, { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { error } from "src/utils/logger"

// TODO: needs to be typed
export function usePaginated(queryKey: string, page: number, options?: object) {
  const { enqueueSnackbar } = useSnackbar()

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: [queryKey, page],
    queryFn: async ({}) => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        }).catch(function (e) {
          if (e.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            error(e.response)
          } else if (e.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            error(e.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            // we will end up here if consent is required
            error("Error getting an auth token", e.message)
            return getAccessTokenWithPopup({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              },
            })
          }
        })
        const response = await axios.request({
          method: "get",
          url: queryString.stringifyUrl({
            url: process.env.REACT_APP_BASE_URL + queryKey,
            query: { page: page + 1, ...options },
          }),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return response.data
      } catch (e) {
        error("error fetching", e)
        if (e instanceof AxiosError) {
          enqueueSnackbar(JSON.stringify(e.response?.data) || e.message, {
            variant: "error",
            preventDuplicate: true,
          })
        } else if (e instanceof Error) {
          enqueueSnackbar(e.message, {
            variant: "error",
            preventDuplicate: true,
          })
        }

        return Promise.reject(e)
      }
    },
  })
}
