import { useQuery } from "react-query"
import queryString from "query-string"
import { PlaidLinkToken } from "../../types"

export function usePlaidLinkToken(anonymous = false, location = "") {
  return useQuery<PlaidLinkToken>(
    queryString.stringifyUrl({
      url: "/plaid_link_token/",
      query: { location: location },
    }),
    {
      meta: { anonymous },
  })
}
