import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { ArrowForward } from "@mui/icons-material"
import ReactPhoneInput from "react-phone-input-material-ui"
import * as yup from "yup"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"
import { useEffect, useState } from "react"
import { PAGE_LABEL_TRADE_REFERENCES } from "./constants"
import { isPageRequired } from "../utils"

const PAGE_LABEL = PAGE_LABEL_TRADE_REFERENCES

const getMinimuRequiredReferences = (minimum: number) => {
  const yupObj = {} as { [key: string]: yup.AnySchema }
  new Array(minimum).fill(0).forEach((_, i) => {
    yupObj["referenceBusinessName" + (i + 1)] = yup.string().required()
    yupObj["referenceEmail" + (i + 1)] = yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address")
      .notOneOf(
        new Array(minimum)
          .fill(0)
          .map((_, j) =>
            i === j ? undefined : yup.ref("referenceEmail" + (j + 1)),
          ),
        "Reference emails must be unique",
      )
    yupObj["referencePhoneNumber" + (i + 1)] = yup
      .string()
      .required()
      .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Phone number is not valid")
  })

  return yup.object(yupObj)
}

const getMinimuRequiredReferencesForHomeowner = (minimum: number) => {
  const yupObj = {} as { [key: string]: yup.AnySchema }
  new Array(minimum).fill(0).forEach((_, i) => {
    yupObj["referenceBusinessName" + (i + 1)] = yup
      .string()
      .when("applicantType", {
        is: (fieldTwo: string) => fieldTwo === "Individual/Consumer/Homeowner",
        then: yup.string(),
        otherwise: yup.string().required(),
      })
    yupObj["referenceEmail" + (i + 1)] = yup.string().when("applicantType", {
      is: (fieldTwo: string) => fieldTwo === "Individual/Consumer/Homeowner",
      then: yup.string().email("Please enter a valid email address"),
      otherwise: yup
        .string()
        .email("Please enter a valid email address")
        .required("Email is required")
        .notOneOf(
          new Array(minimum)
            .fill(0)
            .map((_, j) =>
              i === j ? undefined : yup.ref("referenceEmail" + (j + 1)),
            ),
          "Reference emails must be unique",
        ),
    })
    yupObj["referencePhoneNumber" + (i + 1)] = yup
      .string()
      .when("applicantType", {
        is: (fieldTwo: string) => fieldTwo === "Individual/Consumer/Homeowner",
        then: yup
          .string()
          .matches(
            /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
            "Phone number is not valid",
          ),
        otherwise: yup
          .string()
          .required()
          .matches(
            /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
            "Phone number is not valid",
          ),
      })
  })

  return yup.object(yupObj)
}

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  const config = template?.formTemplate?.pages
    .filter((page) => Boolean(page.enabled) && Boolean(page.required))
    .find((tstep) => tstep.label === PAGE_LABEL)

  if (!config) {
    return yup.object({})
  }

  if (config?.required !== "Homeowner") {
    return getMinimuRequiredReferences(
      Number(
        config?.config.find((c) => c.label === "Minimum Required")?.value,
      ) || 3,
    )
  } else if (config?.required === "Homeowner") {
    return getMinimuRequiredReferencesForHomeowner(
      Number(
        config?.config.find((c) => c.label === "Minimum Required")?.value,
      ) || 3,
    )
  }
  return yup.object({})
}

type Props = {
  size: number
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  template?: ApplicationTemplate
}
export default ({
  size,
  data,
  errors,
  onChange,
  onContinue,
  template,
}: Props) => {
  const [showError, setShowError] = useState(false)
  const [emailToValidate, setEmailToValidate] = useState("")
  const { data: emailValidationResult } = useValidateEmail(
    emailToValidate ? data[emailToValidate] : "",
    "TRADEREFERENCE",
  )

  useEffect(() => {
    if (emailValidationResult?.verdict === "Invalid") {
      setShowError(true)
    }
  }, [emailValidationResult, emailToValidate])

  const requiredMinimum =
    Number(
      template?.formTemplate?.pages
        .find((p) => p.label === "Trade References")
        ?.config.find((c) => c.label === "Minimum Required")?.value,
    ) || 1

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Trade References
        {isPageRequired(PAGE_LABEL, template) ? "" : " (Optional)"}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Your references should be vendors you have a credit account with. More
        information helps us offer you more suitable credit.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />

      {Array.from(Array(size).keys()).map((i) => (
        <Box key={i}>
          <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
            Reference {i + 1}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <TextField
              id={"reference-name" + (i + 1)}
              margin="normal"
              fullWidth
              required={
                isPageRequired(PAGE_LABEL, template) && i < requiredMinimum
              }
              label="Business Name"
              value={data[`referenceBusinessName${i + 1}`]}
              onChange={(event) => {
                onChange("referenceBusinessName" + (i + 1), event.target.value)
              }}
              error={
                !!errors && Boolean(errors[`referenceBusinessName${i + 1}`])
              }
            />
            <TextField
              id={"reference-account" + (i + 1)}
              margin="normal"
              fullWidth
              label="Account # (Optional)"
              value={data[`referenceAccountNumber${i + 1}`]}
              onChange={(event) => {
                onChange("referenceAccountNumber" + (i + 1), event.target.value)
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Credit/AR Email"
              variant="outlined"
              value={data[`referenceEmail${i + 1}`]}
              required={
                isPageRequired(PAGE_LABEL, template) && i < requiredMinimum
              }
              onChange={(event) => {
                setEmailToValidate("")
                setShowError(false)
                onChange("referenceEmail" + (i + 1), event.target.value)
              }}
              onBlur={(event) => {
                setEmailToValidate(`referenceEmail${i + 1}`)
              }}
              error={!!errors && Boolean(errors[`referenceEmail${i + 1}`])}
              helperText={
                !!errors && `referenceEmail${i + 1}` in errors
                  ? errors[`referenceEmail${i + 1}`]
                  : ""
              }
            />
            <ReactPhoneInput
              value={data[`referencePhoneNumber${i + 1}`]}
              country={"us"}
              onChange={(event) => {
                onChange("referencePhoneNumber" + (i + 1), "+" + event)
              }}
              countryCodeEditable={false}
              label="Credit/AR Phone Number"
              component={TextField}
              containerStyle={{ width: "100%", marginTop: "8px" }}
              inputProps={{
                id: "reference-phone-number" + (i + 1),
                style: { marginTop: "8px" },
                required:
                  isPageRequired(PAGE_LABEL, template) && i < requiredMinimum,
                error:
                  !!errors && Boolean(errors[`referencePhoneNumber${i + 1}`]),
              }}
            />
          </Box>
        </Box>
      ))}

      {showError && (
        <Dialog open={showError}>
          <DialogTitle>Did you enter the right email?</DialogTitle>
          <DialogContent>
            <Typography>
              The email <b>{emailValidationResult?.email}</b> seems to not be
              correct. Please double check and make sure the address is valid to
              avoid delays in processing your application.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowError(false)
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Button
        id="continue-trade-references"
        onClick={() => {
          onContinue()
        }}
        size="large"
        fullWidth
        endIcon={<ArrowForward />}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Save & Continue
      </Button>
    </>
  )
}
