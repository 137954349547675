import {
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material"
import { TemplateCustomField } from "../../../types"
import { useState } from "react"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import {
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_USER_DETAILS,
} from "../intake_sections/constants"

type Props = {
  onClose: () => void
  onSave: (field: TemplateCustomField) => void
  value?: TemplateCustomField
}

export default ({ value, onClose, onSave }: Props) => {
  const { data } = useApplicationTemplate()

  const [field, setField] = useState<TemplateCustomField>(
    value || {
      text: "",
      fieldType: "text",
      associatedPage: "Additional Questions",
      template: data?.id || "",
      required: true,
    },
  )

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Edit Additional Question</DialogTitle>
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "1rem",
          }}
        >
          <Grid container>
            <Grid container>
              <Grid item md={12}>
                <Typography>What is the title of your question?</Typography>
                <Typography variant="caption">
                  Example: How many employees do you have?
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  label="Question"
                  fullWidth
                  onChange={(event) => {
                    setField({
                      ...field,
                      text: event.target.value,
                    })
                  }}
                  value={field.text}
                  style={{ margin: "1rem 0 1rem 0" }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={8}>
              <Typography>
                Which section of the application should this question be
                displayed on?
              </Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="section-label">Section</InputLabel>
                <Select
                  labelId="section-label"
                  label="Section"
                  defaultValue={field.associatedPage}
                  value={field.associatedPage}
                  onChange={(event) => {
                    setField({
                      ...field,
                      associatedPage: event.target.value as string,
                    })
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select
                  </MenuItem>
                  {data?.formTemplate.pages
                    .filter((page) =>
                      [
                        PAGE_LABEL_USER_DETAILS,
                        PAGE_LABEL_BUSINESS_DETAILS,
                        PAGE_LABEL_OWNERS,
                        PAGE_LABEL_BANK_REFERENCE,
                      ].includes(page.label),
                    )
                    .map((page) => {
                      return (
                        <MenuItem key={page.label} value={page.label}>
                          {page.label}
                        </MenuItem>
                      )
                    })}
                  <MenuItem
                    key={PAGE_LABEL_ADDITIONAL_QUESTIONS}
                    value={PAGE_LABEL_ADDITIONAL_QUESTIONS}
                  >
                    {PAGE_LABEL_ADDITIONAL_QUESTIONS}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Is a response required?</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="required-label">Required</InputLabel>
                <Select
                  labelId="required-label"
                  label="Required"
                  value={field.required ? 1 : 0}
                  onChange={(event) => {
                    setField({
                      ...field,
                      required: Boolean(event.target.value),
                    })
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>
                What type of a response does this question accept?
              </Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type-label"
                  label="Type"
                  value={field.fieldType}
                  onChange={(event) => {
                    setField({
                      ...field,
                      fieldType: event.target.value as string,
                    })
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>
                  <MenuItem value={"text"}>Text</MenuItem>
                  <MenuItem value={"email"}>Email</MenuItem>
                  <MenuItem value={"phone"}>Phone Number</MenuItem>
                  <MenuItem value={"date"}>Date</MenuItem>
                  <MenuItem value={"file"}>File</MenuItem>
                  <MenuItem value={"dropdown"}>Dropdown</MenuItem>
                  <MenuItem value={"multiselect"}>Multiselect</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {(field.fieldType === "dropdown" ||
            field.fieldType === "multiselect") && (
            <>
              <Grid container>
                <Grid item md={12}>
                  <Stack>
                    <Typography>Options (Comma Separated)</Typography>
                    <Typography variant="caption">
                      Example: Option 1,Option 2,Option 3
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <TextField
                    label="Options (comma separated)"
                    fullWidth
                    onChange={(event) => {
                      setField({
                        ...field,
                        options: event.target.value,
                      })
                    }}
                    value={field.options}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            onSave(field)
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
