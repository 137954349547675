import { QueryClient } from "react-query"
import { CashApplication } from "src/types"
import { useTypedMutation } from "../useTypedMutation"
import { AxiosError } from "axios"

export function usePostCompleteCashApplication(
  onSuccess?: (data: CashApplication) => void,
) {
  const ENDPOINT = "/cod/complete"
  const post = useTypedMutation<Partial<CashApplication>>(
    "post/cod/complete",
    onSuccess,
  )

  const execute = (
    id: string,
    onSuccess?: () => void,
    onError?: (error: AxiosError) => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {
          id,
          stage: 200,
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError: (error) => {
          if (onError) onError(error as AxiosError)
        },
      },
    )
  }
  return { ...post, execute }
}
