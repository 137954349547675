import { useQuery } from "react-query"
import { ApplicationComment } from "src/types"
import queryString from "query-string"

export function useCashApplicationComments(applicationId?: string) {
  return useQuery<Array<ApplicationComment>>(
    queryString.stringifyUrl({
      url: "/cod/base/comments",
      query: { application_id: applicationId },
    }),
    {
      enabled: !!applicationId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
