import React from "react"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { CreditApplication } from "src/types"
import { pdf } from "@react-pdf/renderer"
import PDFBaseApplicationOutput from "src/components/pdf/output-template/PDFBaseApplicationOutput"
import { PDF_TEMPLATE } from "src/components/pdf/output-template/template"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"

/**
 *
 * @param application
 * @returns A promise that resolves to a File containing the PDF of the application
 */
export default function useGenerateApplicationPdf(
  application: CreditApplication,
) {
  const { data: template } = useApplicationTemplate(
    application.seller?.id,
    false,
    true,
    application.id,
  )

  const { data: businessData } = useAnonymousBusiness(
    application.seller?.id || "",
  )

  const execute = React.useMemo(() => {
    return async () => {
      if (application.id && template && businessData) {
        return await pdf(
          <PDFBaseApplicationOutput
            data={application}
            businessData={businessData}
            customQuestionsTemplate={template.customFields}
            pdfTemplate={PDF_TEMPLATE}
          />,
        ).toBlob()
      }
      throw new Error("Missing application data")
    }
  }, [application, businessData, template])

  return { execute }
}
