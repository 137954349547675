import { Box, Typography, Divider, TextField, Button } from "@mui/material"

import { ApplicationTemplate, CreditApplication } from "src/types"
import { ArrowForward } from "@mui/icons-material"
import ReactPhoneInput from "react-phone-input-material-ui"
import * as yup from "yup"
import Address, { PROVINCES, STATES } from "../components/Address"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useLocation, useParams } from "react-router-dom"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { PAGE_LABEL_OWNERS } from "./constants"
import { getAdditionalQuestionSchemaForPage, isPageRequired } from "../utils"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  let enabledSchema = yup.object({})
  if (isPageRequired(PAGE_LABEL, template)) {
    template.formTemplate.pages
      .find((step) => step.label === "Owners and Contacts")
      ?.config?.filter((config) => !!config.value)
      .forEach(
        (config) =>
          (enabledSchema = enabledSchema.concat(
            getAdditionalSchema(config.label),
          )),
      )
  }

  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: getAdditionalQuestionSchemaForPage(
        template,
        PAGE_LABEL_OWNERS,
      ),
    }),
  )
  return enabledSchema
}

const getAdditionalSchema = (label: string) => {
  switch (label) {
    case "Owners":
      return yup.object({
        ownerName1: yup.string().required(),
        ownerEmail1: yup.string().email().required(),
      })
    // case "Driver License":
    //   return yup.object({
    //     ownerDriverLicense1: yup.string().required(),
    //   })
    // case "SSN/SIN":
    //   return yup.object({ ownerSsn1: yup.string().required() })
    case "Address":
      return yup.object({
        ownerAddress1: yup.string().required(),
        ownerCity1: yup.string().required("City is required"),
        ownerCountry1: yup.string().required("Country is required"),
        ownerRegion1: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        ownerPostCode1: yup.string().required("Post code is required"),
      })
    case "AP Contacts":
      return yup.object({
        apEmail: yup.string().email().required(),
        apPhoneNumber: yup
          .string()
          .required()
          .matches(
            /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
            "Phone number is not valid",
          ),
      })
    default:
      return yup.object({})
  }
}

const PAGE_LABEL = PAGE_LABEL_OWNERS

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onCustomChange: (key: string, value: any) => void
}
export default ({
  data,
  errors,
  onChange,
  onContinue,
  onCustomChange,
}: Props) => {
  const params = useParams()
  const { id } = params
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")

  const { data: application } = useCreditApplication(id || "")

  const { data: template } = useApplicationTemplate(
    id ? application?.seller?.id : businessId || "",
    true,
    id ? !application?.seller?.id : !businessId,
    id,
  )

  const isOwnersEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "Owners")?.value
  }

  const isContactsEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "AP Contacts")?.value
  }

  const isAddressEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "Address")?.value
  }

  const isSsnEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "SSN/SIN")?.value
  }

  const isLicenseEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "Driver License")?.value
  }

  const isDobEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "Date of Birth")?.value
  }

  const isPhoneNumberEnabled = () => {
    return template?.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL)
      ?.config.find((config) => config.label === "Owner Phone Number")?.value
  }

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Owners & Contacts
        {isPageRequired(PAGE_LABEL, template) ? "" : " (Optional)"}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        To increase your chances of getting approved, we would like to know the
        people behind the business. Please enter the details of the business
        owners and/or contacts.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />

      {isOwnersEnabled() &&
        Array.from(Array(2).keys()).map((i) => (
          <Box key={i}>
            <Typography
              component="h1"
              variant="h5"
              style={{ marginTop: "8px" }}
            >
              Owner {i + 1}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <TextField
                id={"owner-name" + (i + 1)}
                margin="normal"
                fullWidth
                label="Full Name"
                required={isPageRequired(PAGE_LABEL, template) && i === 0}
                inputProps={
                  isAddressEnabled()
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                value={data[`ownerName${i + 1}`]}
                onChange={(event) => {
                  onChange("ownerName" + (i + 1), event.target.value)
                }}
                error={!!errors && Boolean(errors[`ownerName${i + 1}`])}
              />
              <TextField
                id={"owner-email" + (i + 1)}
                margin="normal"
                fullWidth
                label="Email"
                required={isPageRequired(PAGE_LABEL, template) && i === 0}
                inputProps={
                  isAddressEnabled()
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                value={data[`ownerEmail${i + 1}`]}
                onChange={(event) => {
                  onChange("ownerEmail" + (i + 1), event.target.value)
                }}
                error={!!errors && Boolean(errors[`ownerEmail${i + 1}`])}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isDobEnabled() && (
                <TextField
                  type="date"
                  id={"owner-dob" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label="Date of Birth"
                  inputProps={
                    isAddressEnabled()
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerDob${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerDob" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerDob${i + 1}`])}
                />
              )}
              {isPhoneNumberEnabled() && (
                <ReactPhoneInput
                  value={data[`ownerPhoneNumber${i + 1}`]}
                  country={"us"}
                  onChange={(event) => {
                    onChange(`ownerPhoneNumber${i + 1}`, "+" + event)
                  }}
                  countryCodeEditable={false}
                  label="Phone Number"
                  component={TextField}
                  containerStyle={{
                    width: "100%",
                    marginTop: "8px",
                    height: "56px",
                  }}
                  inputProps={{
                    id: `owner-phone-number${i + 1}`,
                    // required: isPageRequired(PAGE_LABEL, template),
                    error:
                      !!errors && Boolean(errors[`ownerPhoneNumber${i + 1}`]),
                    inputProps: isAddressEnabled()
                      ? {
                          style: { height: "56px" },
                        }
                      : {},
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isLicenseEnabled() && (
                <TextField
                  id={"owner-driver-license" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label="Driver License Number"
                  // required={i === 0}
                  inputProps={
                    isAddressEnabled()
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerDriverLicense${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerDriverLicense" + (i + 1), event.target.value)
                  }}
                  error={
                    !!errors && Boolean(errors[`ownerDriverLicense${i + 1}`])
                  }
                />
              )}

              {isSsnEnabled() && (
                <TextField
                  id={"owner-ssn" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label="SSN / SIN"
                  // required={i === 0}
                  inputProps={
                    isAddressEnabled()
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerSsn${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerSsn" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerSsn${i + 1}`])}
                />
              )}
            </Box>
            {isAddressEnabled() && (
              <Address
                keyPrefix={"owner"}
                keySuffix={String(i + 1)}
                data={data}
                onChange={onChange}
                errors={errors}
                required={i === 0}
              />
            )}
          </Box>
        ))}
      {isContactsEnabled() && (
        <>
          <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
            Billing Contacts
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <TextField
              id={"ap-email"}
              margin="normal"
              fullWidth
              required={isPageRequired(PAGE_LABEL, template)}
              label="Credit/AP Email"
              variant="outlined"
              value={data["apEmail"]}
              inputProps={
                isAddressEnabled()
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
              onChange={(event) => {
                onChange("apEmail", event.target.value)
              }}
              error={Boolean(errors?.apEmail)}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "32px",
            }}
          >
            <ReactPhoneInput
              value={data.apPhoneNumber}
              country={"us"}
              onChange={(event) => {
                onChange("apPhoneNumber", "+" + event)
              }}
              countryCodeEditable={false}
              label="Credit/AP Phone Number"
              component={TextField}
              containerStyle={{
                width: "100%",
                marginTop: "8px",
                height: "56px",
              }}
              inputProps={{
                id: "ap-phone-number",
                required: isPageRequired(PAGE_LABEL, template),
                error: Boolean(errors?.apPhoneNumber),
                inputProps: isAddressEnabled()
                  ? {
                      style: { height: "56px" },
                    }
                  : {},
              }}
            />
            <TextField
              id={"ap-phone-number-ext"}
              fullWidth
              margin="normal"
              label="Extension"
              type="text"
              inputProps={
                isAddressEnabled()
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
              value={data.apPhoneNumberExt}
              onChange={(event) => {
                onChange("apPhoneNumberExt", event.target.value)
              }}
            />
          </Box>
        </>
      )}

      {template?.customFields && (
        <ReusableCustomQuestions
          application={application}
          associatedPage={PAGE_LABEL_OWNERS}
          data={data["customFields"]}
          errors={errors}
          onChange={onCustomChange}
        />
      )}

      <Button
        id="continue-owner"
        onClick={() => {
          onContinue()
        }}
        size="large"
        fullWidth
        endIcon={<ArrowForward />}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Save & Continue
      </Button>
    </>
  )
}
