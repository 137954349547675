import { useEffect } from "react"
import TextField from "@mui/material/TextField"
import { useFormik } from "formik"
import * as yup from "yup"
import { User } from "../../../types"
import { useUser } from "../../../queries"
import { UsePatchUserType } from "../../../queries/base/usePatchUser"

type Props = {
  mutation: UsePatchUserType
  children: JSX.Element
}

export default function AddUserForm({ mutation, children }: Props) {
  const { data, auth0User } = useUser()

  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    lastName: yup.string().required("Last name is required"),
  })

  const formik = useFormik({
    initialValues: {
      id: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: User) => {
      if (data) {
        mutation.execute(data?.username, values, () => undefined)
      }
    },
  })

  // reset form on mount
  useEffect(() => {
    if (data) {
      const defaults = data as User
      // default email to auth0 google login
      if (!defaults.email) {
        defaults.email = auth0User?.email || defaults.email
      }
      if (!defaults.firstName) {
        defaults.firstName = auth0User?.given_name || defaults.firstName
      }
      if (!defaults.lastName) {
        defaults.lastName = auth0User?.family_name || defaults.lastName
      }
      formik.setValues(defaults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, auth0User])

  if (!data) {
    return <>Loading...</>
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        autoFocus
        margin="normal"
        id="firstName"
        label="First Name"
        type="text"
        fullWidth
        // variant="standard"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <TextField
        margin="normal"
        id="lastName"
        label="Last Name"
        type="text"
        fullWidth
        // variant="standard"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />

      {children}
    </form>
  )
}
