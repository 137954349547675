// @mui
import { Typography } from "@mui/material"
import { useState, useEffect } from "react"

// ----------------------------------------------------------------------

import InputBase, { InputBaseProps } from "@mui/material/InputBase"
import { TypographyProps } from "@mui/material/Typography"

interface EditableTypographyProps {
  /**
   * Handler calling when the text changed
   */
  onChange?: (value: string) => void
  editable?: boolean
}

const InputBaseWithChildren = ({
  children,
  ...props
}: InputBaseProps & { children?: React.ReactNode }) => {
  let value = ""
  if (children) {
    if (typeof children == "string" || typeof children == "number") {
      value = children.toString()
    }
  }

  return (
    <InputBase
      {...props}
      multiline
      value={value}
      inputProps={{ className: props.className }}
    />
  )
}

/**
 * Displaying like a `Typography`. But acting as an `input`
 */
export const EditableTypography = ({
  onChange: propsOnChange,
  editable = false,
  ...props
}: TypographyProps & EditableTypographyProps) => {
  const [internalValue, setInternalValue] = useState(props.children || "")

  useEffect(() => {
    setInternalValue(props.children || "")
  }, [editable, setInternalValue, props.children])

  const onChange = (value: string) => {
    if (propsOnChange) {
      propsOnChange(value)
    }
    setInternalValue(value)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <Typography
      {...props}
      component={InputBaseWithChildren}
      onChange={editable ? handleChange : () => false}
      style={
        editable
          ? {
              border: "1px solid",
              borderColor: "#AAAAAA",
              borderRadius: "5px",
              padding: "0 4px 0 4px",
            }
          : {}
      }
    >
      {editable ? internalValue : props.children}
    </Typography>
  )
}
