import {
  Stack,
  Box,
  CardHeader,
  CircularProgress,
  Chip,
  IconButton,
  MenuItem,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Button,
  useTheme,
  Badge,
  Divider,
  Typography,
} from "@mui/material"
import { CreditApplication, TradeReference } from "../../../../types"
import { useTradeReferences } from "../../../../queries/credit/useTradeReferences"
import { useCallback, useState } from "react"
import {
  AccountBalanceOutlined,
  AddBusinessOutlined,
  AddOutlined,
  CalendarMonth,
  EditOutlined,
  EmailOutlined,
  ForwardToInboxOutlined,
  HandshakeOutlined,
  InfoOutlined,
  MoreVertOutlined,
  Pending,
  PendingOutlined,
  TextSnippet,
  TimerOutlined,
} from "@mui/icons-material"
import { CurrencyExchangeOutlined } from "@mui/icons-material"
import EditTradeReferenceDialog from "./EditTradeReferenceDialog"
import { Icon } from "@iconify/react"
import RequestReferenceDialog from "./RequestReferenceDialog"
import AddNewTradeReferenceDialog from "./AddNewTradeReferenceDialog"
import { useEmailStatus } from "src/queries/vendors/useEmailStatus"
import { formatCurrency } from "src/utils/formatNumber"

export default ({ application }: { application: CreditApplication }) => {
  const { data: tradeRefs, refetch } = useTradeReferences(application.id)

  const [referenceToggle, setReferenceToggle] = useState(0)

  const theme = useTheme()

  const [add, setAdd] = useState<TradeReference | undefined>(undefined)
  const [request, setRequest] = useState<TradeReference | undefined>(undefined)
  const [open, setOpen] = useState<TradeReference | null>(null)

  const [createNewReference, setCreateNewReference] = useState(false)

  const reference = tradeRefs?.sort((a, b) => (a.id > b.id ? 1 : -1))[
    referenceToggle
  ]

  const { data: emailStatus } = useEmailStatus(
    tradeRefs?.map((r) => r.email) || [],
    application.id || "",
  )

  const hasBounced = useCallback(
    (email?: string) => {
      if (!email) return false
      return emailStatus
        ?.find((response: any) => response.email === email)
        ?.messages?.find(
          (e: any) => e.events?.find((ev: any) => ev.eventName === "bounce"),
        )
    },
    [emailStatus],
  )

  const handleCloseMenu = () => {
    setOpen(null)
  }

  if (!tradeRefs) return <CircularProgress />

  return (
    <>
      <CardHeader
        title="Trade References"
        subheader={
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
              }}
            >
              <ToggleButtonGroup
                value={referenceToggle}
                exclusive
                onChange={(e, v) => {
                  setReferenceToggle(v || 0)
                }}
                style={{ marginTop: "8px" }}
              >
                {tradeRefs?.map((v, i) => {
                  if (v.isFilled) {
                    return (
                      <Badge
                        key={i}
                        // color="primary"
                        badgeContent={<TextSnippet color="primary" />}
                        style={{ margin: "0 16px 0 0px" }}
                        invisible={!v.isFilled}
                        onClick={() => {
                          setReferenceToggle(i)
                        }}
                      >
                        <ToggleButton
                          value={i}
                          selected={referenceToggle === i}
                        >
                          Reference {i + 1}
                        </ToggleButton>
                      </Badge>
                    )
                  }
                  if (hasBounced(v.email) && i === referenceToggle) {
                    return (
                      <Badge
                        key={i}
                        // color="primary"
                        badgeContent={<InfoOutlined color="error" />}
                        style={{ margin: "0 16px 0 0px" }}
                        onClick={() => {
                          setReferenceToggle(i)
                        }}
                      >
                        <ToggleButton
                          value={i}
                          selected={referenceToggle === i}
                        >
                          Reference {i + 1}
                        </ToggleButton>
                      </Badge>
                    )
                  }
                  return (
                    <ToggleButton
                      value={i}
                      selected={referenceToggle === i}
                      key={i}
                    >
                      Reference {i + 1}
                    </ToggleButton>
                  )
                })}
              </ToggleButtonGroup>
              <Button
                variant="outlined"
                endIcon={<AddOutlined />}
                style={{ marginLeft: "8px" }}
                onClick={() => {
                  setCreateNewReference(true)
                }}
                size="small"
              >
                New
              </Button>
            </Box>
            {reference && (
              <IconButton
                id={"more-" + reference?.id}
                size="large"
                color="inherit"
                onClick={() => setOpen(reference)}
              >
                <MoreVertOutlined />
              </IconButton>
            )}
          </Box>
        }
      />

      <Stack spacing={3.5} sx={{ p: 3, typography: "body2" }}>
        {hasBounced(reference?.email) && (
          <Box
            style={{
              border: "solid",
              borderWidth: "2px",
              borderRadius: "16px",
              padding: "8px",
              borderColor: theme.palette.error.main,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Typography style={{ wordBreak: "break-word" }}>
              Deliverability warning: our systems indicate that emails sent to
              this address have previously been rejected. Please verify the
              email address in case the reference request is not fulfilled in
              time.
            </Typography>
          </Box>
        )}

        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {!reference && (
            // no reference available

            <>
              <Stack alignItems="left" style={{ gap: "8px" }}>
                No References Available
              </Stack>
              {/* <Button
                variant="contained"
                endIcon={<AddOutlined />}
                onClick={() => {
                  setCreateNewReference(true)
                }}
              >
                Add New
              </Button> */}
            </>
          )}
          {reference && (
            <>
              <Stack alignItems="left" style={{ gap: "8px" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {reference.name}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {reference.email}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {reference.phoneNumber}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {reference.accountNumber
                    ? `Account # ${reference.accountNumber}`
                    : "No Account Number"}
                </Box>
              </Stack>
              <Stack alignItems="right" style={{ gap: "8px" }}>
                <Tooltip
                  title="Indicates the payment terms extended to the customer at the time of reporting"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <HandshakeOutlined />
                        Terms:{" "}
                        {reference.terms
                          ? `${
                              reference.terms === "0"
                                ? "Cash"
                                : `Net ${reference.terms}`
                            }`
                          : "---"}
                      </Box>
                    }
                  />
                </Tooltip>
                <Tooltip
                  title="Balance of the customer at the time of reporting"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <AccountBalanceOutlined />
                        Balance:{" "}
                        {reference.balance
                          ? `${formatCurrency(
                              reference.balance,
                              reference.currency,
                            )} ${reference.currency}`
                          : "---"}
                      </Box>
                    }
                  />
                </Tooltip>

                <Tooltip
                  title="Indicates the highest credit limit extended to the customer at the time of reporting"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Icon icon="mdi:hand-coin-outline" width={24} />
                        Max Limit:{" "}
                        {reference.creditLimit
                          ? `${formatCurrency(
                              reference.creditLimit,
                              reference.currency,
                            )}
                            ${reference.currency}
                            `
                          : "---"}
                      </Box>
                    }
                  />
                </Tooltip>
              </Stack>
              <Stack alignItems="right" style={{ gap: "8px" }}>
                <Tooltip
                  title="Indicates how many days past due the customer is at the time of reporting"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <TimerOutlined />
                        Days Past: {reference.paymentStatus || "---"}
                      </Box>
                    }
                  />
                </Tooltip>

                <Tooltip
                  title="Date the customer account was opened with the reference"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <AddBusinessOutlined />
                        Open Date: {reference.accountOpenDate || "---"}
                      </Box>
                    }
                  />
                </Tooltip>
                <Tooltip
                  title="Date the customer last purchased from the reference"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CalendarMonth />
                        Last Sale: {reference.lastSoldDate || "---"}
                      </Box>
                    }
                  />
                </Tooltip>
                <Tooltip
                  title="Average number of days it has historically taken the borrower to repay the invoice to the reference"
                  arrow
                >
                  <Chip
                    color="default"
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CurrencyExchangeOutlined />
                        Avg Days to Repay: {reference.avgDaysToRepay || "---"}
                      </Box>
                    }
                  />
                </Tooltip>
              </Stack>
              <Stack alignItems="right" style={{ gap: "8px" }}></Stack>
            </>
          )}
        </Box>
        {reference && (
          <Box
            style={{
              border: "solid",
              borderWidth: "2px",
              borderRadius: "16px",
              padding: "8px",
              borderColor: theme.palette.primary.main,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Typography variant="subtitle1" style={{ wordBreak: "break-word" }}>
              Considerations:
            </Typography>
            <Typography style={{ wordBreak: "break-word" }}>
              {reference.reviewItems && reference.reviewItems.length > 0
                ? reference.reviewItems.join(", ")
                : "None"}
            </Typography>

            <Typography variant="subtitle1" style={{ wordBreak: "break-word" }}>
              Comments:
            </Typography>
            <Typography style={{ wordBreak: "break-word" }}>{`${
              reference.comments || "None"
            }`}</Typography>
          </Box>
        )}
      </Stack>

      {open && (
        <Popover
          open={Boolean(open)}
          anchorEl={document.getElementById("more-" + open.id)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setAdd(open)
              setOpen(null)
            }}
          >
            <Box style={{ display: "flex", gap: "1rem" }}>
              <EditOutlined />
              View / Edit
            </Box>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setRequest(open)
              setOpen(null)
            }}
          >
            <Box style={{ display: "flex", gap: "1rem" }}>
              <ForwardToInboxOutlined />
              Send Request for Reference
            </Box>
          </MenuItem>
        </Popover>
      )}

      {request && (
        <RequestReferenceDialog
          reference={request}
          open={!!request}
          onClose={() => {
            setRequest(undefined)
          }}
        />
      )}

      {add && (
        <EditTradeReferenceDialog
          reference={add}
          open={!!add}
          onClose={() => {
            setAdd(undefined)
            refetch()
          }}
        />
      )}

      {createNewReference && application.id && (
        <AddNewTradeReferenceDialog
          applicationId={application.id}
          onClose={() => {
            setCreateNewReference(false)
            refetch()
          }}
          open={createNewReference}
        />
      )}
    </>
  )
}
