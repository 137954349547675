/* eslint-disable react/prop-types */
// @mui
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Stack from "@mui/material/Stack"
import CardHeader from "@mui/material/CardHeader"
import Typography from "@mui/material/Typography"
import { useDocumentFields } from "../../../queries/vendors/useDocumentFields"
import { DocumentField } from "../../../types"
import { LockOutlined, TimerOutlined } from "@mui/icons-material"
import { CardActions } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useRequestSecureDocument } from "../../../queries/credit/useRequestSecureDocument"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"

// ----------------------------------------------------------------------
export default function CreditApplicationDetailsExtra() {
  const params = useParams()
  const { id } = params
  const { data: application, refetch } = useCreditApplication(id || "")

  const { data } = useDocumentFields(application?.data.zohoReqId || "")

  const { enqueueSnackbar } = useSnackbar()

  const { execute: requestSecureDocument, isLoading: isRequesting } =
    useRequestSecureDocument(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
      refetch()
    })

  const { fields } = data || { fields: [] }

  if (application?.data.zohoProxyId && Object.keys(fields).length === 0) {
    return (
      <Card>
        <CardHeader
          title={
            <Box sx={{ display: "flex", gap: "8px" }}>
              <LockOutlined />
              Secure Documents
            </Box>
          }
        />

        <Box
          sx={{
            display: "flex",
            gap: "8px",
            marginTop: "1rem",
            justifyContent: "center",
          }}
        >
          <TimerOutlined />
          <Typography variant="body2">Pending Signature</Typography>
        </Box>
        <CardActions>
          <Box
            style={{ display: "flex", width: "100%", justifyContent: "end" }}
            sx={{ padding: "1rem" }}
          >
            <LoadingButton
              variant="contained"
              loading={isRequesting}
              onClick={() => {
                requestSecureDocument(application.id || "")
              }}
            >
              Request CC Authorization Again
            </LoadingButton>
          </Box>
        </CardActions>
      </Card>
    )
  }

  const renderCard = () => {
    if (!fields || !Array.isArray(fields) || fields.length === 0) {
      return (
        <>
          <Typography
            variant="body2"
            style={{ textAlign: "center", marginTop: "1rem" }}
          >
            No secure documents
          </Typography>
          <CardActions>
            <Box
              style={{ display: "flex", width: "100%", justifyContent: "end" }}
              sx={{ padding: "1rem" }}
            >
              <LoadingButton
                variant="contained"
                loading={isRequesting}
                onClick={() => {
                  requestSecureDocument(application?.id || "")
                }}
              >
                Request CC Authorization
              </LoadingButton>
            </Box>
          </CardActions>
        </>
      )
    }
    return (
      <Stack
        spacing={2}
        alignItems="flex-start"
        sx={{ my: 3, typography: "body2" }}
      >
        {fields &&
          Array.isArray(fields) &&
          fields.map((field: DocumentField) => {
            if (field.fieldLabel === "Signature") {
              return
            }
            return (
              <Stack
                direction="row"
                alignItems="center"
                key={field["fieldName"]}
              >
                <Box
                  component="span"
                  sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                >
                  {field.fieldLabel}
                </Box>
                {field.fieldLabel.includes("Card Number")
                  ? "**** **** **** " +
                    field.fieldValue.substr(field.fieldValue.length - 4)
                  : field.fieldValue}
              </Stack>
            )
          })}
      </Stack>
    )
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: "flex", gap: "8px" }}>
            <LockOutlined />
            Secure Documents
          </Box>
        }
      />

      <Stack
        sx={{
          px: 3,
        }}
      >
        {renderCard()}
      </Stack>
    </Card>
  )
}
