import PropTypes from "prop-types"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Button, Stack, AppBar, Toolbar, IconButton } from "@mui/material"
// utils
import { bgBlur } from "../../../utils/cssStyles"
// components
import Iconify from "../../../components/iconify"
import AccountPopover from "./AccountPopover"
import Searchbar from "./Searchbar"
import { useUser } from "../../../queries"

// ----------------------------------------------------------------------

const NAV_WIDTH = 280

const HEADER_MOBILE = 64

const HEADER_DESKTOP = 92

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header({ onOpenNav }) {
  const { data: user } = useUser()

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {user && user.userToBusiness && user.userToBusiness.business && (
          <Searchbar />
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          style={{
            marginLeft: "16px",
          }}
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  )
}
