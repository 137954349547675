import { LoadingButton } from "@mui/lab"
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
  DialogActions,
} from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"

import { useAddCollaborator } from "src/queries/credit/useAddCollaborator"

export default ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (first: string, last: string) => void
}) => {
  const [signerEmail, setSignerEmail] = useState("")
  const [isSigner, setIsSigner] = useState(false)
  const [signerFirstName, setSignerFirstName] = useState("")
  const [signerLastName, setSignerLastName] = useState("")

  const { execute: addCollaborator, isLoading } = useAddCollaborator()

  const [complete, setComplete] = useState(false)

  const params = useParams()
  const { id } = params

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSignerEmail("")
        setSignerFirstName("")
        setSignerLastName("")
        setIsSigner(false)
        onClose()
      }}
    >
      <DialogTitle>{complete ? "Success" : "One last thing"}</DialogTitle>
      <DialogContent>
        {!complete && (
          <>
            <p>
              We require a digital signature to finalize your application. By
              clicking continue you confirm that you are the authorized signer
              (usually Owner, Prinicipal, C-Suite or similar).
            </p>
            <p>
              If you are not authorized to sign the credit application, please
              invite the authorized person to sign. They will receive a link via
              email to continue the process.
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  id="is-signer"
                  onChange={(event) => {
                    setIsSigner(event.target.checked)
                  }}
                  checked={isSigner}
                />
              }
              label={
                <div>
                  I am the authorized signer (Owner, Prinicipal, C-Suite or
                  similar).
                </div>
              }
            />
            {!isSigner && (
              <TextField
                autoFocus
                margin="dense"
                id="signer-email"
                label="Signer Email"
                type="email"
                value={signerEmail}
                onChange={(event) => {
                  setSignerEmail(event.target.value)
                }}
                fullWidth
                variant="standard"
              />
            )}
            {isSigner && (
              <>
                <p>Please enter your legal first and last name.</p>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "32px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="signer-first-name"
                    fullWidth
                    label="First Name"
                    type="text"
                    required
                    value={signerFirstName}
                    onChange={(event) => {
                      setSignerFirstName(event.target.value)
                    }}
                  />
                  <TextField
                    id="signer-last-name"
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    type="text"
                    value={signerLastName}
                    onChange={(event) => {
                      setSignerLastName(event.target.value)
                    }}
                  />
                </Box>
              </>
            )}
          </>
        )}
        {complete && (
          <>
            <p>
              An email has been sent to the authorized signer with a link to
              complete the application. You can close this window now.
            </p>
            <p>
              Please note that the application is not considered complete until
              it is signed by the authorized signer.
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSignerEmail("")
            setSignerFirstName("")
            setSignerLastName("")
            setIsSigner(false)
            onClose()
          }}
        >
          Back
        </Button>
        {!complete && (
          <LoadingButton
            id="continue-button"
            loading={isLoading}
            disabled={
              (!isSigner && !signerEmail) ||
              (isSigner && (!signerFirstName || !signerLastName))
            }
            variant="contained"
            onClick={() => {
              if (!isSigner && signerEmail && id) {
                addCollaborator(id, signerEmail, () => {
                  setComplete(true)
                })
              }
              if (isSigner) {
                onClose()
                onSubmit(signerFirstName, signerLastName)
              }
            }}
          >
            Continue
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
