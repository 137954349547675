import { useState, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { ApplicationTemplate } from "../../types"
import queryString from "query-string"
import * as yup from "yup"
import { getEnabledSchema as getTradeEnabledSchema } from "src/sections/@dashboard/intake_sections/TradeReferencesSection"
import { getEnabledSchema as getBankEnabledSchema } from "src/sections/@dashboard/intake_sections/BankReferenceSection"
import { getEnabledSchema as getUserEnabledSchema } from "src/sections/@dashboard/intake_sections/UserRegistrationSection"
import { getEnabledSchema } from "src/sections/@dashboard/intake_sections/OwnersSection"
import { getEnabledSchema as getCompanyDetailsEnabledSchema } from "src/sections/@dashboard/intake_sections/CompanyDetailsSection"
import { getEnabledSchema as getPersonalGuarantyEnabledSchema } from "src/sections/@dashboard/intake_sections/PersonalGuarantySection"
import { getEnabledSchema as getAdditionalQuestionsSchema } from "src/sections/@dashboard/intake_sections/CustomQuestionsSection"
import { useCreditApplication } from "./useCreditApplication"

export function useApplicationTemplate(
  businessId?: string,
  anonymous = false,
  enabled = true,
  applicationId?: string,
) {
  const { data: application, error: applicationError } =
    useCreditApplication(applicationId)

  const [steps, setSteps] = useState<
    ApplicationTemplate["formTemplate"]["pages"]
  >([])

  const [dataSchema, setDataSchema] = useState(yup.object())

  const { data: baseTemplate, ...rest } = useQuery<ApplicationTemplate>(
    queryString.stringifyUrl({
      url: "/configure/template/1",
      query: { business_id: businessId },
    }),
    {
      enabled: businessId !== undefined || enabled,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
      meta: { anonymous },
    },
  )

  const template = useMemo(() => {
    if (application) {
      return {
        id: baseTemplate?.id,
        inviteOnly: baseTemplate?.inviteOnly,
        formTemplate: application?.template,
        zohoTemplateId: baseTemplate?.zohoTemplateId,
        seller: businessId,
        customFields: baseTemplate?.customFields,
        logo: baseTemplate?.logo,
        color: baseTemplate?.color,
        terms: baseTemplate?.terms,
      } as ApplicationTemplate
    } else {
      return baseTemplate
    }
  }, [application, businessId, baseTemplate])

  // does three things
  // 1. loads main steps
  // 2. adds additional questions step if custom fields are present
  // 3. patches application to stage 200 if consent is complete and changes active step to complete
  useEffect(() => {
    if (template) {
      let tempSteps = template?.formTemplate?.pages.filter((page) =>
        Boolean(page.enabled),
      )
      // if we have additional questions, add them
      if (
        template?.customFields &&
        template?.customFields?.length > 0 &&
        template?.customFields?.some(
          (field) => field.associatedPage === "Additional Questions",
        ) &&
        tempSteps.find((tstep) => tstep.label === "Additional Questions") ===
          undefined
      ) {
        tempSteps = tempSteps.concat([
          {
            label: "Additional Questions",
            description: "Please answer the following questions to continue.",
            enabled: true,
            required: false,
            config: [],
          },
        ])
      }

      let tempSchema = yup.object({})
      tempSchema = tempSchema.concat(getUserEnabledSchema(template))
      tempSchema = tempSchema.concat(getTradeEnabledSchema(template))
      tempSchema = tempSchema.concat(getBankEnabledSchema(template))
      tempSchema = tempSchema.concat(getPersonalGuarantyEnabledSchema(template))
      tempSchema = tempSchema.concat(getEnabledSchema(template))
      tempSchema = tempSchema.concat(getCompanyDetailsEnabledSchema(template))
      tempSchema = tempSchema.concat(getAdditionalQuestionsSchema(template))
      setDataSchema(tempSchema)
      setSteps(tempSteps)
      return
    }
  }, [template])

  return { data: template, dataSchema, steps, applicationError, ...rest }
}
