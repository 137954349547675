import { useState } from "react"

import {
  Card,
  Container,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
} from "@mui/material"

import Grid from "@mui/material/Unstable_Grid2"

// components
import { useParams } from "../../../../routes/hook"
import { useSettingsContext } from "../../../../components/settings"
//
import CreditApplicationDetailsInfo from "../credit-application-details-info"
import CreditApplicationDetailsItems from "../credit-application-details-item"
import CreditApplicationDetailsToolbar from "../CreditApplicationDetailsToolbar"
import { useCreditApplication } from "../../../../queries/credit/useCreditApplication"
import Report from "../Report"
import LandTitle from "../LandTitle"
import CreditApplicationDetailsExtra from "../credit-application-details-extra"
import CreditApplicationBaseInfo from "../credit-application-base-info"
import CreditApplicationBankReference from "../credit-application-bank-reference"
import ApplicationComments from "../components/ApplicationComments"
import CreditApplicationDetailsPersonalGuaranty from "../credit-application-details-personal-guaranty"
import UploadedCreditReport from "../UploadedCreditReport"
import OpenCorporateComapniesData from "../components/OpenCorporateCompaniesData"
import emitter from "src/services/emitter-service"

import NamedButton from "src/components/named-button/NamedButton"
import OpenCorporateSearchbar from "../components/OpenCorporateSearchbar"
import { ScreenSearchDesktopOutlined } from "@mui/icons-material"
// ----------------------------------------------------------------------

export default function CreditApplicationDetailsView() {
  const settings = useSettingsContext()

  const params = useParams()

  const { id } = params

  const { data } = useCreditApplication(id)

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const [openCompanyDataDialogs, setOpenCompanyDataDialogs] = useState(false)
  const [timeStamp, setTimeStamp] = useState(0)

  if (!data) {
    return <></>
  }

  const handleSearchButtonClick = () => {
    setIsSearchDialogOpen(true)
    setTimeStamp(Date.now())
    emitter.emit("applicationLegalBusinessName", data.data.legalBusinessName)
  }
  const handleCloseSearchDialog = () => {
    setIsSearchDialogOpen(false)
    setOpenCompanyDataDialogs(true)
  }

  return (
    <Container maxWidth={false}>
      <CreditApplicationDetailsToolbar
        backLink={"/applications"}
        application={data}
      />

      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          {data.seller?.name?.includes("Quecan") && (
            <CreditApplicationDetailsInfo application={data} />
          )}
          {!data.seller.name.includes("Quecan") && (
            <>
              <CreditApplicationBaseInfo applicationId={data.id} />
              <OpenCorporateComapniesData
                openCompanyDataDialogs={openCompanyDataDialogs}
              />
            </>
          )}
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={3} direction={{ xs: "column-reverse", md: "column" }}>
            <Card>
              <CardHeader
                title={"Business Registries"}
                subheader="Search global business registries for incorporation, officer and corporate events"
              />
              <CardContent style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  onClick={handleSearchButtonClick}
                  variant="contained"
                  style={{ margin: "1rem" }}
                  startIcon={<ScreenSearchDesktopOutlined />}
                >
                  Search
                </Button>
              </CardContent>
            </Card>
            {["Quecan", "MKVapes"].includes(data.seller.name) && (
              <CreditApplicationDetailsExtra />
            )}
            {!data.seller?.name?.includes("Quecan") && (
              <CreditApplicationBankReference application={data} />
            )}

            <ApplicationComments application={data} />
            <OpenCorporateSearchbar
              isSearchDialogOpen={isSearchDialogOpen}
              handleCloseSearchDialog={handleCloseSearchDialog}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
