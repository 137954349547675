import { QueryClient } from "react-query"
import { UploadedCreditReport } from "src/types"
import { useTypedMutation } from "../useTypedMutation"

// to be used for generating apps based on the base template
export function useUploadCreditReport(
  applicationId: string,
  onSuccess?: (data: UploadedCreditReport) => void,
) {
  const ENDPOINT =
    "/application/base/credit_report/?application_id=" + applicationId
  const post = useTypedMutation<FormData>(
    "post/application/base/credit_report",
    onSuccess,
  )

  const execute = (files: File[]) => {
    const formData = new FormData()
    files.forEach((file, index) => {
      formData.append("file" + index, file as File)
    })

    formData.append("application", applicationId)
    post.mutate(
      {
        method: "post",
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...post, execute }
}
