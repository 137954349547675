import { AddressAutofill } from "@mapbox/search-js-react"

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { useEffect } from "react"
import { Application } from "src/types"

export const STATES = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

const getStates = () => {
  return STATES.map((state) => (
    <MenuItem value={state} key={state}>
      {state}
    </MenuItem>
  ))
}

export const PROVINCES = [
  "Ontario",
  "Quebec",
  "British Columbia",
  "Alberta",
  "Manitoba",
  "Saskatchewan",
  "Nova Scotia",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Prince Edward Island",
  "Northwest Territories",
  "Nunavut",
  "Yukon",
]

const getProvinces = () => {
  return PROVINCES.map((province) => (
    <MenuItem value={province} key={province}>
      {province}
    </MenuItem>
  ))
}

const UNITED_STATES = "United States"
const CANADA = "Canada"

export const COUNTRIES = [UNITED_STATES, CANADA]

const getCountries = () => {
  return COUNTRIES.map((country) => (
    <MenuItem value={country} key={country}>
      {country}
    </MenuItem>
  ))
}

// for mapbox
const accessToken =
  "pk.eyJ1Ijoic29yb3VzaG5ldG5vdyIsImEiOiJjbGVzeGFkbDgwMGFvM3FsNDNnZjM4dWUzIn0.w391C51Mlz8fxYoDeNMmLQ"

export default ({
  keyPrefix,
  keySuffix = "",
  data,
  onChange,
  errors,
  required = true,
}: {
  keyPrefix: string
  keySuffix?: string
  data: Application["data"]
  errors: Application["data"] | undefined
  onChange: (key: string, value: string | undefined) => void
  required?: boolean
}) => {
  useEffect(() => {
    if (
      !data[keyPrefix + "Address" + keySuffix] &&
      !data[keyPrefix + "Country" + keySuffix] &&
      !data[keyPrefix + "Region" + keySuffix]
    ) {
      onChange(keyPrefix + "Country" + keySuffix, UNITED_STATES)
    }
  }, [data, keyPrefix, keySuffix, onChange])
  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "5%",
        }}
      >
        <div style={{ width: "100%" }}>
          <AddressAutofill
            options={{
              language: "en",
            }}
            accessToken={accessToken}
            onRetrieve={(res) => {
              onChange(
                keyPrefix + "Address" + keySuffix,
                res.features[0].properties.feature_name.trim(),
              )
              onChange(
                keyPrefix + "City" + keySuffix,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                res.features[0].properties.place.trim(),
              )
              onChange(
                keyPrefix + "Region" + keySuffix,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                res.features[0].properties.region.trim(),
              )
              onChange(
                keyPrefix + "PostCode" + keySuffix,
                res.features[0].properties.postcode,
              )
              onChange(
                keyPrefix + "Country" + keySuffix,
                res.features[0].properties.country?.trim(),
              )
            }}
            onChange={(val) => {
              if (data[keyPrefix + "Address" + keySuffix] !== val) {
                onChange(keyPrefix + "Address" + keySuffix, val)
              }
            }}
          >
            <TextField
              id={keyPrefix + "address-line1" + keySuffix}
              autoComplete="shipping address-line1"
              required={required}
              margin="normal"
              fullWidth
              name="address"
              label="Address"
              type="text"
              defaultValue={data[keyPrefix + "Address" + keySuffix]}
              value={data[keyPrefix + "Address" + keySuffix]}
              inputProps={{
                style: { height: "56px" },
              }}
              error={
                errors
                  ? Boolean(errors[keyPrefix + "Address" + keySuffix])
                  : false
              }
            />
          </AddressAutofill>
        </div>
        <TextField
          id={keyPrefix + "address-line2" + keySuffix}
          autoComplete="shipping address-line2"
          margin="normal"
          fullWidth
          name="address2"
          label="Address Line 2"
          type="text"
          value={data[keyPrefix + "AddressLine2" + keySuffix]}
          inputProps={{
            style: { height: "56px" },
          }}
          onChange={(event) => {
            onChange(keyPrefix + "AddressLine2" + keySuffix, event.target.value)
          }}
        />
      </Box>
      {data[keyPrefix + "Address" + keySuffix] && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            id={keyPrefix + "city" + keySuffix}
            margin="normal"
            fullWidth
            required={required}
            label="City"
            type="text"
            value={data[keyPrefix + "City" + keySuffix]}
            inputProps={{
              style: { height: "56px" },
            }}
            onChange={(event) => {
              onChange(keyPrefix + "City" + keySuffix, event.target.value)
            }}
            error={
              errors ? Boolean(errors[keyPrefix + "City" + keySuffix]) : false
            }
          />
          <FormControl fullWidth margin="normal" required={required}>
            <InputLabel id="region-label">
              {data[keyPrefix + "Country" + keySuffix] === UNITED_STATES
                ? "State"
                : "Province"}
            </InputLabel>
            <Select
              labelId="region-label"
              id={keyPrefix + "region-select" + keySuffix}
              value={data[keyPrefix + "Region" + keySuffix] || ""}
              defaultValue={data[keyPrefix + "Region" + keySuffix]}
              onChange={(event) => {
                onChange(keyPrefix + "Region" + keySuffix, event.target.value)
              }}
              label={
                data[keyPrefix + "Country" + keySuffix] === UNITED_STATES
                  ? "State"
                  : "Province"
              }
              style={{ height: "56px" }}
              error={
                errors
                  ? Boolean(errors[keyPrefix + "Region" + keySuffix])
                  : false
              }
            >
              <MenuItem value={""} disabled>
                Select One
              </MenuItem>
              {data[keyPrefix + "Country" + keySuffix] === UNITED_STATES &&
                getStates()}
              {data[keyPrefix + "Country" + keySuffix] === CANADA &&
                getProvinces()}
            </Select>
          </FormControl>
          <TextField
            id={keyPrefix + "postCode" + keySuffix}
            margin="normal"
            required={required}
            fullWidth
            label="Postal/Zip Code"
            type="text"
            value={data[keyPrefix + "PostCode" + keySuffix]}
            inputProps={{
              style: { height: "56px" },
            }}
            onChange={(event) => {
              onChange(keyPrefix + "PostCode" + keySuffix, event.target.value)
            }}
            error={
              errors
                ? Boolean(errors[keyPrefix + "PostCode" + keySuffix])
                : false
            }
          />
          <FormControl fullWidth margin="normal" required={required}>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              id={keyPrefix + "country-select" + keySuffix}
              value={data[keyPrefix + "Country" + keySuffix] || ""}
              defaultValue={data[keyPrefix + "Country" + keySuffix]}
              onChange={(event) => {
                onChange(keyPrefix + "Country" + keySuffix, event.target.value)
                onChange(keyPrefix + "Region" + keySuffix, undefined)
              }}
              label="Country"
              style={{ height: "56px" }}
              error={
                errors
                  ? Boolean(errors[keyPrefix + "Country" + keySuffix])
                  : false
              }
            >
              <MenuItem value={""} disabled>
                Select One
              </MenuItem>
              {getCountries()}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  )
}
