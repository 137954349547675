import queryString from "query-string"
import { useQuery } from "react-query"

export function useEmailStatus(emails: string[], applicationId: string) {
  return useQuery<any>(
    "/email/status?" +
      queryString.stringify(
        {
          emails,
          application_id: applicationId,
        },
        { arrayFormat: "comma" },
      ),
    {
      // enabled: !!emails && emails.length > 0 && !!applicationId,
      enabled: false,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
