import { ZoomIn, ZoomOut } from "@mui/icons-material"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Pagination,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { useUploadedFile } from "../../queries/credit/useUploadedFile"

// loads an image or pdf file using an UploadedFile id
export default ({
  fileId,
  onClose,
}: {
  fileId?: string
  onClose: () => void
}) => {
  useEffect(() => {
    if (fileId) {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
      setScale(1)
      setPageNumber(1)
    }
  }, [fileId])

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)

  const { data: fetchedDocument, isLoading } = useUploadedFile(fileId)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <Dialog
      open={!!fileId}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth={false}
    >
      <DialogTitle>{fetchedDocument?.name || "Loading..."}</DialogTitle>
      {(!fetchedDocument || isLoading) && (
        <DialogContent>Loading...</DialogContent>
      )}
      {fetchedDocument && (
        <>
          <DialogContent>
            <Box>
              {fetchedDocument.file.includes(".pdf") && (
                <Document
                  file={fetchedDocument.file}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} scale={scale} />
                </Document>
              )}
              {!fetchedDocument.file.includes(".pdf") &&
                !fetchedDocument.file.includes(".msg") && (
                  <img src={fetchedDocument.file} />
                )}
              {fetchedDocument.file.includes(".msg") && (
                <Box>
                  <p>
                    This file type is not supported by the browser. Please
                    download the file to view it.
                  </p>
                </Box>
              )}
            </Box>
          </DialogContent>
          {fetchedDocument.file.includes(".pdf") && (
            <DialogActions>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "2rem",
                }}
              >
                <Box style={{ display: "flex", gap: "1rem" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setScale((prev) => prev + 0.1)
                    }}
                  >
                    <ZoomIn />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (scale < 0.2) return
                      setScale((prev) => prev - 0.1)
                    }}
                  >
                    <ZoomOut />
                  </Button>
                </Box>
                <Box style={{ display: "flex", gap: "1rem", flexShrink: 0 }}>
                  <Pagination
                    count={numPages}
                    color="primary"
                    onChange={(event, page) => setPageNumber(page)}
                  />
                </Box>
                <Box style={{ display: "flex", gap: "1rem" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open(fetchedDocument.file, "_blank")
                    }}
                  >
                    Download
                  </Button>
                  <Button variant="contained" onClick={onClose}>
                    Close
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          )}
          {!fetchedDocument.file.includes(".pdf") && (
            <DialogActions>
              <Button
                onClick={() => {
                  window.open(fetchedDocument.file, "_blank")
                }}
              >
                Download
              </Button>
              <Button onClick={onClose}>Close</Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  )
}
