import { Filings } from "src/types"
import { Box, Card, CardHeader, Stack, Typography } from "@mui/material"

interface FilingsRowProps {
  filing: Filings | undefined
}

const FilingsRow = ({ filing }: FilingsRowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200%",
      }}
    >
      <Stack>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginRight: 1, marginLeft: 2 }}
        >
          Title
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginRight: 1, marginLeft: 2 }}
        >
          Date
        </Typography>
      </Stack>
      <Stack sx={{ marginLeft: 2, width: "auto", flexGrow: 1 }}>
        <Typography variant="body2">{filing?.title ?? ""}</Typography>
        <Typography variant="body2">{filing?.date ?? ""}</Typography>
      </Stack>
    </Box>
  )
}
interface FilingsTableRowProps {
  filing: Filings
}

const FilingsTableRow = ({ filing }: FilingsTableRowProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "8px",
      }}
    >
      <FilingsRow filing={filing} />
    </div>
  )
}

interface FilingsTableProps {
  filings: Filings[] | undefined
}

const FilingsTable = ({ filings }: FilingsTableProps) => {
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <Typography variant="h6">Company Filings</Typography>
            </Box>
          }
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            p: 2,
            gap: 2,
          }}
        >
          {filings?.map((filing, index) => (
            <Box
              key={index}
              sx={{
                flex: "1 1 calc(50% - 16px)",
                marginBottom: "10px",
              }}
            >
              <FilingsTableRow filing={filing} />
            </Box>
          ))}
        </Box>
      </Card>
    </>
  )
}

export default FilingsTable
