// ----------------------------------------------------------------------

export function getAllItems({ data }) {
  const items = data.map((application) => {
    return {
      group: "Application",
      title:
        application.data.legalBusinessName ||
        `${application.data.firstName} ${application.data.lastName}`,
      path: "/applications/" + application.id,
      subtitle: application.collaborators,
      status: application.stage,
    }
  })

  return items
}

// ----------------------------------------------------------------------

export function splitPath(array, key) {
  let stack = array.map((item) => ({
    path: [item.title],
    currItem: item,
  }))

  while (stack.length) {
    const { path, currItem } = stack.pop()

    if (currItem.path === key) {
      return path
    }

    if (currItem.children?.length) {
      stack = stack.concat(
        currItem.children.map((item) => ({
          path: path.concat(item.title),
          currItem: item,
        })),
      )
    }
  }
  return null
}

// ----------------------------------------------------------------------

export function handleLoop(array, subheader) {
  return array?.map((list) => ({
    subheader,
    ...list,
    ...(list.children && {
      children: handleLoop(list.children, subheader),
    }),
  }))
}

// ----------------------------------------------------------------------

export function groupedData(array) {
  const group = array.reduce((groups, item) => {
    groups[item.group] = groups[item.group] || []

    groups[item.group].push(item)

    return groups
  }, {})

  return group
}
