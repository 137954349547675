import {
  ArrowForward,
  ExpandMoreOutlined,
  Search,
  Lock,
  ThumbUp,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  useTheme,
  Stack,
  Grid,
} from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import React from "react"
import { usePlaidLink } from "react-plaid-link"
import { Application, ApplicationTemplate, CreditApplication } from "src/types"
import { usePlaidLinkToken } from "src/queries"
import ReactPhoneInput from "react-phone-input-material-ui"
import * as yup from "yup"
import { getAdditionalQuestionSchemaForPage, isPageRequired } from "../utils"
import { PAGE_LABEL_BANK_REFERENCE } from "./constants"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"

const BANK_REFERENCES_YUP_SCHEMA = yup.object({
  plaidPublicToken: yup.string(),
  bankName: yup.string().when(["plaidPublicToken", "plaidData"], {
    is: (fieldTwo: string, fieldThree: string) =>
      (!fieldTwo || fieldTwo.length === 0) &&
      (!fieldThree || fieldThree.length === 0),
    then: yup.string().required("Bank name is required"),
  }),
  bankAccountNumber: yup.string().when(["plaidPublicToken", "plaidData"], {
    is: (fieldTwo: string, fieldThree: string) =>
      (!fieldTwo || fieldTwo.length === 0) &&
      (!fieldThree || fieldThree.length === 0),
    then: yup
      .string()
      .required("Account number is required")
      .matches(/^[0-9]+$/, "Must be only digits"),
  }),

  bankInstitutionNumber: yup
    .string()
    .when(["plaidPublicToken", "plaidData", "businessCountry"], {
      is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
        fieldFour === "Canada" &&
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup
        .string()
        .required("Institution number is required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(3, "Must be exactly 3 digits")
        .max(3, "Must be exactly 3 digits"),
    }),
  bankTransitNumber: yup
    .string()
    .when(["plaidPublicToken", "plaidData", "businessCountry"], {
      is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
        fieldFour === "Canada" &&
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup.string().required("Transit number is required"),
    })
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  bankContactName: yup.string().when(["plaidPublicToken", "plaidData"], {
    is: (fieldTwo: string, fieldThree: string) =>
      (!fieldTwo || fieldTwo.length === 0) &&
      (!fieldThree || fieldThree.length === 0),
    then: yup.string().required("Bank contact name is required"),
  }),
  bankContactEmail: yup
    .string()
    .email()
    .when(["plaidPublicToken", "plaidData"], {
      is: (fieldTwo: string, fieldThree: string) =>
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup.string().email().required("Bank contact email is required"),
    }),
  bankContactPhoneNumber: yup
    .string()
    .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Phone number is not valid")
    .when(["plaidPublicToken", "plaidData"], {
      is: (fieldTwo: string, fieldThree: string) =>
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup
        .string()
        .required("Phone number is required")
        .matches(
          /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
          "Phone number is not valid",
        ),
    }),
})

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  if (
    template?.formTemplate?.pages
      .filter((page) => Boolean(page.enabled) && Boolean(page.required))
      .find((tstep) => tstep.label === PAGE_LABEL)
  ) {
    let enabledSchema = yup.object({}).concat(BANK_REFERENCES_YUP_SCHEMA)
    enabledSchema = enabledSchema.concat(
      yup.object({
        customFields: getAdditionalQuestionSchemaForPage(template, PAGE_LABEL),
      }),
    )
    if (isRoutingEnabled(template)) {
      return enabledSchema.concat(
        yup.object({
          bankRoutingNumber: yup
            .string()
            .when(["plaidPublicToken", "plaidData", "businessCountry"], {
              is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
                fieldFour === "United States" &&
                (!fieldTwo || fieldTwo.length === 0) &&
                (!fieldThree || fieldThree.length === 0),
              then: yup
                .string()
                .required("Routing number is required")
                .matches(/^[0-9]+$/, "Must be only digits")
                .min(9, "Must be exactly 9 digits")
                .max(9, "Must be exactly 9 digits"),
            }),
        }),
      )
    }
  } else if (
    template?.formTemplate?.pages
      .filter((page) => Boolean(page.enabled))
      .find((tstep) => tstep.label === PAGE_LABEL)
  ) {
    return yup.object({
      plaidPublicToken: yup.string(),
      bankAccountNumber: yup.string().when("plaidPublicToken", {
        is: (fieldTwo: string) => !fieldTwo || fieldTwo.length === 0,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .when(["bankInstitutionNumber", "bankTransitNumber"], {
            is: (fieldThree: string, fieldFour: string) => {
              return Boolean(fieldThree || fieldFour)
            },
            then: yup
              .string()
              .required(
                "Bank reference is optional. However, if you decide to provide it, a valid account number must be entered",
              ),
            // .matches(/^[0-9]+$/, "Must be only digits"),
          }),
      }),
      bankRoutingNumber: yup
        .string()
        .when(["plaidPublicToken", "businessCountry"], {
          is: (fieldTwo: string, fieldThree: string) =>
            fieldThree === "United States" &&
            (!fieldTwo || fieldTwo.length === 0),
          then: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .test(
              "len",
              "Bank reference is optional. However, if you decide to provide it, routing number must be exactly 9 digits",
              (val) => {
                if (val === undefined) {
                  return true
                }
                return val.length === 0 || val.length === 9
              },
            ),
          // .when(["bankAccountNumber"], {
          //   is: (fieldTwo: string) => {
          //     return Boolean(fieldTwo)
          //   },
          //   then: yup
          //     .string()
          //     .required(
          //       "Bank reference is optional. However, if you decide to provide it, routing number must be exactly 9 digits",
          //     ),
          // }),
        }),
      bankInstitutionNumber: yup
        .string()
        .when(["plaidPublicToken", "businessCountry"], {
          is: (fieldTwo: string, fieldThree: string) =>
            fieldThree === "Canada" && (!fieldTwo || fieldTwo.length === 0),
          then: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .test(
              "len",
              "Bank reference is optional. However, if you decide to provide it, institution number must be exactly 3 digits",
              (val) => {
                if (val === undefined) {
                  return true
                }
                return val.length === 0 || val.length === 3
              },
            )
            .when(["bankAccountNumber", "bankTransitNumber"], {
              is: (fieldTwo: string, fieldThree: string) => {
                return Boolean(fieldTwo || fieldThree)
              },
              then: yup
                .string()
                .required(
                  "Bank reference is optional. However, if you decide to provide it, institution number must be exactly 3 digits",
                ),
            }),
        }),
      bankTransitNumber: yup
        .string()
        .when(["plaidPublicToken", "businessCountry"], {
          is: (fieldTwo: string, fieldThree: string) =>
            fieldThree === "Canada" && (!fieldTwo || fieldTwo.length === 0),
          then: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .test(
              "len",
              "Bank reference is optional. However, if you decide to provide it, transit number must be exactly 5 digits",
              (val) => {
                if (val === undefined) {
                  return true
                }
                return val.length === 0 || val.length === 5
              },
            )
            .when(["bankAccountNumber", "bankInstitutionNumber"], {
              is: (fieldTwo: string, fieldThree: string) => {
                return Boolean(fieldTwo || fieldThree)
              },
              then: yup
                .string()
                .required(
                  "Bank reference is optional. However, if you decide to provide it, transit number must be exactly 5 digits",
                ),
            }),
        }),
    })
  }
  return yup.object({})
}

const useStyles = makeStyles(() =>
  createStyles({
    MuiAccordionroot: {
      "&:before": {
        display: "none",
      },
    },
  }),
)

const PAGE_LABEL = PAGE_LABEL_BANK_REFERENCE

type Props = {
  application: Application
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCustomChange: (key: string, value: any) => void
  onContinue: () => void
  template?: ApplicationTemplate
}

const isRoutingEnabled = (template?: ApplicationTemplate) => {
  if (!template) return true

  const config = template?.formTemplate?.pages
    .filter((page) => Boolean(page.enabled) && Boolean(page.required))
    .find((tstep) => tstep.label === "Bank Reference")
    ?.config.find((c) => c.label === "Routing Number")

  if (!config) return true

  return Boolean(config.value)
}

export default ({
  onContinue,
  onChange,
  data,
  errors,
  template,
  onCustomChange,
  application,
}: Props) => {
  const classes = useStyles()

  const theme = useTheme()

  const { data: business } = useAnonymousBusiness(data?.seller)

  const getBuyerAddress = () => {
    if (business?.country) {
      return business.country
    }
    if (data?.businessCountry) {
      return data.businessCountry
    }
    if (data?.shippingCountry) {
      return data?.shippingCountry
    }

    return "United States"
  }

  const { data: linkTokenResponse } = usePlaidLinkToken(true, getBuyerAddress())

  const { open: openPlaid, ready } = usePlaidLink({
    token: linkTokenResponse?.linkToken || "",
    onSuccess: (public_token) => {
      // this will be exchanged in the next step when application is submitted
      onChange("plaidPublicToken", public_token)
      onContinue()
    },
  })
  const getDefaultExpanded = () => {
    if (data?.plaidData) {
      return "panel1"
    }

    if (data?.bankName) {
      return "panel2"
    }
    return "panel1"
  }

  const [expanded, setExpanded] = React.useState<string | false>(
    getDefaultExpanded(),
  )

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Bank Reference
        {isPageRequired(PAGE_LABEL, template) ? "" : " (Optional)"}
      </Typography>
      <Typography
        gutterBottom
        variant="body2"
        sx={{ color: "text.primary", textAlign: "left" }}
      >
        A bank reference can be provided in one of two ways. The{" "}
        <strong>Instant</strong> bank reference provides a secure, digital bank
        reference to us and helps us make a credit decision much faster.{" "}
        <strong>Manual</strong> bank reference can also be provided and will
        require you to provide your account number and contact information of
        your branch / account manager.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: theme.palette.success.dark,
          borderRadius: "16px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h6">Instant Bank Reference</Typography>
          <Typography
            variant="caption"
            sx={{
              padding: "5px",
              borderRadius: "4px",
              color: theme.palette.success.dark,
              marginLeft: "4px",
              backgroundColor: "rgb(34, 154, 22, 0.1)",
            }}
          >
            <strong>Recommended</strong>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              marginLeft: "auto",
              color: "text.secondary",
              marginTop: "0.5em",
            }}
          >
            1 minute or less to complete
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!data.plaidData && (
            <>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  p: 1,
                  backgroundColor: "rgb(34, 154, 22, 0.1)",
                }}
              >
                <Typography variant="caption">
                  <Lock
                    sx={{
                      color: theme.palette.success.dark,
                      marginRight: "2px",
                    }}
                  />
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: "0.26em" }}>
                  Your data is protected with 256-bit encryption
                </Typography>
              </Stack>
              <Grid sx={{ p: 1 }}>
                <Grid item>
                  <Typography variant="h6">How It works</Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" sx={{ p: 1 }}>
                    <Stack sx={{ paddingTop: "0.4em" }}>
                      <Typography variant="body1">
                        <strong>Step 1</strong>
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingX: 1, margin: 1 }}>
                      <img
                        src="/assets/images/plaid-logo.png"
                        style={{ width: "33px", borderRadius: "4px" }}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="h6">
                        You select your financial institution through Plaid
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        Plaid has partnered with 11,000+ financial institutions
                        across the US and Canada
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ p: 1 }}>
                    <Stack sx={{ paddingTop: "0.4em" }}>
                      <Typography variant="body1">
                        <strong>Step 2</strong>
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingX: 1, margin: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: "rgb(34, 154, 22, 0.1)",
                          borderRadius: "4px",
                          paddingTop: 0.5,
                          paddingLeft: 0.6,
                          paddingRight: 0.6,
                        }}
                      >
                        <Lock
                          sx={{
                            color: theme.palette.success.dark,
                          }}
                        />
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">
                        You login securely into your bank
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        NetNow or the supplier will never get access to
                        credentials
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ p: 1 }}>
                    <Stack sx={{ paddingTop: "0.4em" }}>
                      <Typography variant="body1">
                        <strong>Step 3</strong>
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingX: 1, margin: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: "#B95CF4",
                          borderRadius: "4px",
                          paddingTop: 0.5,
                          paddingLeft: 0.8,
                          paddingRight: 0.8,
                        }}
                      >
                        <ThumbUp
                          fontSize="small"
                          sx={{ color: theme.palette.info.contrastText }}
                        />
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">
                        You Give Consent for Bank Reference Authorization
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        You share limited READ-ONLY information like account
                        balance and transactions
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ paddingX: 1 }}
                  >
                    <Stack sx={{ marginTop: 3 }}>
                      <Stack>
                        <Typography variant="caption">
                          <strong>
                            Plaid is trusted by 1000+ organizations Including:
                          </strong>
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ marginTop: 1 }}>
                        <Stack sx={{ paddingX: 1.1 }}>
                          <img
                            src="/assets/images/Venmo.png"
                            style={{
                              width: "80px",
                              opacity: 0.2,
                            }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1 }}>
                          <img
                            src="/assets/images/Betterment.png"
                            style={{ width: "140px", opacity: 0.2 }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1 }}>
                          <img
                            src="/assets/images/Dave.png"
                            style={{ width: "70px", opacity: 0.2 }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1, marginTop: -0.5 }}>
                          <img
                            src="/assets/images/RBC.png"
                            style={{ width: "20px", opacity: 0.2 }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <LoadingButton
                        loading={!ready}
                        endIcon={<ArrowForward />}
                        size="large"
                        onClick={() => {
                          openPlaid()
                        }}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Find my bank
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
          {data.plaidData &&
            data?.plaidData?.bankInfo?.accounts?.map((a: any) => (
              <Stack key={a.account_id}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <Typography>{a.name}</Typography>
                  <Typography>{a.officialName}</Typography>
                </Box>
                <Typography>**********{a.mask}</Typography>
                <LoadingButton
                  onClick={() => {
                    onContinue()
                  }}
                  size="large"
                  fullWidth
                  endIcon={<ArrowForward />}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Continue
                </LoadingButton>
                <LoadingButton
                  loading={!ready}
                  startIcon={<Search />}
                  size="large"
                  onClick={() => {
                    onChange("plaidData", undefined)
                    openPlaid()
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Connect another account
                </LoadingButton>
              </Stack>
            ))}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: "#D6E4FF",
          borderRadius: "16px",
          marginTop: "16px",
        }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6" sx={{ width: "33%", flexShrink: 0 }}>
            Manual
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Provide details of your bank account and representative. This
            typically results in a longer approval process and might result in a
            lower credit limit
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
            We will not use this information for any payment processing.
          </Typography>

          <TextField
            margin="normal"
            fullWidth
            label="Bank Name"
            value={data.bankName}
            onChange={(event) => {
              onChange("bankName", event.target.value)
            }}
            required={isPageRequired(PAGE_LABEL, template)}
            error={Boolean(errors?.bankName)}
          />
          {data.businessCountry === "Canada" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Account Number"
                value={data.bankAccountNumber}
                onChange={(event) => {
                  onChange("bankAccountNumber", event.target.value.trim())
                }}
                error={Boolean(errors?.bankAccountNumber)}
                required={isPageRequired(PAGE_LABEL, template)}
                helperText={errors?.bankAccountNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Transit Number"
                value={data.bankTransitNumber}
                onChange={(event) => {
                  onChange("bankTransitNumber", event.target.value.trim())
                }}
                required={isPageRequired(PAGE_LABEL, template)}
                error={Boolean(errors?.bankTransitNumber)}
                helperText={errors?.bankTransitNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Institution Number"
                value={data.bankInstitutionNumber}
                onChange={(event) => {
                  onChange("bankInstitutionNumber", event.target.value.trim())
                }}
                required={isPageRequired(PAGE_LABEL, template)}
                error={Boolean(errors?.bankInstitutionNumber)}
                helperText={errors?.bankInstitutionNumber}
              />
            </Box>
          )}
          {data.businessCountry === "United States" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Account Number"
                value={data.bankAccountNumber}
                onChange={(event) => {
                  onChange("bankAccountNumber", event.target.value.trim())
                }}
                required={isPageRequired(PAGE_LABEL, template)}
                error={Boolean(errors?.bankAccountNumber)}
                helperText={errors?.bankAccountNumber}
              />
              {isRoutingEnabled(template) && (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Routing Number"
                  value={data.bankRoutingNumber}
                  onChange={(event) => {
                    onChange("bankRoutingNumber", event.target.value.trim())
                  }}
                  required={isPageRequired(PAGE_LABEL, template)}
                  error={Boolean(errors?.bankRoutingNumber)}
                  helperText={errors?.bankRoutingNumber}
                />
              )}
            </Box>
          )}
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Account Manager Name"
              value={data.bankContactName}
              onChange={(event) => {
                onChange("bankContactName", event.target.value)
              }}
              required={isPageRequired(PAGE_LABEL, template)}
              error={Boolean(errors?.bankContactName)}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Account Manager Email"
              value={data.bankContactEmail}
              onChange={(event) => {
                onChange("bankContactEmail", event.target.value)
              }}
              required={isPageRequired(PAGE_LABEL, template)}
              error={Boolean(errors?.bankContactEmail)}
            />
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Line of Credit Amount (if applicable)"
              value={data.bankContactName}
              onChange={(event) => {
                onChange("bankContactName", event.target.value)
              }}
              error={Boolean(errors?.bankContactName)}
            />
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <ReactPhoneInput
                value={data.bankContactPhoneNumber}
                country={"us"}
                onChange={(event) => {
                  onChange("bankContactPhoneNumber", "+" + event)
                }}
                countryCodeEditable={false}
                label="Account Manager Phone Number"
                component={TextField}
                containerStyle={{ width: "100%", marginTop: "8px" }}
                inputProps={{
                  required: isPageRequired(PAGE_LABEL, template),
                  error: Boolean(errors?.bankContactPhoneNumber),
                }}
              />
            </Box>
          </Box>

          {template?.customFields && (
            <ReusableCustomQuestions
              application={application}
              associatedPage={PAGE_LABEL}
              data={data["customFields"]}
              errors={errors}
              onChange={onCustomChange}
            />
          )}

          <Button
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit Manual Reference
          </Button>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
