import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import EventsTable from "../../components/events-table"
import emitter from "src/services/emitter-service"
import React, { useEffect, useState } from "react"
import {
  useOpenCorporateGetCompaniesEvents,
  useOpenCorporateGetCompanies,
} from "src/queries/vendors/useOpenCorporate"
import {
  Box,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import CompanyDataTable from "../../components/company-data-table/CompanyDataTable"
import OfficersTable from "../../components/officers-table/OfficersTable"
import FilingsTable from "../../components/filings-table/FilingsTable"
import Scrollbar from "src/components/scrollbar"
import { Close } from "@mui/icons-material"

interface OpenCorporateComapniesDataProps {
  openCompanyDataDialogs: boolean
}
const OpenCorporateComapniesData = ({
  openCompanyDataDialogs,
}: OpenCorporateComapniesDataProps) => {
  const [jurisdictionCode, setJurisdictionCode] = useState("")
  const [companyNumber, setCompanyNumber] = useState("")
  const [timeStamp, setTimeStamp] = useState(0)

  const [hasCompanyDataEvents, setHasCompanyDataEvents] = useState(false)
  const [isCompanyDataDialogs, setOpenCompanyDataDialogs] = useState(
    openCompanyDataDialogs,
  )
  const [hasCompanyData, setHasCompanyData] = useState(false)

  const handleCloseCompanyDataDialogs = () => {
    setOpenCompanyDataDialogs(false)
  }

  const {
    data: companyDataEvents,
    refetch: refetchCompanyEvents,
    isLoading: isLoadingDataEvents,
  } = useOpenCorporateGetCompaniesEvents(jurisdictionCode, companyNumber, 3, 1)

  const {
    data: companyData,
    refetch: refetchCompanyData,
    isLoading: isLoadingData,
  } = useOpenCorporateGetCompanies(jurisdictionCode, companyNumber, 1, 1)

  useEffect(() => {
    const handleJurisdictionCode = (data: unknown) =>
      setJurisdictionCode(data as string)
    const handleCompanyNumber = (data: unknown) =>
      setCompanyNumber(data as string)

    const handleTimeStamp = (data: unknown) => setTimeStamp(data as number)

    emitter.on("jurisdictionCode", handleJurisdictionCode)
    emitter.on("companyNumber", handleCompanyNumber)
    emitter.on("timeStamp", handleTimeStamp)

    if (companyDataEvents && companyDataEvents.length !== 0) {
      setOpenCompanyDataDialogs(true)
      setHasCompanyDataEvents(true)
    } else {
      setHasCompanyDataEvents(false)
    }
    if (companyData) {
      setOpenCompanyDataDialogs(true)
      setHasCompanyData(true)
    } else {
      setHasCompanyData(false)
    }

    return () => {
      emitter.off("jurisdictionCode", handleJurisdictionCode)
      emitter.off("companyNumber", handleCompanyNumber)
      emitter.off("timeStamp", handleTimeStamp)
    }
  }, [
    jurisdictionCode,
    companyNumber,
    refetchCompanyEvents,
    refetchCompanyData,
    companyDataEvents,
    companyData,
    hasCompanyDataEvents,
    timeStamp,
  ])

  if (isLoadingData) {
    return (
      <div>
        <Dialog
          maxWidth="sm"
          open={isCompanyDataDialogs}
          onClose={handleCloseCompanyDataDialogs}
          PaperProps={{
            sx: {
              width: "180%",
              maxWidth: "1000px",
            },
          }}
        >
          <DialogTitle>Registry Lookup</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseCompanyDataDialogs}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <div>...Loading...</div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  const renderCompanyDataCore = (
    <>
      <CompanyDataTable companyData={companyData} />
      <OfficersTable officers={companyData?.officers} />
      <FilingsTable filings={companyData?.filings} />
      <EventsTable events={companyDataEvents} />
    </>
  )

  const renderNoCompanyDataCore = (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <Typography variant="h6">Company Data</Typography>
            </Box>
          }
        />
        <Box
          sx={{
            color: "text.secondary",
            justifyContent: "center",
            display: "flex",
            height: "100%",
            flexDirection: "column",
            marginLeft: 3,
          }}
        >
          <span>No Data Found</span>
        </Box>
      </Card>
    </>
  )

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={isCompanyDataDialogs}
        onClose={handleCloseCompanyDataDialogs}
        PaperProps={{
          sx: {
            width: "180%",
            maxWidth: "1000px",
          },
        }}
      >
        <DialogTitle>Registry Lookup</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseCompanyDataDialogs}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <div>
            {hasCompanyData ? renderCompanyDataCore : renderNoCompanyDataCore}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default OpenCorporateComapniesData
