import { alpha } from "@mui/material/styles"

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        outlinedPrimary: {
          color: "#000",
          borderColor: "#000",
          "&:hover": {
            boxShadow: theme.customShadows.secondary,
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          color: "black",
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
            borderColor: "#000",
            boxShadow: theme.customShadows.secondary,
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  }
}
