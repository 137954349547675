import * as React from "react"
import Autocomplete from "./Autocomplete"
import useDebounced from "../../hooks/useDebounced"
import { UseQueryResult } from "react-query"
import { AutoCompleteOption } from "../../types/components/autocomplete.types"
import { InputProps } from "@mui/material"

interface PaginatedResponse<T> {
  results: Array<T>
}

interface Props<T> {
  style: React.CSSProperties
  label: string
  useSuggestions: (query: string) => UseQueryResult<PaginatedResponse<T>>
  required?: boolean
  value: string
  error?: boolean
  helperText?: string
  onChange: (val: string) => void
  inputProps?: Partial<InputProps>
}

export default function DebouncedAutocomplete<T extends AutoCompleteOption>({
  style,
  useSuggestions,
  label,
  required,
  value,
  onChange,
  error,
  helperText,
  inputProps,
}: Props<T>) {
  // State and setters for ...
  // Search term
  const [searchTerm, setSearchTerm] = React.useState(value)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 1000ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounced(searchTerm)

  const { data, isLoading } = useSuggestions(debouncedSearchTerm)

  return (
    <Autocomplete
      style={style}
      options={data?.results || []}
      loading={isLoading}
      onValueChanged={(value) => {
        setSearchTerm(value)
        onChange(value)
      }}
      label={label}
      required={required}
      value={value}
      error={error || false}
      helperText={helperText || undefined}
      inputProps={inputProps}
    />
  )
}
