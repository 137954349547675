import { CloudUploadOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, Divider } from "@mui/material"
import { CreditApplication, ApplicationTemplate } from "src/types"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import * as yup from "yup"

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  let enabledSchema = yup.object({})
  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === "Additional Questions")
    .filter((field) => field.required && !!field.id)
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )
  return enabledSchema
}

type Props = {
  application?: CreditApplication
  errors: CreditApplication["data"] | undefined
  data: CreditApplication["data"]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
}

export default ({ application, errors, data, onChange, onContinue }: Props) => {
  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Additional Questions
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        We have some more questions to finalize your application.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <form>
        <ReusableCustomQuestions
          application={application}
          associatedPage="Additional Questions"
          data={data["customFields"]}
          errors={errors}
          onChange={onChange}
        />
        <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
          <LoadingButton
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<CloudUploadOutlined />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
