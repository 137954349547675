import {
  OpenCorporateSearchInterface,
  OpenCorporateGetCompaniesInterface,
  OpenCorporateGetCompaniesEventsInterface,
  Officers,
  Filings,
  CompanyPreviousNames,
} from "src/types/vendors.types"

export class Converter {
  static toOpenCorporateGetCompaniesEvents(
    input: any,
  ): OpenCorporateGetCompaniesEventsInterface {
    return {
      createdAt:
        input?.createdAt !== null ? String(input.createdAt) : undefined,
      type: input?.type !== null ? String(input.type) : undefined,
      date: input?.date !== null ? String(input.date) : undefined,
    }
  }

  static toOpenCorporateGetCompaniesEventsArray(
    inputArray: any[],
  ): OpenCorporateGetCompaniesEventsInterface[] {
    return inputArray.map((item) =>
      this.toOpenCorporateGetCompaniesEvents(item),
    )
  }

  static toOpenCorporateGetCompanies(
    input: any,
  ): OpenCorporateGetCompaniesInterface | undefined {
    if (Object.keys(input).length === 0) {
      return undefined
    }

    return {
      companyName:
        input?.companyName !== null ? String(input.companyName) : undefined,
      companyPreviousNames: Array.isArray(input?.companyPreviousNames)
        ? input.companyPreviousNames.map(this.toCompanyPreviousName)
        : undefined,
      companyType:
        input?.companyType !== null ? String(input.companyType) : undefined,
      incorporationDate:
        input?.incorporationDate !== null
          ? String(input.incorporationDate)
          : undefined,
      currentStatus:
        input?.currentStatus !== null ? String(input.currentStatus) : undefined,
      companyAddress:
        input?.companyAddress !== null
          ? String(input.companyAddress)
          : undefined,
      website: input?.website !== undefined ? String(input.website) : undefined,
      linkToRegistryData:
        input?.linkToRegistryData !== null
          ? String(input.linkToRegistryData)
          : undefined,
      officers: Array.isArray(input?.officers)
        ? input.officers.map(this.toOfficer)
        : undefined,
      filings: Array.isArray(input?.filings)
        ? input.filings.map(this.toFiling)
        : undefined,
    }
  }

  static toCompanyPreviousName(input: any): CompanyPreviousNames {
    return {
      companyName:
        input?.companyName !== undefined
          ? String(input.companyName)
          : undefined,
    }
  }

  static toOfficer(input: any): Officers {
    return {
      name: input?.name !== null ? String(input.name) : undefined,
      position: input?.position !== null ? String(input.position) : undefined,
      startDate:
        input?.startDate !== null ? String(input.startDate) : undefined,
      endDate: input?.endDate !== null ? String(input.endDate) : undefined,
    }
  }

  static toFiling(input: any): Filings {
    return {
      title: input?.title !== null ? String(input.title) : undefined,
      date: input?.date !== null ? String(input.date) : undefined,
    }
  }

  static toOpenCorporateSearch(input: any): OpenCorporateSearchInterface {
    return {
      name: input.companyName ? String(input.companyName) : "",
      jurisdictionCode: input.jurisdictionCode
        ? String(input.jurisdictionCode)
        : "",
      companyNumber: input.companyNumber ? String(input.companyNumber) : "",
      registeredAddressInFull: input.registeredAddressInFull
        ? String(input.registeredAddressInFull)
        : "",
    }
  }

  static toOpenCorporateSearchArray(
    inputArray: any[],
  ): OpenCorporateSearchInterface[] {
    return inputArray.map((item) => this.toOpenCorporateSearch(item))
  }
}
