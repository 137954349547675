import { Formik, FormikConfig, FormikProps } from "formik"
import React, { RefObject } from "react"
import { useParams } from "react-router-dom"
import { ApplicationTemplate, Application } from "src/types"

import * as yup from "yup"

import { error } from "src/utils/logger"
import { FormComponent } from "src/types/credit/FormComponent"
import { useSnackbar } from "notistack"

export const validateErrorsAgainstSchema = (
  formRef: RefObject<FormikProps<Application>>,
  handleNext: (stepsRemaining?: boolean) => void,
  schema?: yup.AnyObjectSchema,
  onError = (err: any) => {
    error("validation error", err)
  },
) => {
  formRef?.current
    ?.validateForm()
    .then((errors) => {
      if (Object.keys(errors).length === 0) {
        handleNext(false)
      } else {
        if (errors.data) {
          if (schema) {
            error(errors.data)
            const { customFields: customFieldsErrors, ...restOfErrors } =
              errors.data || { customFields: {} }
            const { customFields, ...restOfFields } = schema.fields

            // if (errors.salesRep) {
            //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //   //@ts-ignore
            //   restOfErrors.salesRep = errors.salesRep
            // }
            if (
              Object.keys(customFieldsErrors || {}).every(
                (key) => !Object.keys(customFields?.fields || {}).includes(key),
              ) &&
              Object.keys(restOfErrors).every(
                (key) => !Object.keys(restOfFields).includes(key),
              )
            ) {
              formRef.current?.setErrors({})
              handleNext(true)
            } else {
              onError(errors)
            }
          } else {
            onError(errors)
          }
        }
      }
      return
    })
    .catch((err) => {
      error(err)
    })
}

export default ({
  activeStep,
  handleNext,
  handleBack,
  initialValues,
  application,
  formRef,
  Component,
  completionError,
  template,
  dataSchema,
  steps,
}: Omit<FormikConfig<Application>, "onSubmit"> & {
  activeStep: number
  handleNext: (stepsRemaining?: boolean, onFailed?: () => void) => void
  handleBack: () => void
  application: Application
  formRef: RefObject<FormikProps<Application>>
  completionError?: FormComponent["completionError"]
  Component: React.ComponentType<FormComponent>
  template: ApplicationTemplate
  dataSchema: yup.AnyObjectSchema
  steps: FormComponent["steps"]
}) => {
  const params = useParams()
  const { id } = params

  const validationSchema = yup.object({
    data: dataSchema,
  })

  // if (Object.keys(dataSchema.fields).length !== 0) {
  //   validationSchema = validationSchema.concat(
  //     yup.object({
  //       salesRep: yup.mixed().required("Sales Rep is required"),
  //     }),
  //   )
  // }

  const { enqueueSnackbar } = useSnackbar()

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDataFieldUpdated = (key: string, value: any) => {
    let data = formRef.current?.values.data
    if (!data) {
      data = {}
    }
    data[key] = value
    formRef.current?.setFieldValue("data", data, false)
  }

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onApplicationFieldUpdated = (key: string, value: any) => {
    formRef.current?.setFieldValue(key, value, false)
  }

  const onCustomFieldUpdated = (
    key: string,
    value: File | string | undefined,
  ) => {
    let data = formRef.current?.values.data
    if (!data) {
      data = {}
    }
    if (!data["customFields"]) {
      data["customFields"] = {}
    }
    if (!data["customResponsesToBeDeleted"]) {
      data["customResponsesToBeDeleted"] = {}
    }
    if (value) {
      data["customFields"][key] = value
      if (data["customResponsesToBeDeleted"][key]) {
        delete data["customResponsesToBeDeleted"][key]
      }
    } else if (data["customFields"][key]) {
      delete data["customFields"][key]
      data["customResponsesToBeDeleted"][key] = 1
    }
    formRef.current?.setFieldValue("data", data, false)
  }

  const onContinue = React.useCallback(
    (
      schema?: yup.AnyObjectSchema,
      onFormValidationError = () => {
        enqueueSnackbar(
          "Data validation failed. Please correct any errors and try again.",
          {
            variant: "warning",
          },
        )
        return undefined
      },
      ref: RefObject<FormikProps<Application>> = formRef,
    ) => {
      if (template) {
        if (activeStep !== steps.length - 1) {
          if (schema) {
            validateErrorsAgainstSchema(
              ref,
              () => {
                handleNext(true, onFormValidationError)
              },
              schema,
              onFormValidationError,
            )
          }
        } else {
          validateErrorsAgainstSchema(
            ref,
            () => {
              handleNext(false, onFormValidationError)
            },
            undefined,
            onFormValidationError,
          )
        }
      }
    },
    [activeStep, enqueueSnackbar, formRef, handleNext, steps.length, template],
  )

  if (!template || steps.length === 0)
    return (
      <>
        Loading business template...If this screen persists, please contact
        info@netnow.io
      </>
    )
  if (id && !application) return <>Loading application...</>

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        console.log("no-op")
      }}
      // validationSchema={validationSchema}
      innerRef={formRef}
    >
      {(props) => (
        <Component
          template={template}
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          props={props}
          onContinue={onContinue}
          onDataFieldUpdated={onDataFieldUpdated}
          onCustomFieldUpdated={onCustomFieldUpdated}
          onApplicationFieldUpdated={onApplicationFieldUpdated}
          application={application}
          completionError={completionError}
        />
      )}
    </Formik>
  )
}
