import { useQuery } from "react-query"
import { CashApplication } from "src/types"

export function useCashApplication(id: string | undefined) {
  return useQuery<CashApplication>(`/cod/${id}`, {
    enabled: !!id,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 400, 401, 403
      return ![400, 401, 403].includes((error as any)?.response?.status)
    },
  })
}
