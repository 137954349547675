import { Box, Card, CardHeader, Stack, Typography } from "@mui/material"
import {
  CompanyPreviousNames,
  OpenCorporateGetCompaniesInterface,
} from "src/types/vendors.types"

interface CompanyPreviousNamesRowProps {
  companyPreviousNames: CompanyPreviousNames[] | undefined
}

const CompanyPreviousNamesRow = ({
  companyPreviousNames,
}: CompanyPreviousNamesRowProps) => (
  <Stack direction="column" spacing={1}>
    {companyPreviousNames?.map((item, index) => (
      <Typography key={`company-name-${index}`} variant="body2">
        {item.companyName}
      </Typography>
    ))}
  </Stack>
)

interface CompanyDataTableRowsProps {
  companyData: OpenCorporateGetCompaniesInterface | undefined
}

const CompanyDataRow = ({
  label,
  data,
}: {
  label: string
  data: string | JSX.Element
}) => (
  <Stack direction="row" spacing={2} alignItems="center">
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ minWidth: "150px" }}
    >
      {label}
    </Typography>
    <Typography variant="body2">{data}</Typography>
  </Stack>
)

const CompanyDataTableRows = ({ companyData }: CompanyDataTableRowsProps) => (
  <Stack spacing={2}>
    <CompanyDataRow
      label="Company Name"
      data={companyData?.companyName || ""}
    />
    <CompanyDataRow
      label="Previous Names"
      data={
        <CompanyPreviousNamesRow
          companyPreviousNames={companyData?.companyPreviousNames}
        />
      }
    />
    <CompanyDataRow
      label="Company Type"
      data={companyData?.companyType || ""}
    />
    <CompanyDataRow
      label="Incorporation Date"
      data={companyData?.incorporationDate || ""}
    />
    <CompanyDataRow
      label="Current Status"
      data={companyData?.currentStatus || ""}
    />
    <CompanyDataRow label="Address" data={companyData?.companyAddress || ""} />

    <Stack direction="row" spacing={2} alignItems="center">
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ minWidth: "150px" }}
      >
        Website
      </Typography>
      <Typography variant="body2">
        <a
          href={companyData?.website || ""}
          target="_blank"
          rel="noopener noreferrer"
        >
          {companyData?.website || ""}
        </a>
      </Typography>
    </Stack>

    <Stack direction="row" spacing={2} alignItems="center">
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ minWidth: "150px" }}
      >
        Link To Registry Data
      </Typography>
      <Typography variant="body2">
        <a
          href={companyData?.linkToRegistryData || ""}
          target="_blank"
          rel="noopener noreferrer"
        >
          {companyData?.linkToRegistryData || ""}
        </a>
      </Typography>
    </Stack>
  </Stack>
)

interface CompanyDataTableProps {
  companyData: OpenCorporateGetCompaniesInterface | undefined
}

const CompanyDataTable = ({ companyData }: CompanyDataTableProps) => (
  <Card sx={{ width: "100%" }}>
    <CardHeader title={<Typography variant="h6">Company Data</Typography>} />
    <Box sx={{ p: 2 }} marginLeft={2}>
      <CompanyDataTableRows companyData={companyData} />
    </Box>
  </Card>
)

export default CompanyDataTable
