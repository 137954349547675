import { Helmet } from "react-helmet-async"
import { useState } from "react"
// @mui
import {
  Card,
  Chip,
  Box,
  Divider,
  Stack,
  Button,
  TableCell,
  TableRow,
  CardActions,
  Popover,
  Container,
  Typography,
  CardHeader,
  CardContent,
  MenuItem,
} from "@mui/material"

export default function CreditApplicationListPage() {
  return (
    <>
      <Helmet>
        <title>Credit Monitoring</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Credit Monitoring
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5">Needs Attention</Typography>
          <Divider />
          <Box style={{ display: "flex", gap: "5%", margin: "1rem" }}>
            <Card>
              <CardHeader
                title="Canada Rental Inc."
                subheader="Last sync: July 1, 2023"
              />
              <CardContent>
                <Stack
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <Stack>
                    <Typography variant="body2">Credit Limit</Typography>
                    <Typography variant="body2">Credit Score</Typography>
                    <Typography variant="body2">NSFs Last Month</Typography>
                    <Typography variant="body2">Cashflow Trend</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">No Change</Typography>
                    <Typography variant="body2" color="green">
                      + 2
                    </Typography>
                    <Typography variant="body2" color="red">
                      + 1
                    </Typography>
                    <Typography variant="body2" color="red">
                      - $9,320.21
                    </Typography>
                  </Stack>
                  <Divider
                    orientation="vertical"
                    style={{ width: "1px", height: "auto" }}
                  />
                  <Stack>
                    <Typography variant="body2">Liens</Typography>
                    <Typography variant="body2">Lawsuits</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2" color="red">
                      + 1
                    </Typography>
                    <Typography variant="body2">No Change</Typography>
                  </Stack>
                </Stack>
              </CardContent>
              <Divider />
              <CardActions style={{ padding: "1rem" }}>
                <Button variant="contained">See Details</Button>
                <Button>Stop</Button>
              </CardActions>
            </Card>
            <Card>
              <CardHeader
                title="Gabbatha Flooring Inc."
                subheader="Last sync: July 1, 2023"
              />
              <CardContent>
                <Stack
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <Stack>
                    <Typography variant="body2">Credit Limit</Typography>
                    <Typography variant="body2">Credit Score</Typography>
                    <Typography variant="body2">NSFs Last Month</Typography>
                    <Typography variant="body2">Cashflow Trend</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2" color="green">
                      + $12,000
                    </Typography>
                    <Typography variant="body2" color="red">
                      - 10
                    </Typography>
                    <Typography variant="body2" color="green">
                      No Change
                    </Typography>
                    <Typography variant="body2" color="green">
                      + $1,002.53
                    </Typography>
                  </Stack>
                  <Divider
                    orientation="vertical"
                    style={{ width: "1px", height: "auto" }}
                  />
                  <Stack>
                    <Typography variant="body2">Liens</Typography>
                    <Typography variant="body2">Lawsuits</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">No Change</Typography>

                    <Typography variant="body2">No Change</Typography>
                  </Stack>
                </Stack>
              </CardContent>
              <Divider />
              <CardActions style={{ padding: "1rem" }}>
                <Button variant="contained">See Details</Button>
                <Button>Stop</Button>
              </CardActions>
            </Card>
          </Box>
        </Stack>
        <Stack>
          <Typography variant="h5">Healthy Accounts</Typography>
          <Divider />
          <Box style={{ display: "flex", gap: "5%", margin: "1rem" }}>
            <Card style={{ height: "150px" }}>
              <CardHeader
                title="Brick & Mortar Inc."
                subheader="Last sync: July 1, 2023"
              />

              <CardActions style={{ padding: "1rem" }}>
                <Button variant="contained">See Details</Button>
                <Button>Stop</Button>
              </CardActions>
            </Card>
            <Card style={{ height: "150px" }}>
              <CardHeader
                title="Family Homes Inc."
                subheader="Last sync: July 1, 2023"
              />

              <CardActions style={{ padding: "1rem" }}>
                <Button variant="contained">See Details</Button>
                <Button>Stop</Button>
              </CardActions>
            </Card>
            <Card style={{ height: "150px" }}>
              <CardHeader
                title="Equipment Rentals"
                subheader="Last sync: July 1, 2023"
              />

              <CardActions style={{ padding: "1rem" }}>
                <Button variant="contained">See Details</Button>
                <Button>Stop</Button>
              </CardActions>
            </Card>
            <Card style={{ height: "150px" }}>
              <CardHeader
                title="John Smith Contracting Inc."
                subheader="Last sync: July 1, 2023"
              />

              <CardActions style={{ padding: "1rem" }}>
                <Button variant="contained">See Details</Button>
                <Button>Stop</Button>
              </CardActions>
            </Card>
          </Box>
        </Stack>
      </Container>
    </>
  )
}
