import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"

const color = {
  grey: "#999",
  white: "#ffffff",
  yellow: "#F4B728",
}
const border = {
  main: `1px solid ${color.grey}`,
}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    minWidth: "100vw",
    minHeight: "100vh",
    paddingTop: "20px",
    paddingBottom: "30px",
    paddingHorizontal: "15px",
  },
  logo: {
    // width: "162px",
    height: "48px",
  },
  cell: {
    marginLeft: "auto",
  },
  block: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  inputRoot: {
    position: "relative",
    paddingTop: "5px",
    marginVertical: "5px",
  },
  input: {
    width: "100%",
    height: "30px",
    border: border.main,
    borderRadius: "6px",
    fontSize: "10px",
    backgroundColor: color.white,
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    color: color.grey,
    fontSize: "10px",
    fontWeight: 400,
    position: "absolute",
    left: "5px",
    top: "0px",
    backgroundColor: color.white,
    paddingHorizontal: "5px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "18px",
    textAlign: "left",
  },
  box: {
    borderBottom: `1px solid ${color.yellow}`,
    width: "100%",
    paddingBottom: "4px",
    marginBottom: "4px",
  },
  subTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    textAlign: "left",
    marginTop: "15px",
  },
})

const PageBase = ({
  title,
  children,
  logoSrc,
  wrap = true,
}: {
  title: string
  children: React.ReactNode
  logoSrc?: string
  wrap?: boolean
}) => {
  return (
    <Page
      style={styles.body}
      wrap={wrap}
      size="LETTER"
      orientation={"portrait"}
    >
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          paddingBottom: "10px",
        }}
      >
        <View style={{}}>
          <Image src={logoSrc} style={styles.logo} />
        </View>

        <Text style={{ fontSize: "18px" }}>Credit Account Application</Text>
      </View>

      <Title label={title} />

      {children}
    </Page>
  )
}

const Cell = ({
  spacing,
  extraStyles = {},
  children,
}: {
  spacing?: string
  extraStyles?: Style
  children?: React.ReactNode
}) => {
  return (
    <View
      wrap={false}
      style={{
        flexBasis: `${spacing}%`,
        ...styles.cell,
        ...extraStyles,
      }}
    >
      {children}
    </View>
  )
}

const Block = ({
  extraStyles = {},
  children,
}: {
  extraStyles?: Style
  children: React.ReactNode
}) => {
  return (
    <View wrap style={{ ...styles.block, ...extraStyles }}>
      {children}
    </View>
  )
}

const Input = ({
  label,
  value,
}: {
  label?: string
  value: string | number
}) => {
  return (
    <View style={styles.inputRoot}>
      <View style={styles.input}>
        <Text>{value}</Text>
      </View>
      {label ? <Text style={styles.label}>{label}</Text> : null}
    </View>
  )
}

const Title = ({
  label,
  extraStyles = {},
}: {
  label: string
  extraStyles?: Style
}) => {
  return (
    <View style={{ ...styles.box, ...extraStyles }}>
      <Text style={styles.titleText}>{label}</Text>
    </View>
  )
}

const SubTitle = ({ label }: { label: string }) => {
  return <Text style={styles.subTitleText}>{label}</Text>
}

export { PageBase, Cell, Block, Input, SubTitle, Title }
