import { Box, Button, Chip, Stack, Typography } from "@mui/material"
import Label from "src/components/label"
import { SchemaDescription, SchemaObjectDescription } from "yup/lib/schema"
import * as yup from "yup"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { APPLICATION_STAGE } from "src/statics"

export const getStatus = (stage: number) => {
  if (stage === APPLICATION_STAGE.ONE) {
    return <Chip color={"primary"} label="Incomplete" />
  }

  if (stage === APPLICATION_STAGE.AWAITING_SIGNATURE) {
    return <Chip color={"warning"} label="Awaiting Signature" />
  }

  if (stage === APPLICATION_STAGE.COMPLETED_BY_CLIENT) {
    return <Chip color={"success"} label="Completed By Client" />
  }

  if (stage === APPLICATION_STAGE.APPROVED) {
    return <Chip color={"success"} label="Approved" />
  }

  if (stage === APPLICATION_STAGE.REJECTED) {
    return <Chip color={"error"} label="Rejected" />
  }

  if (stage === APPLICATION_STAGE.ARCHIVE) {
    return <Chip color={"info"} label="Archived" />
  }
}

export const getStatusLabel = (stage: number) => {
  if (stage === APPLICATION_STAGE.ONE) {
    return <Label color={"primary"}>Incomplete</Label>
  }

  if (stage === APPLICATION_STAGE.AWAITING_SIGNATURE) {
    return <Label color={"warning"}>Awaiting Signature</Label>
  }

  if (stage === APPLICATION_STAGE.COMPLETED_BY_CLIENT) {
    return <Label color={"success"}>Completed By Client</Label>
  }

  if (stage === APPLICATION_STAGE.APPROVED) {
    return <Label color={"success"}>Approved</Label>
  }

  if (stage === APPLICATION_STAGE.REJECTED) {
    return <Label color={"error"}>Rejected</Label>
  }

  if (stage === APPLICATION_STAGE.MANUAL_INCOMPLETE) {
    return <Label color={"primary"}>Manual / Incomplete</Label>
  }

  if (stage === APPLICATION_STAGE.MANUAL_COMPLETE) {
    return <Label color={"success"}>Manual / Complete</Label>
  }

  if (stage === APPLICATION_STAGE.ARCHIVE) {
    return <Label color={"info"}>Archived</Label>
  }
}

export const getStatusString = (stage: number) => {
  if (stage === APPLICATION_STAGE.ONE) {
    return "Incomplete"
  }

  if (stage === APPLICATION_STAGE.MANUAL_INCOMPLETE) {
    return "Manual / Incomplete"
  }

  if (stage === APPLICATION_STAGE.MANUAL_COMPLETE) {
    return "Manual / Complete"
  }

  if (stage === APPLICATION_STAGE.AWAITING_SIGNATURE) {
    return "Awaiting Signature"
  }

  if (stage === APPLICATION_STAGE.COMPLETED_BY_CLIENT) {
    return "Completed By Client"
  }

  if (stage === APPLICATION_STAGE.APPROVED) {
    return "Approved"
  }

  if (stage === APPLICATION_STAGE.REJECTED) {
    return "Rejected"
  }
  if (stage === APPLICATION_STAGE.ARCHIVE) {
    return "Archived"
  }
}

export const isPageRequired = (
  label: string,
  template?: ApplicationTemplate,
) => {
  return Boolean(
    template?.formTemplate.pages.find((step) => step.label === label)?.required,
  )
}

export const isDataFieldRequired = (
  schema: yup.AnyObjectSchema,
  fieldName: string,
) => {
  let required = false
  try {
    required = !!(
      (schema.describe().fields["data"] as SchemaObjectDescription).fields[
        fieldName
      ] as SchemaDescription
    ).tests.find((t) => t.name === "required")
  } catch (e) {
    return required
  }
  return required
}

export const getAdditionalQuestionSchemaForPage = (
  template: ApplicationTemplate,
  page: string,
) => {
  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === page)
    .filter((field) => field.required && !!field.id)
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  return customSchema
}

/**
 * Renders custom questions for a given page label.
 * This is used in Application Details page.
 */
export const renderCustomQuestionsFor = (
  businessTemplate: ApplicationTemplate | undefined,
  application: CreditApplication,
  associatedPage: string,
  onVerifyContractorLicense: () => void,
  onOpenDocument: (file: string) => void,
) => {
  const questions = businessTemplate?.customFields
    ?.filter((field) => !!field.id)
    ?.filter((field) => field.associatedPage === associatedPage)
    ?.filter((field) =>
      Boolean(
        Object.keys(application?.data?.customFields).includes(
          field.id as string,
        ),
      ),
    )
    ?.map((field) => {
      return {
        ...field,
        ...application.data.customFields[field.id as string],
      }
    })

  const numberOfQuestions = questions?.length || 0

  if (numberOfQuestions === 0) {
    return <></>
  }

  return (
    <>
      {questions?.map((field: any, index: number) => {
        return (
          <>
            <Stack
              direction="row"
              key={field.fieldId}
              justifyContent="space-between"
              width="auto"
              minHeight="4rem"
              // sx={index % 2 === 1 ? { backgroundColor: "#eeeeee" } : {}}
            >
              <Box
                style={{ width: "60%", minWidth: "60%" }}
                sx={{
                  color: "text.secondary",
                  flexWrap: "wrap",
                  display: "flex",
                  alignSelf: "center",
                }}
              >
                <Typography variant="body2" style={{ wordBreak: "break-word" }}>
                  {field.text}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignSelf: "center",
                }}
              >
                {field.fieldType !== "file" ? (
                  <>
                    {field.text.includes("Contractor License") && (
                      <Button
                        onClick={() => {
                          onVerifyContractorLicense()
                        }}
                        variant="contained"
                        style={{ marginRight: "8px" }}
                      >
                        Verify
                      </Button>
                    )}
                    <Typography
                      variant="body2"
                      style={{ wordBreak: "break-word" }}
                    >
                      {field.response}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        onOpenDocument(field.file)
                      }}
                      disabled={!field.file}
                      variant="contained"
                    >
                      {field.file ? "View" : "Skipped"}
                    </Button>
                  </>
                )}
              </Box>
            </Stack>
          </>
        )
      })}
    </>
  )
}
