import { Grid, Typography } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import AddUserForm from "../sections/@dashboard/onboarding/AddUserForm"
import { usePatchUser } from "../queries/base/usePatchUser"
import { useNavigate } from "react-router-dom"

export default () => {
  const navigate = useNavigate()
  const patchUser = usePatchUser(() => {
    navigate(0)
  })

  return (
    <Grid
      container
      direction="column"
      style={{ padding: "10px", alignItems: "center" }}
    >
      <h2>Thank you for deciding to use NetNow.</h2>
      <Typography>
        Please enter your information below to start unlocking your cash flow.
      </Typography>
      <Grid
        container
        direction="column"
        style={{ width: "500px", padding: "10px", alignItems: "center" }}
      >
        <AddUserForm mutation={patchUser}>
          <LoadingButton
            loading={patchUser.isLoading}
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: "1rem" }}
            type="submit"
          >
            Continue
          </LoadingButton>
        </AddUserForm>
      </Grid>
    </Grid>
  )
}
