// ----------------------------------------------------------------------

export function localStorageAvailable() {
  try {
    const key = "nnls-"
    window.localStorage.setItem(key, key)
    window.localStorage.removeItem(key)
    return true
  } catch (error) {
    return false
  }
}

export function localStorageGetItem(key, defaultValue = "") {
  const storageAvailable = localStorageAvailable()

  if (storageAvailable) {
    const value = localStorage.getItem(key) || defaultValue
    return value
  }

  return defaultValue
}

export function localStorageGetObjectItem(key, defaultValue = "{}") {
  return JSON.parse(localStorageGetItem(key, defaultValue))
}

export function localStorageSetItem(key, value) {
  const storageAvailable = localStorageAvailable()

  if (storageAvailable) {
    localStorage.setItem(key, value)
  }
}

export function sessionStorageAvailable() {
  try {
    const key = "nnls-"
    window.sessionStorage.setItem(key, key)
    window.sessionStorage.removeItem(key)
    return true
  } catch (error) {
    return false
  }
}

export function sessionStorageGetItem(key, defaultValue = "") {
  const storageAvailable = sessionStorageAvailable()

  if (storageAvailable) {
    const value = sessionStorage.getItem(key) || defaultValue
    return value
  }

  return defaultValue
}

export function sessionStorageGetObjectItem(key, defaultValue = {}) {
  defaultValue = JSON.stringify(defaultValue)
  return JSON.parse(sessionStorageGetItem(key, defaultValue))
}

export function sessionStorageSetItem(key, value) {
  const storageAvailable = sessionStorageAvailable()
  if (typeof value === "object") {
    value = JSON.stringify(value)
  }
  if (storageAvailable) {
    sessionStorage.setItem(key, value)
  }
}
