import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
} from "@mui/material"
import SingleTradeReferenceEntry from "./SingleTradeReferenceEntry"
import { usePostTradeReference } from "src/queries/credit/usePostTradeReference"
import { TradeReference } from "src/types"
import { useFormik } from "formik"
import { useState } from "react"
import * as yup from "yup"
import { useSnackbar } from "notistack"

export default ({
  applicationId,
  onClose,
  open,
}: {
  applicationId: string
  onClose: () => void
  open: boolean
}) => {
  const { execute } = usePostTradeReference()

  const [sendEmail, setSendEmail] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: {
      application: applicationId,
    } as TradeReference,
    enableReinitialize: true,
    validationSchema: yup.object({
      referenceEmail: yup.string().email().required(),
    }),
    onSubmit: (values: Partial<TradeReference>) => {
      execute(
        values,
        sendEmail,
        () => {
          enqueueSnackbar("Operation Successful.", {
            variant: "success",
          })
          onClose()
        },
        () => {
          enqueueSnackbar("Operation Successful.", {
            variant: "success",
          })
          onClose()
        },
      )
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Enter Trade Reference</DialogTitle>
      <Divider />
      <DialogContent>
        <SingleTradeReferenceEntry
          data={formik.values}
          onChange={(key, value) => {
            formik.setFieldValue(key, value, false)
          }}
          errors={formik.errors}
        />
        <FormControlLabel
          style={{ marginTop: "1rem" }}
          control={
            <Checkbox
              checked={sendEmail}
              onChange={() => {
                setSendEmail(!sendEmail)
              }}
              aria-label=""
              aria-labelledby="Automated Reference Required"
            />
          }
          label="Select this option if you would like to request automated trade references from this contact. Upon submission, we will send an email to the reference and ask for data. Leave this unchecked if you are only importing an existing reference."
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            formik.submitForm()
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
