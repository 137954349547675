import { useState } from "react"
import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import * as yup from "yup"

import {
  Box,
  Checkbox,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material"
import DebouncedAutocomplete from "src/components/autocomplete/DebouncedAutocomplete"
import { useBusinessSearch } from "src/queries/vendors/useBusinessSearch"
import { ApplicationTemplate, Application } from "src/types"

import Address, { PROVINCES, STATES, COUNTRIES } from "../components/Address"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { useSalesReps } from "src/queries/base/useSalesReps"
import { useCreditManagers } from "src/queries/base/useCreditManagers"
import {
  BUSINESS_CONFIG_BILLING_ADDRESS,
  BUSINESS_CONFIG_BUSINESS_ADDRESS,
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR,
  BUSINESS_CONFIG_REVENUE,
  BUSINESS_CONFIG_SERVICE_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  PAGE_LABEL_BUSINESS_DETAILS,
} from "./constants"
import {
  APPLICANT_TYPES,
  APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME,
} from "src/statics"

const baseYup = (template: ApplicationTemplate) =>
  yup.object({
    legalBusinessName: yup.string().when("applicantType", {
      is: (fieldTwo: string) =>
        APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME.includes(fieldTwo),
      then: yup.string().required(),
    }),
    revenue: yup.string().when("applicantType", {
      is: (fieldTwo: string) => {
        return (
          fieldTwo !== APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER &&
          getRevenueEnabled(template)
        )
      },
      then: yup.string().required(),
    }),
    salesRep: yup.mixed(),
  })

export const getEnabledSchema = (
  template: ApplicationTemplate,
): yup.AnyObjectSchema => {
  let enabledSchema = baseYup(template)
  template.formTemplate.pages
    .find((step) => step.label === "Business Details")
    ?.config?.filter((config) => !!config.value)
    .forEach(
      (config) =>
        (enabledSchema = enabledSchema.concat(
          getAdditionalSchema(config.label),
        )),
    )

  const addresses = getAddressTypes(template)

  if (addresses.includes(BUSINESS_CONFIG_SHIPPING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        shippingAddress: yup.string().required("Shipping Address is required"),
        shippingCity: yup.string().required("City is required"),
        shippingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(COUNTRIES),
        shippingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        shippingPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_BILLING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        billingAddress: yup.string().required("Billing Address is required"),
        billingCity: yup.string().required("City is required"),
        billingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(COUNTRIES),
        billingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        billingPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_SERVICE_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        serviceAddress: yup.string().required("Service Address is required"),
        serviceCity: yup.string().required("City is required"),
        serviceCountry: yup
          .string()
          .required("Country is required")
          .oneOf(COUNTRIES),
        serviceRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        servicePostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        creditCardBillingAddress: yup.string().required("Address is required"),
        creditCardBillingCity: yup.string().required("City is required"),
        creditCardBillingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(COUNTRIES),
        creditCardBillingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        creditCardBillingPostCode: yup
          .string()
          .required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_BUSINESS_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        businessAddress: yup.string().required("Address is required"),
        businessCity: yup.string().required("City is required"),
        businessCountry: yup
          .string()
          .required("Country is required")
          .oneOf(COUNTRIES),
        businessRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        businessPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === PAGE_LABEL_BUSINESS_DETAILS)
    .filter((field) => field.required && !!field.id)
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )
  return enabledSchema
}

const getAdditionalSchema = (label: string) => {
  return yup.object({})
}

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: () => void
  onApplicationFieldUpdated?: (key: string, value: any) => void
  topLevelValues?: Application
  template?: ApplicationTemplate
}

const getAddressTypes = (template?: ApplicationTemplate) => {
  let fromTemplate = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!fromTemplate || fromTemplate.length === 0) {
    fromTemplate = [
      BUSINESS_CONFIG_BUSINESS_ADDRESS,
      BUSINESS_CONFIG_SHIPPING_ADDRESS,
    ]
  }
  return fromTemplate
}

const getRevenueEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) => config.label.includes(BUSINESS_CONFIG_REVENUE))
    ?.value

  // by default it is enabled if not specified
  return v === undefined || Boolean(v)
}

const getCreditManagerSelectorEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) =>
      config.label.includes(BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR),
    )?.value

  // by default it is enabled if not specified
  return Boolean(v)
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  onApplicationFieldUpdated,
  topLevelValues,
  template,
}: Props) => {
  const [hideShippingAddress, setHideShippingAddress] = useState(false)
  const [hideBillingAddress, setHideBillingAddress] = useState(false)

  const [hideCcBillingAddress, setHideCcBillingAddress] = useState(false)

  const [hideDba, setHideDba] = useState(false)

  const { data: salesReps } = useSalesReps(application?.seller?.id || "")

  const { data: creditManagers } = useCreditManagers(
    application?.seller?.id || "",
  )

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Business Details
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Let us know about your business and where it is located.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      {APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME.includes(
        data.applicantType,
      ) && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <DebouncedAutocomplete
              useSuggestions={useBusinessSearch}
              style={{ width: "100%" }}
              label="Legal Business Name"
              required
              inputProps={{
                id: "legal-business-name",
                style: { height: "56px" },
              }}
              value={data.legalBusinessName}
              onChange={(val) => {
                onChange("legalBusinessName", val)
                if (hideDba) {
                  onChange("businessDba", val)
                }
              }}
              error={Boolean(errors?.legalBusinessName)}
            />
            {!hideDba && (
              <TextField
                id="business-dba"
                margin="normal"
                fullWidth
                label="Business DBA"
                type="text"
                value={data.businessDba}
                onChange={(event) => {
                  onChange("businessDba", event.target.value)
                }}
                inputProps={{
                  style: { height: "56px" },
                }}
                error={Boolean(errors?.businessDba)}
              />
            )}
          </Box>
          <FormGroup>
            <FormControlLabel
              defaultChecked={true}
              control={
                <Checkbox
                  id="dba-same"
                  onChange={(event) => {
                    setHideDba(event.target.checked)
                    if (event.target.checked) {
                      onChange("businessDba", data.legalBusinessName)
                    } else {
                      onChange("businessDba", undefined)
                    }
                  }}
                />
              }
              label="DBA same as legal business name"
            />
          </FormGroup>
        </>
      )}
      {getAddressTypes(template).includes(BUSINESS_CONFIG_BUSINESS_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Mailing Address</Typography>

          <Address
            keyPrefix="business"
            data={data}
            onChange={onChange}
            errors={errors}
          />
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_BILLING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Billing Address</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="billing-same-as-mailing"
                  onChange={(event) => {
                    setHideBillingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("billingAddress", data.businessAddress)
                      onChange("billingCity", data.businessCity)
                      onChange("billingCountry", data.businessCountry)
                      onChange("billingRegion", data.businessRegion)
                      onChange("billingPostCode", data.businessPostCode)
                    } else {
                      onChange("billingAddress", undefined)
                      onChange("billingCity", undefined)
                      onChange("billingCountry", undefined)
                      onChange("billingRegion", undefined)
                      onChange("billingPostCode", undefined)
                    }
                  }}
                />
              }
              label="Billing address same as mailing address"
            />
          </FormGroup>

          {!hideBillingAddress && (
            <Address
              keyPrefix="billing"
              data={data}
              onChange={onChange}
              errors={errors}
            />
          )}
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SHIPPING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Shipping Address</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="shipping-same-as-mailing"
                  onChange={(event) => {
                    setHideShippingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("shippingAddress", data.businessAddress)
                      onChange("shippingCity", data.businessCity)
                      onChange("shippingCountry", data.businessCountry)
                      onChange("shippingRegion", data.businessRegion)
                      onChange("shippingPostCode", data.businessPostCode)
                    } else {
                      onChange("shippingAddress", undefined)
                      onChange("shippingCity", undefined)
                      onChange("shippingCountry", undefined)
                      onChange("shippingRegion", undefined)
                      onChange("shippingPostCode", undefined)
                    }
                  }}
                />
              }
              label="Shipping address same as mailing address"
            />
          </FormGroup>

          {!hideShippingAddress && (
            <Address
              keyPrefix="shipping"
              data={data}
              onChange={onChange}
              errors={errors}
            />
          )}
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SERVICE_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Service Address</Typography>

          <Address
            keyPrefix="service"
            data={data}
            onChange={onChange}
            errors={errors}
          />
        </>
      )}

      {getAddressTypes(template).includes(
        BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
      ) && (
        <>
          <Typography variant="subtitle1">
            Credit Card Billing Address
          </Typography>

          {/* <FormGroup>
            <FormControlLabel
              required
              control={
                <Checkbox
                  id="cc-billing-same-as-mailing"
                  onChange={(event) => {
                    setHideCcBillingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("creditCardBillingAddress", data.businessAddress)
                      onChange("creditCardBillingCity", data.businessCity)
                      onChange("creditCardBillingCountry", data.businessCountry)
                      onChange("creditCardBillingRegion", data.businessRegion)
                      onChange(
                        "creditCardBillingPostCode",
                        data.businessPostCode,
                      )
                    } else {
                      onChange("creditCardBillingAddress", undefined)
                      onChange("creditCardBillingCity", undefined)
                      onChange("creditCardBillingCountry", undefined)
                      onChange("creditCardBillingRegion", undefined)
                      onChange("creditCardBillingPostCode", undefined)
                    }
                  }}
                />
              }
              label="Credit Card billing address same as mailing address"
            />
          </FormGroup> */}

          {!hideCcBillingAddress && (
            <Address
              keyPrefix="creditCardBilling"
              data={data}
              onChange={onChange}
              errors={errors}
            />
          )}
        </>
      )}

      {![APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER].includes(
        data.applicantType,
      ) &&
        getRevenueEnabled(template) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              What is the annual revenue of your business?
            </Typography>
            <FormControl
              fullWidth
              margin="normal"
              style={{ flex: "1 1 0px" }}
              required
            >
              <InputLabel id="revenue-label">Revenue</InputLabel>
              <Select
                labelId="revenue-label"
                id="revenue-select"
                label="Revenue"
                value={data.revenue}
                onChange={(event) => {
                  onChange("revenue", event.target.value)
                }}
                style={{ height: "56px" }}
                error={Boolean(errors?.revenue)}
              >
                <MenuItem disabled value={undefined}>
                  Select one
                </MenuItem>
                {[
                  "Less than $100,000 / year",
                  "$100,000 - $500,000 / year",
                  "$500,000 - $1M / year",
                  "$1M - $2.5M / year",
                  "$2.5M - $10M / year",
                  "$10M - $50M / year",
                  "More than $50M / year",
                  "Not a business",
                ].map((revenue) => (
                  <MenuItem value={revenue} key={revenue}>
                    {revenue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        salesReps &&
        salesReps.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              Who is the Sales Representative associated with this account?
            </Typography>
            <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
              <InputLabel id="sales-rep-label">Sales Rep</InputLabel>
              <Select
                labelId="sales-rep-label"
                id="sales-rep-select"
                label="Sales Rep"
                value={topLevelValues?.salesRep?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    "salesRep",
                    salesReps?.find(
                      (salesRep) => salesRep.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.salesRep)}
                style={{ height: "56px" }}
              >
                <MenuItem disabled value={undefined}>
                  Select One
                </MenuItem>
                {salesReps?.map((salesRep) => (
                  <MenuItem value={salesRep.id} key={salesRep.id}>
                    {salesRep.firstName} {salesRep.lastName}
                  </MenuItem>
                ))}
                <MenuItem value={-1} key="sales_rep_0">
                  No Sales Rep / Other
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        getCreditManagerSelectorEnabled(template) &&
        creditManagers && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              Who is the Credit Manager associated with this account?
            </Typography>
            <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
              <InputLabel id="credit-manager-label">Credit Manager</InputLabel>
              <Select
                labelId="credit-manager-label"
                id="credit-manager-select"
                label="Credit Manager"
                value={topLevelValues?.assignee?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    "assignee",
                    creditManagers?.find(
                      (creditManager) =>
                        creditManager.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.creditManager)}
                style={{ height: "56px" }}
              >
                <MenuItem disabled value={undefined}>
                  Select One
                </MenuItem>

                {creditManagers?.map((creditManager) => (
                  <MenuItem value={creditManager.id} key={creditManager.id}>
                    {creditManager.firstName} {creditManager.lastName}
                  </MenuItem>
                ))}
                <MenuItem value={-1} key="sales_rep_0">
                  Unknown / Other
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

      {template?.customFields && (
        <ReusableCustomQuestions
          application={application}
          associatedPage={"Business Details"}
          data={data["customFields"]}
          errors={errors}
          onChange={onCustomChange}
        />
      )}

      <LoadingButton
        id="continue-business"
        onClick={() => {
          if (hideShippingAddress) {
            // the user can change the address after checking the box.
            // in that case, the changes don't propagate. and we should sync here.
            onChange("shippingAddress", data.businessAddress)
            onChange("shippingCity", data.businessCity)
            onChange("shippingCountry", data.businessCountry)
            onChange("shippingRegion", data.businessRegion)
            onChange("shippingPostCode", data.businessPostCode)
          }
          if (hideBillingAddress) {
            // the user can change the address after checking the box.
            // in that case, the changes don't propagate. and we should sync here.
            onChange("billingAddress", data.businessAddress)
            onChange("billingCity", data.businessCity)
            onChange("billingCountry", data.businessCountry)
            onChange("billingRegion", data.businessRegion)
            onChange("billingPostCode", data.businessPostCode)
          }
          onContinue()
        }}
        size="large"
        fullWidth
        endIcon={<ArrowForward />}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Save & Continue
      </LoadingButton>
    </>
  )
}
