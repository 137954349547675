// @mui
import { useDocumentFields } from "src/queries/vendors/useDocumentFields"
import { DocumentField } from "src/types"
import {
  ContentPaste,
  LockOutlined,
  TimerOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material"
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { useState } from "react"
import { useSnackbar } from "notistack"

// ----------------------------------------------------------------------
export default () => {
  const { enqueueSnackbar } = useSnackbar()

  const params = useParams()
  const { id } = params
  const { data: application } = useCashApplication(id || "")

  const { data } = useDocumentFields(application?.data.zohoReqId || "", "cod")

  const [showCC, setShowCC] = useState(false)

  const theme = useTheme()

  const { fields } = data || { fields: [] }

  const formatCardNumber = (value: string) => {
    if (!value || value.length < 16) {
      return value
    }
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = value.replace(/[^\d]/g, "")

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(" "),
    )
  }

  const renderCard = () => {
    return (
      <Stack
        spacing={2}
        alignItems="flex-start"
        sx={{ my: 3, typography: "body2" }}
      >
        {(!fields || !Array.isArray(fields)) && (
          <Typography>Awaiting Signature</Typography>
        )}
        {fields &&
          Array.isArray(fields) &&
          fields
            .sort((a, b) => {
              if (a.fieldLabel === "CC") return -1
              if (b.fieldLabel === "CC") return 1
              if (a.fieldLabel === "CVV") return -1
              if (b.fieldLabel === "CVV") return 1
              if (a.fieldLabel === "Exp Date") return -1
              if (b.fieldLabel === "Exp Date") return 1
              return a.fieldLabel.localeCompare(b.fieldLabel)
            })
            .map((field: DocumentField) => {
              if (field.fieldLabel === "Signature") {
                return
              }
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  key={field["fieldName"]}
                >
                  <Box
                    component="span"
                    sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                  >
                    {field.fieldLabel}
                  </Box>
                  {["CC", "CVV", "Exp Date"].includes(field.fieldLabel) ? (
                    <Box
                      style={{
                        border: "2px solid",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: "8px",
                        padding: "4px 12px 4px 12px",
                        borderColor: theme.palette.primary.main,
                        cursor: "pointer",
                        width: "500px",
                      }}
                      onMouseDown={() => {
                        setShowCC(true)
                      }}
                      onMouseUp={() => {
                        setShowCC(false)
                      }}
                      onMouseLeave={() => {
                        setShowCC(false)
                      }}
                    >
                      <IconButton>
                        {showCC ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      <Typography style={{ width: "500px" }}>
                        {showCC
                          ? formatCardNumber(field.fieldValue)
                          : "Click and hold to view securely"}
                      </Typography>
                      {field.fieldLabel === "CC" && (
                        <IconButton
                          onClick={() => {
                            enqueueSnackbar(
                              "Card number copied to clipboard.",
                              {
                                variant: "info",
                              },
                            )
                            navigator.clipboard.writeText(field.fieldValue)
                          }}
                        >
                          <ContentPaste />
                        </IconButton>
                      )}
                    </Box>
                  ) : (
                    field.fieldValue
                  )}
                </Stack>
              )
            })}
      </Stack>
    )
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: "flex", gap: "8px" }}>
            <LockOutlined />
            Secure Data
          </Box>
        }
      />

      <Stack
        sx={{
          px: 3,
        }}
      >
        {renderCard()}
      </Stack>
    </Card>
  )
}
