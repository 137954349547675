import { QueryClient } from "react-query"
import { CreditApplication } from "../../types"
import { useTypedMutation } from "../useTypedMutation"
import { AxiosError } from "axios"
import queryString from "query-string"

export function usePatchApplication(
  onSuccess?: (data: CreditApplication) => void,
  anonymous = false,
) {
  const ENDPOINT = "/application/"
  const patchCreditApplication = useTypedMutation<FormData>(
    "patch/application",
    onSuccess,
  )

  const execute = (
    id: string,
    body: Partial<CreditApplication>,
    onSuccess?: () => void,
    onError?: (error: AxiosError) => void,
    queryParams: Record<string, string> = {},
  ) => {
    if (body.seller) {
      delete body.seller
    }
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "files" && body["files"]) {
        body["files"].forEach((file, index) => {
          if (file instanceof File) {
            formData.append("file" + index, file as File)
          } else {
            formData.append("file" + index, JSON.stringify(file))
          }
        })
      } else if (item.includes("data")) {
        formData.append(item, JSON.stringify(body["data"]))
      } else if (item.includes("Date")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(
          item,
          new Date(body[item as keyof CreditApplication] as Date).toISOString(),
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof CreditApplication])
      }
    }
    patchCreditApplication.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + id + "/?" + queryString.stringify(queryParams),
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        anonymous,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError: (error) => {
          if (onError) onError(error as AxiosError)
        },
      },
    )
  }
  return { ...patchCreditApplication, execute }
}
