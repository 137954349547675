// @mui
import PropTypes from "prop-types"
import { alpha } from "@mui/material/styles"
import Box from "@mui/material/Box"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
// components
import Label from "../../../components/label"

// ----------------------------------------------------------------------
const getStatusLabel = (stage) => {
  if (stage === 1) {
    return <Label color={"primary"}>Incomplete</Label>
  }

  if (stage === 100) {
    return <Label color={"warning"}>Awaiting Signature</Label>
  }

  if (stage === 200) {
    return <Label color={"success"}>Completed By Client</Label>
  }

  if (stage === 800) {
    return <Label color={"success"}>Approved</Label>
  }

  if (stage === 900) {
    return <Label color={"error"}>Rejected</Label>
  }
}

export default function ResultItem({
  title,
  subtitle,
  status,
  groupLabel,
  onClickItem,
}) {
  return (
    <ListItemButton
      onClick={onClickItem}
      sx={{
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "transparent",
        borderBottomColor: (theme) => theme.palette.divider,
        "&:hover": {
          borderRadius: 1,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.hoverOpacity,
            ),
        },
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          typography: "subtitle2",
          sx: { textTransform: "capitalize" },
        }}
        secondaryTypographyProps={{ typography: "caption" }}
        primary={title.map((part, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              color: part.highlight ? "primary.main" : "text.primary",
            }}
          >
            {part.text}
          </Box>
        ))}
        secondary={subtitle.map((part, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              color: part.highlight ? "primary.main" : "text.secondary",
            }}
          >
            {part.text}
          </Box>
        ))}
      />
      {groupLabel === "Application" && getStatusLabel(status)}

      {groupLabel && (
        <Label color="info" style={{ marginLeft: "8px" }}>
          {groupLabel}
        </Label>
      )}
    </ListItemButton>
  )
}

ResultItem.propTypes = {
  groupLabel: PropTypes.string,
  onClickItem: PropTypes.func,
  title: PropTypes.array,
  subtitle: PropTypes.array,
  status: PropTypes.string,
}
